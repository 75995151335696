

.container {
  margin: 0 auto;
  max-width: $containerWidth;
  width: 100%;

  @extend .clearfix;
}


main {
  overflow: hidden;
  margin: 1em 0 0;
  width: 100%;
}

aside {
  float: left;
  padding: 0 1em;
  width: 270px;

  @include media(lg) {
    float: none;
    width: 100%;
  }
}

.content-block {
  float: right;
  padding: 0 1em;
  width: calc(100% - 270px);
  min-height: calc(100vh - 313px);
  
  @include media(lg) {
    float: none;
    width: 100%;
  }
  @media screen and (max-width: 768px){
    min-height: auto;
  }
}
