.filter_block_wrapper {
  @extend .clearfix;

  .filter_block {
    float: left;
    min-height: 93px;
    padding: 1em 0;
    width: 50%;
  }

  .blockReset {
    color: $linkColor;
    cursor: pointer;
    float: right;
    font-size: 12px;
    text-decoration: underline;

    &:hover {
      color: $linkColor--hover;
      text-decoration: underline;
      transition: color 0.2s ease;
    }
  }

  .filter_top {
    margin: 0 0 0.5em;
    @extend .clearfix;


    & > span {
      color: $textColor;
      display: block;
      font-size: 17px;
    }
  }

  .filter_mid {
    @extend .clearfix;



    .filter-input-wrapper {
      display: inline-block;
      margin: 0 0.5em 0 0;
     
      label {
        background: $linkColor--hover;
        color: $white;
        cursor: pointer;
        font-size: 12px;
        display: block;
        padding: 0 7px 2px;
      }
      
      input[type="checkbox"] {
        display: none;
        &:checked {
          & + label {
            background: $linkColor;
          }
        }
      }
    }

    .input-wrapper {
      &.check {
        display: inline-block;
        margin: 0 0.5em 0 0;
      }
    }
  }

}

.filters-left {
  .filter_block_wrapper {
    .filter_block {
      float: none;
      width: 100%;
    }
  }
}




.filter_range {
  display: table;
  width: 100%;

  .input-wrapper {
    float: left;
    width: calc(50% - 0.5em);

    &:first-child {
      margin: 0 1em 0 0;
    }

    & > span,
    .input-text {
      display: inline-block;
    }

    & > span {
      font-size: 12px;
      width: 20px;
    }

    .input-text {
      width: calc(100% - 25px);
    }
  }

  & > .col-6 {
    padding: 0 0.5em 0 0;

    &:nth-child(2) {
      padding: 0 0 0 0.5em;
    }

    & > span {
      display: inline-block;
      font-size: 13px;
    }
  }

  .filter-input-wrapper {
    float: right;
    width: calc(100% - 35px);
  }

  input.pole {
    width: 100%;
  }


}



.filters-main {
  background: $white;
}


.module-wrapper {
  &.filters-main {

    .filter_block {
      padding: 1em 1em;
      min-height: 124px;

      &:nth-child(1n + 2) {
        padding: 1em 1em 1em 0;
      }
    }
  }
}

.poiskat {
  padding: 5px;
  border-top: 1px solid $borderColor;
  text-align: center;
}

.range_slider {
  display: block;
  padding: 10px 10px;
  height: 8px;
}

.ui-slider {
  position: relative;
  height: 6px;
  border: 1px solid #777;
  background: #fff;
  margin: 0;
}

.ui-slider-range {
  position: absolute;
  height: 6px;
  border: 1px solid #777;
  top: -1px;
  background: rgb(255,255,255);
  background: linear-gradient(to right,  rgba(255,255,255,1) 0%,rgba(216,216,216,1) 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#d8d8d8',GradientType=1 ); /* IE6-9 */
}

.ui-slider-handle {
  position: absolute;
  width: 8px;
  height: 8px;
  top: -2px;
  margin-left: -5px;
  border: 1px solid #000;
  border-radius: 2px;
  background: rgb(216,216,216);
  background: linear-gradient(to bottom,  rgba(216,216,216,1) 0%,rgba(255,255,255,1) 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d8d8d8', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
}

