.module {
  &.ajaxflowcart {
    background: #2c2a7d;
    overflow: visible;
    margin: 0;
  }
}


.ajaxflowcart-block {
  background: #fff;
  border-radius: 0 0 $borderRadius $borderRadius;
  box-shadow: 6px 8px 32px 0 rgba(0, 0, 0, 0.2);
  margin: 0;
  opacity: 0;
  padding: 0;
  position: absolute;
  right: 1em;
  text-align: center;
  top: 100%;
  transform: translate3d(0, -100%, 0);
  visibility: hidden;
  width: 380px;
  z-index: 10;


  &:after {
    border: 16px solid $linkColor--hover;
    border-width: 0 16px 10px 16px;
    border-color: transparent transparent $linkColor--hover transparent;
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    bottom: 100%;
    left: 50%;
    margin-left: -16px;
  }


  .price {
    color: $linkColor--hover;
    font-size: 17px;
  }

  .currency {
    color: #333;
    font-size: 11px;
    display: inline-block;
    vertical-align: middle;
  }


  .ajaxflowcart_kill {
    cursor: pointer;
    padding: 0;
    width: 33px;

    & > span {
      display: block;
      color: $linkColor;
      cursor: pointer;
      font-family: Arial;
      font-size: 14px;
      line-height: 2.3;
      width: 34px;
      height: 34px;
      -webkit-tap-highlight-color: $webkitHighlightTapColor;
    }
  }


  .ajaxflowcart_photo-img {
    display: block;
  }

  .ajaxflowcart_photo {
    width: 55px;

    & > a {
      display: block;
    }
  }



  .ajaxflowcart_title {
    text-align: left;
    width: 138px;

    & > a {
      font-size: 13px;
    }

    .prod-params {
      display: block;
      font-size: 13px;
      line-height: 1.333;
    }
  }


  .ajaxflowcart_count {
    color: #333;
    width: 43px;

    & > span {
      font-size: 13px;
    }
  }

  .ajaxflowcart_price {
    width: 101px;
  }

  .ajaxflowcart_summ {
    color: #827c72;
    font-size: 14px;
  }

  @include media(380px) {
    width: 100%;

    .currency {
      color: #333;
      font-size: 11px;
      width: 6px;
      overflow: hidden;
    }

    .ajaxflowcart_price {
      padding: 5px 0.5em 5px 5px;
      width: 67px;
    }

    .price {
      font-size: 14px;
    }

    .ajaxflowcart_title {
      width: auto;
    }

    .ajaxflowcart_count {
      width: 39px;

      & > span {
        font-size: 10px;
      }


    }
  }

}


.tablet,
.mobile {

  .ajaxflowcart-block {
    box-shadow: -6px 8px 32px 0 rgba(0, 0, 0, 0.2);
    position: fixed;
    overflow-x: hidden;
    overflow-y: scroll;
    right: 0;
    top: 44px;
    height: calc(100vh - 44px);
    transform: translate3d(100%, 0, 0);
    transition: all 0.3s ease;
    z-index: 1002;
    -webkit-overflow-scrolling: touch;
  }
}


.ajaxflowcart-table {
  table-layout: fixed;
  width: 100%;

  td {
    padding: 5px;
    border-top: 1px solid #eee;
  }

  tr {
    &:first-child {
      & > td {
        border-top: none;
      }
    }
  }
}

.mobile,
.tablet {
  .ajaxflowcart-table {
    margin: 0 0 4em;
  }
}

