.news-detail {
  margin: 1em 0 0;

  .news-detail__header {
    display: table;
    width: 100%;
  }

  .news-detail__title {
    font-size: 18px;
    display: table-cell;
  }

  .news-detail__date{
    display: table-cell;
    vertical-align: middle;
    text-align: right;
    width: 85px;
    padding-bottom: 4px;
    & > span {
      background: $linkColor;
      color: #fff;
      font-size: 13px;
      padding: 0 8px 1px;
      white-space: nowrap;

      &.pbl {
        background: none;
        display: block;
        color: $textColor;
        font-size: 10px;
        padding: 0;
      }
    }
  }

  .news-detail__text {
    @extend .clearfix;
    & > a {
      float: left;
      outline: none;
    }
  }


  .news-detail__image {
    display: block;
    margin: 0 1em 0 0;
  }



  @include media(sm) {

  }
}

