footer {

}

/* Раскладка футера */
.footer-top {

	.contacts {
		float: left;
		padding: 0 1em;
		width: 33.333%;
		@include media(md) {
			width: 100%;
			text-align: center;
			> div{
				display: inline-block;
			}
			.logo{
				width: 100%;
			}
			.phone{
				> ul{
					> li{
						display: inline-block;
						&:first-child{
							margin: 0 10px 0 0;
						}
					}
				}
			}
			@include media(xs) {
				.phone{
					> ul{
						> li{
							display: block;
						}
					}
				}
				.icon{
					left: 0;
				}
			}
		}
	}

	.footer-menu {
		float: right;
		padding: 0 1em;
		width: 66.666%;
		@include media(md) {
			width: 100%;
		}
	}
}



.footer-bottom {

	.payments {
		float: left;
		padding: 0 1em;
		width: 25%;
	}

	.social-block {
		float: left;
		padding: 0 1em;
		width: 45%;
	}

	.counters {
		// float: right;
		// padding: 0 0em;
		// width: 30%;
	}
}


/* Оформление */
.footer-top {
	background: $footerTopBgColor;
	padding: 1em 0 3em;


	.footer-menu {
		margin: 1.5em 0 0;

		@extend .clearfix;


		.title {
			font-size: 16px;
			color: #fff;
			margin: 0 0 1em;
			text-transform: uppercase;
		}

		.ishop,
		.text {
			float: left;
			width: 50%;
			@include media(xs) {
				width: 100%;
				text-align: center;
				margin: 0 0 1em;
			}
			.item {

				& > a {
					line-height: 1.666;
				}

				&.selected {
					& > a {
						color: $linkColor--hover;
						cursor: default;
						text-decoration: none;
					}
				}
			}
		}
	}


	.adres {
		margin: 0 0 0.5em;
		position: relative;
		padding: 0 1em 0 2em;

		.icon {
			@include fontawesome('\f041') {
				color: $linkColor--hover;
			}
		}
	}


	.phone {
		margin: 0 0 0.5em;
		padding: 0 1em 0 2em;
		position: relative;

		.icon {
			vertical-align: top;

			@include fontawesome('\f095') {
				color: $linkColor--hover;
			}
		}

		& > ul {
			display: inline-block;
			list-style: none;

			& > li {
				color: #fff;
				font-weight: bold;

				.pref {
					font-weight: normal;
				}
			}
		}


	}

	.mail {
		margin: 0 0 0.5em;
		position: relative;
		padding: 0 1em 0 2em;

		.icon {
			@include fontawesome('\f0e0') {
				color: $linkColor--hover;
			}
		}
	}
}


.footer-bottom {
	background: url(/assets/images/menu-bg.png) no-repeat;
	background-size: cover;
	background-position: top center;
	padding: 0.25em 0;
	@include media(xmd) {
		.container{
			text-align: center;
			> div{
				width: 100%;
			}
		}
	}

	.payments {
		margin: 7px 0 0;

		& > img {
			max-width: 290px;
			width: 100%;
		}
	}

	.social-block {
		margin: 0.5em 0;
		text-align: center;
	}

	.social-block__item {
		border: 1px solid $white;
		border-radius: 50%;
		cursor: pointer;
		display: inline-block;
		height: 1.7em;
		margin: 0 0.5em 0 0;
		width: 1.7em;

		&:hover > a {

			&:before {
				color: $linkColor--hover;
			}
		}


		& > a {
			display: inline-block;
			position: relative;
			line-height: 1;
			text-decoration: none;
			vertical-align: middle;


			@include fontawesome('') {
				color: $white;
				font-size: 1em;
				margin: -8px 0 0 -7px;
			}

			&.si1:before {
				content: '\f189';
				margin: -8px 0 0 -8px;
			}
			&.si2:before {
				content: '\f263';
				margin: -8px 0 0 -5px;
			}
			&.si3:before {
				content: '\f09a';
				margin: -8px 0 0 -5px;
			}
			&.si4:before {
				content: '\f099';
				margin: -8px 0 0 -6px;
			}
			&.si5:before {
				content: '\f1a0';
				margin: -8px 0 0 -6px;
			}
			&.si6:before {
				content: '\f1fa';
				margin: -8px 0 0 -6px;
			}
			&.si7:before {
				content: '\f167';
				margin: -8px 0 0 -6px;
			}
			&.si8:before {
				content: '\f16d';
				margin: -8px 0 0 -7px;
			}
		}
	}

	.counters {
		margin: 0.25em 0 0;
		padding: 0 1em;
		& > a {
			display: inline-block;
			vertical-align: middle;
			float: left;
		}

		& > .citron-logo__link {
			display: inline-block;
			max-width: 92px;
			width: 100%;
			position: relative;
			vertical-align: middle;
			float: right;
			&:hover {
				.citron-logo--hover {
					opacity: 1;
					visibility: visible;
				}
			}
		}

		.citron-logo--hover {
			position: absolute;
			top: 0;
			left: 0;
			opacity: 0;
			transition: opacity 0.3s ease;
			visibility: hidden;
		}
	}
}

