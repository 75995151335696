@font-face {
	font-family: 'FontAwesome';
	src: url('/assets/fonts/fontawesome/fontawesome-webfont.eot?v=4.7.0');
	src: url('/assets/fonts/fontawesome/fontawesome-webfont.eot?#iefix&v=4.7.0') format('embedded-opentype'),
	url('/assets/fonts/fontawesome/fontawesome-webfont.woff2?v=4.7.0') format('woff2'),
	url('/assets/fonts/fontawesome/fontawesome-webfont.woff?v=4.7.0') format('woff'),
	url('/assets/fonts/fontawesome/fontawesome-webfont.ttf?v=4.7.0') format('truetype'),
	url('/assets/fonts/fontawesome/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Roboto';
	src: url('../fonts/robotoregular/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
	url('../fonts/robotoregular/Roboto-Regular.woff') format('woff'),
	url('../fonts/robotoregular/Roboto-Regular.ttf')  format('truetype'),
	url('../fonts/robotoregular/Roboto-Regular.svg#Roboto-Regular') format('svg');
	font-weight: normal;
	font-style: normal;
}


@font-face {
	font-family: 'Roboto';
	src: url('../fonts/robotomedium/Roboto-Medium.eot?#iefix') format('embedded-opentype'),
	url('../fonts/robotomedium/Roboto-Medium.woff') format('woff'),
	url('../fonts/robotomedium/Roboto-Medium.ttf')  format('truetype'),
	url('../fonts/robotomedium/Roboto-Medium.svg#Roboto-Medium') format('svg');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Bookman Old Style';
	src: url('../fonts/bookman/BookmanOldStyle.eot?#iefix') format('embedded-opentype'),
	url('../fonts/bookman/BookmanOldStyle.woff') format('woff'),
	url('../fonts/bookman/BookmanOldStyle.ttf') format('truetype'),
	url('../fonts/bookman/BookmanOldStyle.svg#BookmanOldStyle') format('svg');
	font-weight: 500;
	font-style: normal;
}