

.module {
	@include module(false);

	& > .module-wrapper {
		padding: 1em;
	}

	&.catalog {
		.module-wrapper {
			padding: 0 /*1em 1em*/;
		}
	}
}


/* Оформление модулей в контент области */
.module-inner {
	clear: both;
	margin: 1em 0 2em;


	& > .module-header {
		background: none;
		padding: 1em;
		text-align: center;

		& > span {
			font-size: 18px;
			line-height: 18px;
			color: $black;
			text-transform: uppercase;
			font-family: 'Bookman Old Style';
		}
	}
}


.module-wrapper {
	padding: 0;
	position: relative;

	@include media(md) {
		padding: 1em;
	}
}


/* Бренды */
.module.brands {

	.brands-list {
		display: block;
		list-style: none;
		padding: 1em;
	}

	.brands-list__item {
		font-size: 1.14286em;
		line-height: 1.9;

		& > span {
			color: $linkColor--hover;
		}
	}
	
	.brands-list__link {
		color: $linkColor;
		transition: color 0.3s ease;

		&:hover {
			color: $linkColor--hover;
		}
	}
}



.module.brands {
	@include media(md) {
		float: left;
		padding: 0 0.5em 0 1em;
		width: 50%;
	}
	@include media(sm) {
		float: none;
		padding: 0;
		width: 100%;
	}
}



.mobile,
.tablet {
	// .header-contacts{
	// 	display: none;
	// }
	.weather{
		margin-top: 2%;
		
	}
	.module,
	.module-inner {
		background: none;

		& > .module-header {
			background: url(/assets/images/menu-bg.png) no-repeat;
			background-size: cover;
			padding: 1em;
			margin: 0 0 1em;


			& > span {
				color: $moduleMobileHeaderColor;
			}

			@include media(md) {
				padding: 0.5em 1em;

				& > span {
					font-size: 16px;
				}
			}
		}

		.module-wrapper {
			padding: 0;
		}
	}
}





