@import "../system/icon";


.header-bottom {
	background: url(/assets/images/header-bg.png) no-repeat;
	background-position: center bottom;
	background-size: cover;
	padding: 50px 0;
	.logo {
		float: left;
		padding: 0 1em;
		width: 25%;
	}

	.header-contacts {
		float: left;
		padding: 1em;
		width: 40%;
		text-align: right;
		@extend .clearfix;
	}


	.phones {
		float: left;
		padding: 0 1em 0 2em;
		width: 25%;
	}

	.mail {
		float: left;
		padding: 0 1em;
		width: 25%;

		.mail-wrapper {
			padding: 0 0 0 2em;
		}
	}

	.times-block {
		float: left;
		padding: 0 1em;
		width: 25%;
	}

	.header-cart {
		float: right;
		padding: 0 1em;
		width: 25%;
	}

	.auth-block {
		width: 100%;
		margin-top: 1em;
		.auth-box {
			float: right;
			padding: 10px 1em 0;
			position: relative;
			
			&:before{
				content: '';
				position: absolute;
				width: 20px;
				height: 20px;
				background: url(/assets/images/reg-icon.png) no-repeat;
				background-size: cover;
				left: -11px;
			}
			.reg {
				
			}
			.enter {

			}
		}
		a{
			color: #473923;
			font-weight: bold;
			text-decoration: none;
			&:hover{
				text-decoration: underline;
			}
		}
	}

	@include media(lg) {

		.logo {
			text-align: center;
			width: 100%;
		}

		.phones {
			width: 50%;

			.phones-list {
				&:before {
					left: 0;
				}
			}
		}

		.times-block {
			width: 50%;
		}

		.header-cart {
			float: right;
			padding: 0 1em 0 0;
			text-align: left;
			width: 50%;
		}

		.header-contacts {
			padding: 0 1em;
			width: 100%;


			.mail {
				float: left;
				padding: 0 1em 0 0;
				width: 50%;
			}
		}
	}

	@include media(xmd) {

		.logo {
			float: none;
			text-align: center;
			width: 100%;

			& > a {
				display: block;
			}

			img.logo-img {
				display: inline-block;
			}
		}

		.header-contacts {
			float: none;
			padding: 0 1em 1em;
			width: 100%;
		}
	}

}
/* конец раскладки */



.header-bottom {
	& > .container {
		position: relative;
	}

	.logo {
		margin: 0.333em 0;
		text-align:center;

		.logo-img {
			display: inline-block;
			height: auto;
			max-width: 268px;
			max-height: 90px;
			/*width: 100%;*/
		}
	}

	.phones {
		position: relative;
		margin: 0;

		.icon {
			@include fontawesome('\f095') {
				color: $iconColor;
			}
		}
	}

	.phones-list {
		display: inline-block;
		list-style: none;
		margin: 0;
		position: relative;
		vertical-align: middle;
	}

	.phones-list__item {

		& > a {
			display: block;
			color: $linkColor;
			font-size: 16px;
			font-weight: bold;
			line-height: 36px;
			text-decoration: none;
			white-space: nowrap;
		}

		.pref {
			font-weight: normal;
		}
	}

	.mail {
		margin: 0;

		.mail-wrapper {
			position: relative;
			margin: 0 0 1em;

			.icon {

				@include fontawesome('\f0e0') {
					color: $iconColor;
				}
			}

			@include media(lg) {
				margin: 0 0 0.5em;
			}
		}

	}

	.mail-link {
		font-size: 15px;
		line-height: 1;
		vertical-align: middle;
		white-space: nowrap;
	}


	.call-order {
		white-space: nowrap;
	}


	.times-block {
		margin: 0;

		.title {
			color: $linkColor--hover;
			font-size: 15px;
			line-height: 1;
			margin: 0 0 3px;
			text-transform: uppercase;
		}
	}


	.times {
		color: $textColor;
		font-size: 15px;
		padding: 0 0 0 1.5em;
		position: relative;
		white-space: nowrap;

		& > span {
			color: $linkColor + 70%;
		}

		@include media(xlg) {
			font-size: 14px;
		}

		@include media(lg) {
			font-size: 13px;
			padding-right: 1em;
		}

		@include fontawesome('\f017') {
			color: $linkColor;
			left: 0;
			margin-left: 0;
		}

	}

	@include media(lg) {

		.logo {
			margin: 0.5em 0;
		}

		.mail,
		.phones {
			margin: 0 0 1em;
		}

		.phones-list__item {

			& > a {
				font-size: 15px;
				line-height: 31px;
			}
		}

	}

}


.header-cart {
	cursor: default;
	padding: 0 0 5px;
	text-align: right;
	user-select: none;

	&.open {

		.cart {
			&.full {
				&:before {
					color: $iconColor;
				}
			}
		}
	}


	.cart {
		display: inline-block;
		position: relative;
		text-align: left;
		max-width: 150px;
		white-space: nowrap;
		width: 100%;

		@include fontawesome('\f07a') {
			color: $iconColor;
			font-size: 34px;
			margin: -19px 0 0;
			left: 0;
		}



		& > .header-cart__title-mob {
			display: none;
		}

		& > .header-cart__title {
			color: $linkColor--hover;
			font-size: 15px;
			line-height: 1;
			text-transform: uppercase;
		}

		.summ-block {
			padding: 0 0 0 3em;
		}

		& > .count {
			padding: 0 0 0 3em;

			& > .count-text {
				display: inline-block;
			}

			& > .count-quantity {
				display: inline-block;
			}
		}
	}
}


.desktop {

	.header-cart {


		.cart {

			&.full {
				cursor: pointer;
			}

			&:not(.empty) {
				&:hover {
					&:before {
						color: $linkColor;
					}
				}
			}
		}
	}


	@include media(lg) {
		.header-bottom {
			padding: 0 0 1em;
		}
	}
}

.weather{
	display: block;
	width: 20%;
	float: right;
	margin-right: 1em;

	text-align: center;
	
	padding: 5px 0;
	@media screen and(max-width: 480px){
		width: 50%;
		text-align: center;
		margin: 0 auto;
		float: none;
	}
	.SinoptikInformer{
		display: inline-block!important;
	}
	.SinoptikInformer .siHeader{
		display: none!important;
	}
	.siTitle{
		display: none!important;
	}
	.siLinks{
		display: none!important;
	}
	.SinoptikInformer .siBody{
		background: url(/assets/images/informer-bg.png) no-repeat!important;
		background-size: cover!important;
		border: none!important;
	}
	.type4c1 .siCityV2{
		background: url(/assets/images/informer-bg.png) no-repeat!important;
		background-size: cover!important;
	}
	.type4c1 .siCityV{
		background: none!important;
	}
	.SinoptikInformer .siCityV{
		border: none!important;
	}
	.SinoptikInformer .siCityV2{
		border: none!important;
	}
	.siLf{
		display: none;
	}
	.SinoptikInformer .siFooter{
		display: none;
	}
	.SinoptikInformer .siCityName a{
		font-size: 15px!important;
		span{
			font-size: 15px!important;
		}
	}

}

@media screen and(max-width: 480px){
	.weather{
		width: 43%!important;
	}
}