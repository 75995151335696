/* Вид на мобильных девайсах */
.mobile,
.tablet {


  .menu-button {
    background: none;
    border: none;
    cursor: pointer;
    float: left;
    outline: none;
    padding: 0;
    position: relative;
    text-align: center;
    
    height: $mobileFixedHeaderHeight;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: $webkitHighlightTapColor;
    user-select: none;
    transform: rotate(0deg);
    transition: 0.5s ease-in-out;

    @include sideBorder('right');

    & > span {
      display: block;
      position: absolute;
      height: 5px;
      width: 28px;
      background: #fff;
      border-radius: 1px;
      opacity: 1;
      left: 8px;
      transform: rotate(0deg);
      transition: 0.25s ease-in-out;


      &:nth-child(1) {
        top: 9px;
        transform-origin: left center;
      }

      &:nth-child(2) {
        top: 19px;
        transform-origin: left center;
      }

      &:nth-child(3) {
        top: 29px;
        transform-origin: left center;
      }

    }

    &.open {
      & > span {
        &:nth-child(1) {
          transform: rotate(45deg);
          top: 8px;
          left: 12px;
        }

        &:nth-child(2) {
          width: 0;
          opacity: 0;
        }

        &:nth-child(3) {
          transform: rotate(-45deg);
          top: 28px;
          left: 12px;
        }
      }
    }
  }


  .header-top {
    background: $headerTopBgColorMobile;
    background: url(/assets/images/menu-bg.png);
    backface-visibility: hidden;
    height: $mobileFixedHeaderHeight;
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
    right: 0;
    will-change: transform;
    z-index: 20;

    .search {
      float: right;
      padding: 0;
      position: relative;
      height: $mobileFixedHeaderHeight;
      width: $mobileFixedHeaderHeight;

      // @include sideBorder();

      .search-form {
        &.open {
          transform: translate3d(0, 0, 0);
          visibility: visible;
        }
      }
    }


    .search-icon {
      height: $mobileFixedHeaderHeight;
      width: $mobileFixedHeaderHeight;

      @include fontawesome('\f002') {
        color: brown;
        margin: -13px 0 0 -7px;
      }
    }

    .search-form {
      background: $white;
      padding: 10px 44px 10px;
      position: fixed;
      margin: 0;
      top: 0;
      left: 0;
      right: 0;
      height: $mobileFixedHeaderHeight;
      width: auto;
      max-width: none;
      transform: translate3d(0, -100%, 0);
      transition: all 0.3s ease-out;
      z-index: 10;
      visibility: hidden;
    }

    .search-form__button {
      width: $mobileFixedHeaderHeight;

      @include fontawesome('\f002') {
        color: $linkColor--hover;
        margin: -12px 0 0 -7px;
      }

      &.disabled {
        &:before {
          color: #999;
        }
      }
    }

    .times-block {
      display: inline-block;
      float: none;
      margin: 0.6em 0 0;
      width: auto;

      @include media(slg) {
        display: none;
      }
    }
  }


  .header-bottom {
    border-top: none;
    margin: 0;

    .logo {
      margin: 0;
      padding-top: 0.5em;
      padding-bottom: 0.5em;
    }

    @include media(lg) {
      .logo {
        width: 40%;
      }
      .header-contacts {
        padding: 20px 1em;
        width: 60%;
      }
    }

    @include media(xmd) {
      .header-contacts {
        padding: 0 0 1em;
        width: 100%;
      }
    }

    @include media(xs) {
      .logo {
        padding: 0 1em;
        width: 100%;
      }
    }
    .weather{
      width: 100%;
    }
  }


  .header-contacts {
    width: 70%;

    .times {
      padding: 0 0 0 2em;

      &:before {
        margin: -22px 0 0 -10px;
        top: 0;
      }

      @include media(xs) {
        font-size: 11px;
        padding: 0 0 0 2%;

        &:before {
          margin: -25px 0 0 -25px;
        }
      }

    }

    .times-block {
      margin: 10px 0 0;
      text-align: center;
      width: 50%;

      @include media(lg) {
        margin: 1em 0 0;
      }

      @include media(xs) {
        padding: 0 0 0 1em;
      }
    }

    .phones-list {
      display: inline-block;
      vertical-align: middle;
    }

    .phones {
      float: right;
      width: auto;
      padding: 0 1em 0 2em;
      text-align: right;

      @include media(lg) {
        margin: 0.25em 0 0;
      }

      @include media(xs) {
        margin: 0.5em 0 0;
        padding: 0 1em 0 0;
      }

      @include media(330px) {


        .icon {
          position: absolute;
          top: 50%;
          margin: -12px 0 0;
        }
      }

      .phones-list__item {
        line-height: 1.7em;
        width: auto;
        float: none;

        @include media(lg) {
          padding: 0;
        }

        @include media(md) {
          & > a {
            line-height: 30px;
          }
        }

        @include media(xs) {
          line-height: 1;

          & > a {
            display: block;
            font-size: 14px;
            line-height: 24px;
          }
        }
      }
    }
  }


  .header-cart {
    float: right;
    height: $mobileFixedHeaderHeight;
    width: $mobileFixedHeaderHeight;
    margin: 0;
    padding: 0;
    -webkit-tap-highlight-color: $webkitHighlightTapColor;

    &.open {
      .cart {
        &.full {
          @include spriteIcon(0, -585px) {
            margin: -15px 0 0 -15px;
            height: 28px;
            width: 28px;
          }
        }
      }
    }

    .header-cart__title-mob {
      display: none;
    }

    .cart {
      padding: 0;
      width: 100%;
      height: $mobileFixedHeaderHeight;

      @include sideBorder('left');

      @include fontawesome('\f07a') {
        color: $iconColor;
        font-size: 34px;
        margin: -26px 0 0 -18px;
        left: 50%;
      }

    }

    .summ-block {
      display: none;
      padding: 0;
    }

    .header-cart__title {
      display: none;
    }

    .count {
      background: $white;
      position: absolute;
      border-radius: 50%;
      height: 16px;
      padding: 0;
      text-align: center;
      width: 16px;
      top: 50%;
      margin: -11px 0 0 -7px;
      left: 50%;
      z-index: 2;

      .count-quantity {
        color: $black;
        display: block;
        font-size: 11px;
        line-height: 16px;
        letter-spacing: -1px;
      }

      .count-text {
        display: none;
      }

      .qt {
        display: none;
      }

    }
  }
}



@include media(xmd) {
  .header-top {

    .search {
      float: right;
      position: relative;
      width: 4em;
      height: 3em;
      perspective-origin: right center;
    }

  }
}

.mobile{
  .header-contacts{
    text-align: center;
  }
  .auth-block{
    text-align: center;
    width: 50%;
    margin: 0 auto;
    overflow: hidden;
  }
  .weather{
    width: 43%;
  }
}

