.vote-block {
	margin: 0 auto;
	max-width: 145px;

	.flag {
		position: relative;

		&:before {
			content: '\f00c';
			color: $textColor;
			font: normal 14px/1 "FontAwesome";
			margin: 0 0 0 5px;
		}
	}

	& > .vote-block__box {


		.vote-block__box-innr {
			list-style: none;
			margin: 0 0 1em;

			& > li {

				margin: 0 0 3px;

				& > label {
					color: $textColor;
					display: block;
					font-size: 13px;
					height: 20px;
					position: relative;
					padding: 0 0 0 2em;
				}
			}
		}


		.opr_btn {

		}


		.vote-block__otvet-var {
			color: $textColor;
			font-size: 13px;
			position: relative;
			line-height: 13px;
			padding: 5px 70px 5px 0;
		}

		.vote-block__otvet_-var-percent {
			color: $white;
			font-size: 12px;
			position: absolute;
			right: 5px;
			bottom: -14px;
		}

		.vote-block__line {
			height: 16px;
			background: $bgColor - 50%;
			margin: 0 0 5px;
		}

		.vote-block__line-in {
			height: 15px;
			background: $linkColor;
			width: 0;
			will-change: width;
			transition: all .5s ease-in;
		}

		.vote-block__total {
			color: $grey;
			font-size: 12px;
			font-weight: bold;
			padding-top: 10px;
		}
		.vote-block__total-percent {
			bottom: 5px;
		}
	}

	.vote-block__title {
		color: $textColor;
		font-size: 15px;
		margin: 0 0 1em;
		text-align: center;
	}
}


@include media(lg) {
	.vote-block {
		& > .vote-block__box {
			padding: 1em 0 1em 1em;
		}

	}
}
