@charset "UTF-8";
/* Базовые: Ширина контента */
/* Базовые: Высота компьютерной фиксированной шапки */
/* Базовые: Семейство шрифта */
/* Базовые: Размер шрифта */
/* Базовые: Белый цвет */
/* Базовые: Черный цвет */
/* Базовые: Цвет ссылок */
/* Базовые: Цвет ссылок при наведении */
/* Базовые: Какой-то цвет */
/* Базовые: Цвет текста */
/* Базовые: Черный цвет */
/* Базовые: Розовый цвет */
/* Базовые: Какой-то цвет иконок */
/* Базовые: Цвет фона */
/* Базовые: Цвет фона верхней шапки */
/* Базовые: Цвет фона нижней шапки */
/* Базовые: Цвета ошибок */
/* Базовые: Цвета ОК */
/* Базовые: Цвет фона верхней шапки на мобильных */
/* Базовые: Высота мобильной фиксированной шапки */
/* Футер: Верхний цвет фона */
/* Футер: Нижний цвет фона */
/* Футер: Нижний цвет фона */
/* Рамки: Цвет */
/* Рамки: Скругление */
/* Рамки: Стиль */
/* Кнопки: Градиент да/нет? Если нет, используется цвет 1 */
/* Кнопки: Тень, да/нет */
/* Кнопки: Рамка, да/нет */
/* Кнопки: Цвет фона 1 (нижний) */
/* Кнопки: Цвет фона 2 (верхний) */
/* Кнопки: Тень */
/* Кнопки: Цвет текста */
/* Кнопки: Скругления */
/* Кнопки: Цвет фона 1 (нижний) при наведении */
/* Кнопки: Цвет фона 2 (верхний) при наведении */
/* Кнопки: Цвет текста при наведении */
/* Кнопки: Рамка */
/* Кнопки: Цвет рамки при наведении */
/* Кнопки: Тень наведении */
/* Кнопки: Цвет фона 1 (нижний) активный */
/* Кнопки: Цвет фона 2 (верхний) активный */
/* Кнопки: Цвет текста активный */
/* Кнопки: Цвет рамки активный */
/* Кнопки: Тень в активном состоянии */
/* Инпуты: Есть ли рамка */
/* Инпуты: Есть ли тень */
/* Инпуты: Цвет текста */
/* Инпуты: Цвет фона */
/* Инпуты: Цвет рамки */
/* Инпуты: Тень */
/* Модули: Цвет фона */
/* Модули: Цвет фона заголовков */
/* Модули: Цвет заголовков */
/* Модули: Рамка */
/* Модули: Тень сверху */
/* Модули: Цвет фона заголовков в мобильной версиии */
/* Модули: Цвет заголовков в мобильной версиии */
/* Горизонтальное меню: Градиент да/нет Если нет, используется цвет 1 */
/* Горизонтальное меню: Тень да/нет */
/* Горизонтальное меню: Рамка да/нет */
/* Горизонтальное меню: Закругления да/нет */
/* Горизонтальное меню: Цвет фона 1 (нижний) */
/* Горизонтальное меню: Цвет фона 2 (верхний) */
/* Горизонтальное меню: Рамка */
/* Горизонтальное меню: Тень кнопок */
/* Горизонтальное меню: Цвет текста */
/* Горизонтальное меню: Скругления */
/* Горизонтальное меню: Цвет текста при наведении */
/* Горизонтальное меню: Цвет фона 1 (нижний) */
/* Горизонтальное меню: Цвет фона 2 (верхний) выбранный раздел */
/* Товар: Цвет заголовка */
/* Товар: Цвет текста */
/* Товар: Цвет текста следующий */
/* Товар: Цвет текущей цены */
/* Товар: Цвет старой цены */
/* Вертикальный градиент вверх */
.clearfix:before, .container:before, .header-bottom .header-contacts:before, .footer-top .footer-menu:before, .product .characters-table .characters-table__row:before, .kolvo_buy_button_add:before, .product-comments-form:before, .responses_content:before, .catalog-main__wrappa.set-5 > .row:before, .catalog-main:before, .category-list:before, .filter_block_wrapper:before, .filter_block_wrapper .filter_top:before, .filter_block_wrapper .filter_mid:before, .brand-list:before, .search_box .price-wrapper:before, .albums_list:before,
.photos_list:before, .news-list .news-block__item:before, .news-detail .news-detail__text:before,
.clearfix:after,
.container:after,
.header-bottom .header-contacts:after,
.footer-top .footer-menu:after,
.product .characters-table .characters-table__row:after,
.kolvo_buy_button_add:after,
.product-comments-form:after,
.responses_content:after,
.catalog-main__wrappa.set-5 > .row:after,
.catalog-main:after,
.category-list:after,
.filter_block_wrapper:after,
.filter_block_wrapper .filter_top:after,
.filter_block_wrapper .filter_mid:after,
.brand-list:after,
.search_box .price-wrapper:after,
.albums_list:after,
.photos_list:after,
.news-list .news-block__item:after,
.news-detail .news-detail__text:after {
  content: " ";
  display: table; }

.clearfix:after, .container:after, .header-bottom .header-contacts:after, .footer-top .footer-menu:after, .product .characters-table .characters-table__row:after, .kolvo_buy_button_add:after, .product-comments-form:after, .responses_content:after, .catalog-main__wrappa.set-5 > .row:after, .catalog-main:after, .category-list:after, .filter_block_wrapper:after, .filter_block_wrapper .filter_top:after, .filter_block_wrapper .filter_mid:after, .brand-list:after, .search_box .price-wrapper:after, .albums_list:after,
.photos_list:after, .news-list .news-block__item:after, .news-detail .news-detail__text:after {
  clear: both; }

.img-responsive, .category-list .category-list__item-image, .news-block .news-block__image {
  display: block;
  height: auto;
  max-width: 100%;
  width: 100%; }

/* Импорт sass модулей, по порядку */
/* Неиспользуемые модули надо закомментировать, используемые - раскомментировать */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0; }

html {
  font-family: sans-serif;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */ }

/* HTML5 display definitions
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 * 1. Add the correct display in Edge, IE, and Firefox.
 * 2. Add the correct display in IE.
 */
article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  /* 1 */
  display: block; }

/**
 * Add the correct display in IE 9-.
 */
audio,
canvas,
progress,
video {
  display: inline-block; }

/**
 * Add the correct display in iOS 4-7.
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
 * Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */
progress {
  vertical-align: baseline; }

/**
 * Add the correct display in IE 10-.
 * 1. Add the correct display in IE.
 */
template,
[hidden] {
  display: none; }

/* Links
   ========================================================================== */
/**
 * 1. Remove the gray background on active links in IE 10.
 * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
 */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/**
 * Remove the outline on focused links when they are also active or hovered
 * in all browsers (opinionated).
 */
a:active,
a:hover {
  outline-width: 0; }

/* Text-level semantics
   ========================================================================== */
/**
 * 1. Remove the bottom border in Firefox 39-.
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
 * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
 */
b,
strong {
  font-weight: inherit; }

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder; }

/**
 * Add the correct font style in Android 4.3-.
 */
dfn {
  font-style: italic; }

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Firefox, and Safari.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/**
 * Add the correct background and color in IE 9-.
 */
mark {
  background-color: #ff0;
  color: #000; }

/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%; }

/**
 * Prevent `sub` and `sup` elements from affecting the line height in
 * all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
   ========================================================================== */
/**
 * Remove the border on images inside links in IE 10-.
 */
img {
  border-style: none; }

/**
 * Hide the overflow in IE.
 */
svg:not(:root) {
  overflow: hidden; }

/* Grouping content
   ========================================================================== */
/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
 * Add the correct margin in IE 8.
 */
figure {
  margin: 1em 40px; }

/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/* Forms
   ========================================================================== */
/**
 * 1. Change font properties to `inherit` in all browsers (opinionated).
 * 2. Remove the margin in Firefox and Safari.
 */
button, .popup-block.add-to-cart .link-to-cart,
input,
select,
textarea {
  font: inherit;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
 * Restore the font weight unset by the previous rule.
 */
optgroup {
  font-weight: bold; }

/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 */
button, .popup-block.add-to-cart .link-to-cart,
input {
  /* 1 */
  overflow: visible; }

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */
button, .popup-block.add-to-cart .link-to-cart,
select {
  /* 1 */
  text-transform: none; }

/**
 * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
 *    controls in Android 4.
 * 2. Correct the inability to style clickable types in iOS and Safari.
 */
button, .popup-block.add-to-cart .link-to-cart,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

/**
 * Remove the inner border and padding in Firefox.
 */
button::-moz-focus-inner, .popup-block.add-to-cart .link-to-cart::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0; }

/**
 * Restore the focus styles unset by the previous rule.
 */
button:-moz-focusring, .popup-block.add-to-cart .link-to-cart:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText; }

/**
 * Change the border, margin, and padding in all browsers (opinionated).
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers.
 */
legend {
  box-sizing: border-box;
  /* 1 */
  color: inherit;
  /* 2 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  white-space: normal;
  /* 1 */ }

/**
 * Remove the default vertical scrollbar in IE.
 */
textarea {
  overflow: auto; }

/**
 * 1. Add the correct box sizing in IE 10-.
 * 2. Remove the padding in IE 10-.
 */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */ }

/**
 * Remove the inner padding and cancel buttons in Chrome and Safari on OS X.
 */
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * Correct the text style of placeholders in Chrome, Edge, and Safari.
 */
::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54; }

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

.preloader {
  display: block;
  position: fixed;
  left: 50%;
  top: 50%;
  width: 100px;
  height: 100px;
  margin: -50px 0 0 -50px;
  border-radius: 50%;
  border: 6px solid transparent;
  border-top-color: #2a82c4;
  animation: spin 2s linear infinite;
  z-index: 3; }
  .preloader:after {
    content: "";
    position: absolute;
    top: 12px;
    left: 12px;
    right: 12px;
    bottom: 12px;
    border-radius: 50%;
    border: 6px solid transparent;
    border-top-color: #edac0a;
    animation: spin 1.5s linear infinite; }
  .preloader:before {
    content: "";
    position: absolute;
    top: 3px;
    left: 3px;
    right: 3px;
    bottom: 3px;
    border-radius: 50%;
    border: 6px solid transparent;
    border-top-color: rgba(253, 30, 15, 0.7);
    animation: spin 3s linear infinite; }

.aniback-block {
  pointer-events: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: -2em;
  right: -2em;
  z-index: 50; }
  .aniback-block > .bottom,
  .aniback-block > .top {
    background: #fff;
    position: absolute;
    left: 0;
    right: 0;
    height: 50%; }
  .aniback-block > .top {
    top: 0; }
  .aniback-block > .bottom {
    top: 50%; }

.loaded .aniback-block > .top {
  transform: translateY(-100%);
  transition: transform 0.6s cubic-bezier(0.22, 0.61, 0.38, 0.71), visibility 0.5s;
  visibility: hidden; }

.loaded .aniback-block > .bottom {
  transform: translateY(100%);
  transition: transform 0.6s cubic-bezier(0.22, 0.61, 0.38, 0.71), visibility 0.6s;
  visibility: hidden; }

@-webkit-keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

/**************************************/
/*    Миксины для медиа запросов      */
/*    Использование:                  */
/*    @include media(lg/md/.../) {    */
/*         селектор {}                */
/*    }                               */
/*                                    */
/**************************************/
/**************************************/
/*    Брейкпоинты                     */
/**************************************/
/* Первые десктопы */
/* Медиа миксины. Как использовать см. внутри */
@font-face {
  font-family: 'FontAwesome';
  src: url("/assets/fonts/fontawesome/fontawesome-webfont.eot?v=4.7.0");
  src: url("/assets/fonts/fontawesome/fontawesome-webfont.eot?#iefix&v=4.7.0") format("embedded-opentype"), url("/assets/fonts/fontawesome/fontawesome-webfont.woff2?v=4.7.0") format("woff2"), url("/assets/fonts/fontawesome/fontawesome-webfont.woff?v=4.7.0") format("woff"), url("/assets/fonts/fontawesome/fontawesome-webfont.ttf?v=4.7.0") format("truetype"), url("/assets/fonts/fontawesome/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Roboto';
  src: url("../fonts/robotoregular/Roboto-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/robotoregular/Roboto-Regular.woff") format("woff"), url("../fonts/robotoregular/Roboto-Regular.ttf") format("truetype"), url("../fonts/robotoregular/Roboto-Regular.svg#Roboto-Regular") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Roboto';
  src: url("../fonts/robotomedium/Roboto-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/robotomedium/Roboto-Medium.woff") format("woff"), url("../fonts/robotomedium/Roboto-Medium.ttf") format("truetype"), url("../fonts/robotomedium/Roboto-Medium.svg#Roboto-Medium") format("svg");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'Bookman Old Style';
  src: url("../fonts/bookman/BookmanOldStyle.eot?#iefix") format("embedded-opentype"), url("../fonts/bookman/BookmanOldStyle.woff") format("woff"), url("../fonts/bookman/BookmanOldStyle.ttf") format("truetype"), url("../fonts/bookman/BookmanOldStyle.svg#BookmanOldStyle") format("svg");
  font-weight: 500;
  font-style: normal; }

/* подгрузка шрифтов */
.container {
  margin: 0 auto;
  max-width: 1200px;
  width: 100%; }

main {
  overflow: hidden;
  margin: 1em 0 0;
  width: 100%; }

aside {
  float: left;
  padding: 0 1em;
  width: 270px; }
  @media only screen and (max-width: 992px) {
    aside {
      float: none;
      width: 100%; } }

.content-block {
  float: right;
  padding: 0 1em;
  width: calc(100% - 270px);
  min-height: calc(100vh - 313px); }
  @media only screen and (max-width: 992px) {
    .content-block {
      float: none;
      width: 100%; } }
  @media screen and (max-width: 768px) {
    .content-block {
      min-height: auto; } }

/* Основной склелет */
input::-ms-clear {
  display: none; }

body {
  background: #fff;
  font: normal 14px/1.5 "Roboto", "Helvetica", sans-serif;
  color: #494949;
  opacity: 0;
  overflow: hidden;
  height: 100vh;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: none; }

.loaded body {
  overflow-x: hidden;
  overflow-y: scroll;
  height: auto;
  opacity: 1; }

table {
  border-collapse: collapse; }

ul {
  margin-top: 0;
  margin-bottom: 0; }

.content-inner ul {
  padding-left: 18px; }

h1 {
  color: #2a82c4;
  font-size: 28px; }

h2 {
  color: #2a82c4;
  font-size: 24px; }

h3 {
  color: #2a82c4;
  font-size: 18px; }

h1, h2, h3, h4, h5, h6, p {
  padding: 0 0 1em; }

a {
  color: #473923;
  transition: color 0.3s ease;
  text-decoration: underline;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.15); }

a:hover {
  color: #edac0a;
  text-decoration: underline;
  transition: color 0.3s ease; }

.alert {
  color: red; }

.noscript_alert {
  margin-bottom: 10px;
  padding: 5px;
  border: 1px solid red;
  text-align: center; }

.noscript_alert > span {
  font-weight: bold;
  color: red; }

.content-inner {
  margin: 1em 0 3em; }
  .content-inner .title {
    margin-bottom: 5px;
    text-transform: uppercase;
    font-weight: bold; }
  @media only screen and (max-width: 768px) {
    .content-inner {
      padding: 0 1em; } }
  .content-inner .restore_pass-section {
    display: block;
    margin: 1em 0 0;
    max-width: 320px;
    padding: 1em; }
    .content-inner .restore_pass-section > .popup-block__cross {
      display: none; }
    .content-inner .restore_pass-section > .popup-block__title {
      display: none; }
    .content-inner .restore_pass-section > .restore_pass-form {
      max-width: 292px; }
      .content-inner .restore_pass-section > .restore_pass-form .form_block {
        margin: 0; }
        .content-inner .restore_pass-section > .restore_pass-form .form_block .form_title {
          position: static; }
        .content-inner .restore_pass-section > .restore_pass-form .form_block.key-title {
          margin: 0 0 0.5em; }
      .content-inner .restore_pass-section > .restore_pass-form .input-text {
        width: 100%; }

.col-3 {
  float: left;
  padding: 0 1em;
  width: 25%; }

.col-4 {
  float: left;
  padding: 0 1em;
  width: 33.333%; }

.col-6 {
  float: left;
  padding: 0 1em;
  width: 50%; }

.col-3-r {
  float: right;
  padding: 0 1em;
  width: 25%; }

.col-4-r {
  float: right;
  padding: 0 1em;
  width: 33.333%; }

.col-6-r {
  float: right;
  padding: 0 1em;
  width: 50%; }

@media only screen and (max-width: 768px) {
  .col-3 {
    width: 50%; }
  .col-4 {
    width: 50%; }
  .col-6 {
    width: 100%; }
  .col-3-r {
    width: 50%; }
  .col-4-r {
    width: 50%; }
  .col-6-r {
    width: 100%; } }

@media only screen and (max-width: 480px) {
  .col-3,
  .col-4,
  .col-6,
  .col-3-r,
  .col-4-r,
  .col-6-r {
    width: 100%; } }

.path {
  color: #483a23;
  display: block;
  text-align: left;
  font-weight: bold;
  font-size: 14px;
  line-height: 1em;
  padding: 2em 0 1.5em;
  position: relative; }
  .path:before {
    background: linear-gradient(to right, #aaa 0%, #aaa 15%, rgba(255, 255, 255, 0) 70%);
    content: '';
    height: 1px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0; }
  .path > a {
    font-weight: normal; }

/* Вид хлебных крошек на мобильных */
@media only screen and (max-width: 768px) {
  .mobile .path,
  .tablet .path {
    background: #f1e0a6;
    color: #222;
    font-size: 12px;
    padding: 12px 15px 14px; }
    .mobile .path > a,
    .tablet .path > a {
      color: #fff; }
    .mobile .path:before,
    .tablet .path:before {
      display: none; } }

.content {
  position: relative; }
  .content img {
    height: auto;
    max-width: 800px;
    /*width: 100%;*/ }
    @media only screen and (max-width: 992px) {
      .content img {
        max-width: 600px; } }
    @media only screen and (max-width: 768px) {
      .content img {
        max-width: 100%; } }

button, .popup-block.add-to-cart .link-to-cart,
.button {
  background: linear-gradient(to top, #dd8200, #eeb00b);
  border-radius: 0px;
  border: none;
  /* Стили кнопки по-умолчанию */
  display: inline-block;
  color: #fff;
  cursor: pointer;
  font-size: 15px;
  outline: none;
  padding: 7px 14px;
  pointer-events: all;
  text-decoration: none;
  transition: all 0.2s ease;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.15);
  -webkit-appearance: none;
  backface-visibility: hidden;
  -webkit-font-smoothing: antialiased;
  /* Основные стили настроек кнопки см. /src/scss/vars */
  font-size: 14px;
  outline: none;
  text-transform: uppercase;
  transition: all 0.3s ease; }
  button:hover, .popup-block.add-to-cart .link-to-cart:hover,
  .button:hover {
    background: linear-gradient(to top, #0a3454, #143f61); }
  button:hover, .popup-block.add-to-cart .link-to-cart:hover,
  .button:hover {
    border-color: #1b9fae;
    color: #fff;
    transition: all 0.3s ease;
    text-decoration: none; }
  button:active, .popup-block.add-to-cart .link-to-cart:active,
  .button:active {
    box-shadow: inset 0 2px 7px 0 rgba(0, 0, 0, 0.5);
    padding: 8px 13px 6px 15px;
    transition: all 0.2s ease;
    will-change: padding; }
  button.disabled, .popup-block.add-to-cart .disabled.link-to-cart,
  .button.disabled {
    background: #aaa;
    background: linear-gradient(to top, #aaa, #999);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=$startColor,endColorstr=$endColor, GradientType=0 );
    cursor: default;
    pointer-events: none; }

.yashare_text {
  text-align: right; }

html.ie9 * {
  border-radius: 0 !important; }

.wrapper {
  height: 100vh;
  overflow-x: hidden;
  pointer-events: none;
  position: relative;
  z-index: 1; }

.loaded .wrapper {
  height: auto;
  overflow: hidden;
  pointer-events: all;
  visibility: visible; }

.mobile.menu-visible .overlay,
.tablet.menu-visible .overlay {
  visibility: hidden; }

.mobile .wrapper,
.tablet .wrapper {
  background: #fff;
  box-shadow: -9px 0 37px rgba(0, 0, 0, 0.3);
  padding: 44px 0 0;
  position: relative;
  will-change: transform;
  z-index: 10; }

.locked .overlay {
  opacity: 1;
  transition: opacity 0.2s cubic-bezier(0, 0, 0, 1);
  visibility: visible; }

.icon {
  display: block;
  height: 24px;
  width: 24px;
  position: absolute;
  top: 50%;
  left: 0;
  margin: -12px 0 0; }
  @media only screen and (max-width: 414px) {
    .icon {
      left: -20px; } }

.overlay {
  position: fixed;
  background: rgba(0, 0, 0, 0.65);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  visibility: hidden;
  z-index: 100; }

.mob-icon {
  font: normal 1.5em/1.2 "FontAwesome";
  position: absolute;
  pointer-events: none; }

.desktop .mob-icon {
  visibility: hidden; }

.citronLoader {
  position: absolute;
  top: 0;
  left: 0;
  background: white;
  background: rgba(255, 255, 255, 0.8);
  z-index: 2; }

.citronLoader span {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 32px;
  height: 32px;
  background: url(/assets/images/ajaxloader.gif) 0 0 no-repeat; }

.citronLoaderBig {
  position: absolute;
  top: 0;
  left: 0;
  background: white;
  background: rgba(255, 255, 255, 0.8);
  z-index: 2; }

.citronLoaderBig span {
  position: absolute;
  top: 100px;
  left: 48%;
  width: 66px;
  height: 66px;
  background: url(/assets/images/citronloader.gif) 0 0 no-repeat; }

#ajaxedsearch {
  position: absolute;
  z-index: -1;
  width: 1px;
  height: 1px;
  overflow: hidden; }

.content-block.filtrovannoe {
  padding: 10px; }

.prd_pages_bottom,
.prd_pages_top {
  color: #494949;
  font-size: 13px;
  text-align: right; }
  .prd_pages_bottom > a,
  .prd_pages_top > a {
    display: inline-block;
    padding: 0 4px; }
    .prd_pages_bottom > a.sel_page,
    .prd_pages_top > a.sel_page {
      color: #494949;
      text-decoration: none; }

.example {
  background: #2a82c4;
  color: #fff;
  font-weight: 700;
  display: inline-block; }

.video-responsive {
  position: relative;
  padding-bottom: 42%;
  height: 0;
  overflow: hidden;
  margin: 0 auto;
  max-width: 73%; }
  .video-responsive iframe,
  .video-responsive object,
  .video-responsive embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.redz {
  color: #df141e; }

.radio {
  vertical-align: top;
  width: 17px;
  height: 17px;
  margin: 0 6px 0 0; }
  .radio + label {
    cursor: pointer; }
  .radio:not(checked) {
    position: absolute;
    visibility: hidden; }
    .radio:not(checked) + label {
      position: relative;
      padding: 0 0 0 35px; }
      .radio:not(checked) + label:before {
        border-radius: 50%;
        background: #cbcbcb;
        content: '';
        position: absolute;
        top: 50%;
        margin-top: -8px;
        left: 0;
        width: 16px;
        height: 16px; }
      .radio:not(checked) + label:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 3px;
        width: 10px;
        height: 10px;
        margin-top: -5px;
        border-radius: 50%;
        background: #2a82c4;
        opacity: 0;
        transition: background .2s ease; }
  .radio:checked + label:after {
    opacity: 1; }

.checkbox {
  vertical-align: top;
  margin: 0 3px 0 0;
  width: 17px;
  height: 17px; }
  .checkbox + label {
    cursor: pointer;
    user-select: none; }
  .checkbox:not(checked) {
    position: absolute;
    visibility: hidden; }
    .checkbox:not(checked) + label {
      position: relative;
      padding: 0 0 0 24px; }
      .checkbox:not(checked) + label:before {
        border: 1px solid #aaa;
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        margin-top: -6px;
        width: 12px;
        height: 12px;
        border-radius: 0;
        background: #fff; }
      .checkbox:not(checked) + label:after {
        content: '\f00c';
        color: #2a82c4;
        font: normal 16px/1 "FontAwesome";
        position: absolute;
        margin-top: -8px;
        top: 50%;
        left: 1px;
        opacity: 0;
        visibility: hidden; }
  .checkbox:checked + label:after {
    opacity: 1;
    transition: opacity 0.2s ease;
    visibility: visible; }

/* Основные стили */
.input-text,
.input-textarea {
  background: #fff;
  border: 1px solid #050f29;
  border-radius: 0px;
  color: #494949;
  display: block;
  font-size: 13px;
  padding: 3px 0.5em 3px;
  outline: none;
  appearance: none;
  border: 1px solid lightgray;
  padding: 6px 0.5em; }

.content-block .reg_auth-section .form_block:not(.form_block__last) .form_title {
  position: initial; }

.content-block .reg_auth-section {
  max-width: 260px;
  background: #fff;
  margin: 1em 0 0;
  padding: 0 1em 1em; }

.content-block .form_block.form_block__last {
  margin: 0; }

.content-block .addition-block a {
  margin-right: 5px; }

.input-textarea {
  padding: 0.5em 1em; }

textarea {
  display: block;
  resize: none;
  padding: 0.5em 1em; }

.form_block {
  margin: 1.25em 0 0;
  position: relative;
  text-align: left; }
  .form_block .form_title {
    color: #666;
    font-size: 12px;
    pointer-events: none;
    line-height: 1.5; }
  .form_block.form_block__last {
    padding: 0;
    margin: -1em 0 0; }
    .form_block.form_block__last .button {
      margin: 0.5em 0 0; }
  .form_block:not(.form_block__last) .form_title {
    position: absolute;
    top: 4px;
    left: 0.75em;
    z-index: 10; }

.has-error > label {
  color: rgba(253, 30, 15, 0.7); }

.has-error > .input-text,
.has-error > .input-textarea {
  border-color: rgba(253, 30, 15, 0.7); }

.has-success > .input-text,
.has-success > .input-textarea {
  border-color: rgba(169, 253, 0, 0.7); }

.form_content {
  position: relative; }

.help-block {
  font-size: 11px;
  position: absolute;
  top: 5px;
  right: 1em;
  pointer-events: none; }
  .help-block.form-error {
    color: rgba(253, 30, 15, 0.7); }

.check-agree {
  padding: 0.5em 0;
  margin: 0; }
  .check-agree .checkbox:not(checked) + label:before {
    top: 0px;
    left: 0;
    margin-top: 0; }
  .check-agree .checkbox:not(checked) + label:after {
    top: 1px;
    left: 1px;
    margin-top: 0; }
  .check-agree label {
    cursor: pointer;
    font-size: 11px;
    display: inline-block;
    line-height: 1.333; }
    .check-agree label.has-error {
      color: rgba(253, 30, 15, 0.7); }
  .check-agree .input-form {
    margin: 0 0.5em 0 0;
    vertical-align: bottom;
    width: auto; }

.payFlex {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around; }

.payType {
  margin: 6px 0;
  border-radius: 0;
  box-shadow: 0 0 4px -1px #aaa;
  background: #fff;
  cursor: pointer;
  padding: 10px 2px;
  font-size: 12px;
  font-weight: bold;
  width: 100%;
  transition: ease all .02s;
  -webkit-transition: ease all .02s;
  position: relative;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start; }
  .payType.active {
    box-shadow: 0 0 4px 2px #2a82c4; }
  .payType > div {
    line-height: 35px; }
  .payType > img {
    height: 35px;
    max-width: 70px;
    margin: 0 1em 0 0; }
  .payType .radio:not(checked) + label {
    padding: 0;
    height: 32px;
    width: 32px; }
    .payType .radio:not(checked) + label:after {
      left: 50%;
      margin-left: -5px; }
    .payType .radio:not(checked) + label:before {
      margin-left: -8px;
      left: 50%; }

.ui-datepicker .ui-widget-header {
  background: #2a82c4;
  color: #fff; }

.ui-datepicker td span,
.ui-datepicker td a {
  text-align: center !important; }

.ui-datepicker td > a {
  background: none !important;
  border: 1px solid transparent !important; }

.ui-datepicker td.ui-datepicker-week-end > a {
  color: #2a82c4 !important;
  background: none !important;
  border: 1px solid transparent !important; }

.ui-datepicker td > a:hover {
  color: inherit !important;
  background: #e6e6e6 !important;
  border: 1px solid #d3d3d3 !important; }

.ui-datepicker td.ui-datepicker-today > a {
  color: #fff !important;
  background: #2a82c4 !important;
  border: 1px solid #2a82c4 !important; }

.ui-datepicker td.ui-datepicker-current-day > a {
  color: inherit !important;
  border: 1px solid #2a82c4 !important; }

.ui-datepicker th.ui-datepicker-week-end > span {
  color: #2a82c4 !important;
  background: none !important;
  border: 1px solid transparent !important; }

.ui-datepicker td.ui-datepicker-week-end > a:hover {
  color: #2a82c4 !important; }

.header-bottom {
  background: url(/assets/images/header-bg.png) no-repeat;
  background-position: center bottom;
  background-size: cover;
  padding: 50px 0; }
  .header-bottom .logo {
    float: left;
    padding: 0 1em;
    width: 25%; }
  .header-bottom .header-contacts {
    float: left;
    padding: 1em;
    width: 40%;
    text-align: right; }
  .header-bottom .phones {
    float: left;
    padding: 0 1em 0 2em;
    width: 25%; }
  .header-bottom .mail {
    float: left;
    padding: 0 1em;
    width: 25%; }
    .header-bottom .mail .mail-wrapper {
      padding: 0 0 0 2em; }
  .header-bottom .times-block {
    float: left;
    padding: 0 1em;
    width: 25%; }
  .header-bottom .header-cart {
    float: right;
    padding: 0 1em;
    width: 25%; }
  .header-bottom .auth-block {
    width: 100%;
    margin-top: 1em; }
    .header-bottom .auth-block .auth-box {
      float: right;
      padding: 10px 1em 0;
      position: relative; }
      .header-bottom .auth-block .auth-box:before {
        content: '';
        position: absolute;
        width: 20px;
        height: 20px;
        background: url(/assets/images/reg-icon.png) no-repeat;
        background-size: cover;
        left: -11px; }
    .header-bottom .auth-block a {
      color: #473923;
      font-weight: bold;
      text-decoration: none; }
      .header-bottom .auth-block a:hover {
        text-decoration: underline; }
  @media only screen and (max-width: 992px) {
    .header-bottom .logo {
      text-align: center;
      width: 100%; }
    .header-bottom .phones {
      width: 50%; }
      .header-bottom .phones .phones-list:before {
        left: 0; }
    .header-bottom .times-block {
      width: 50%; }
    .header-bottom .header-cart {
      float: right;
      padding: 0 1em 0 0;
      text-align: left;
      width: 50%; }
    .header-bottom .header-contacts {
      padding: 0 1em;
      width: 100%; }
      .header-bottom .header-contacts .mail {
        float: left;
        padding: 0 1em 0 0;
        width: 50%; } }
  @media only screen and (max-width: 600px) {
    .header-bottom .logo {
      float: none;
      text-align: center;
      width: 100%; }
      .header-bottom .logo > a {
        display: block; }
      .header-bottom .logo img.logo-img {
        display: inline-block; }
    .header-bottom .header-contacts {
      float: none;
      padding: 0 1em 1em;
      width: 100%; } }

/* конец раскладки */
.header-bottom > .container {
  position: relative; }

.header-bottom .logo {
  margin: 0.333em 0;
  text-align: center; }
  .header-bottom .logo .logo-img {
    display: inline-block;
    height: auto;
    max-width: 268px;
    max-height: 90px;
    /*width: 100%;*/ }

.header-bottom .phones {
  position: relative;
  margin: 0; }
  .header-bottom .phones .icon:before {
    content: "";
    color: #2a82c4;
    font-family: "FontAwesome";
    font-size: 18px;
    position: absolute;
    margin: -11px 0 0 -10px;
    top: 50%;
    left: 50%;
    color: #edac0a;
    z-index: 2; }

.header-bottom .phones-list {
  display: inline-block;
  list-style: none;
  margin: 0;
  position: relative;
  vertical-align: middle; }

.header-bottom .phones-list__item > a {
  display: block;
  color: #2a82c4;
  font-size: 16px;
  font-weight: bold;
  line-height: 36px;
  text-decoration: none;
  white-space: nowrap; }

.header-bottom .phones-list__item .pref {
  font-weight: normal; }

.header-bottom .mail {
  margin: 0; }
  .header-bottom .mail .mail-wrapper {
    position: relative;
    margin: 0 0 1em; }
    .header-bottom .mail .mail-wrapper .icon:before {
      content: "";
      color: #2a82c4;
      font-family: "FontAwesome";
      font-size: 18px;
      position: absolute;
      margin: -11px 0 0 -10px;
      top: 50%;
      left: 50%;
      color: #edac0a;
      z-index: 2; }
    @media only screen and (max-width: 992px) {
      .header-bottom .mail .mail-wrapper {
        margin: 0 0 0.5em; } }

.header-bottom .mail-link {
  font-size: 15px;
  line-height: 1;
  vertical-align: middle;
  white-space: nowrap; }

.header-bottom .call-order {
  white-space: nowrap; }

.header-bottom .times-block {
  margin: 0; }
  .header-bottom .times-block .title {
    color: #edac0a;
    font-size: 15px;
    line-height: 1;
    margin: 0 0 3px;
    text-transform: uppercase; }

.header-bottom .times {
  color: #494949;
  font-size: 15px;
  padding: 0 0 0 1.5em;
  position: relative;
  white-space: nowrap; }
  .header-bottom .times > span {
    color: #70c8ff; }
  @media only screen and (max-width: 1200px) {
    .header-bottom .times {
      font-size: 14px; } }
  @media only screen and (max-width: 992px) {
    .header-bottom .times {
      font-size: 13px;
      padding-right: 1em; } }
  .header-bottom .times:before {
    content: "";
    color: #2a82c4;
    font-family: "FontAwesome";
    font-size: 18px;
    position: absolute;
    margin: -11px 0 0 -10px;
    top: 50%;
    left: 50%;
    color: #2a82c4;
    left: 0;
    margin-left: 0;
    z-index: 2; }

@media only screen and (max-width: 992px) {
  .header-bottom .logo {
    margin: 0.5em 0; }
  .header-bottom .mail,
  .header-bottom .phones {
    margin: 0 0 1em; }
  .header-bottom .phones-list__item > a {
    font-size: 15px;
    line-height: 31px; } }

.header-cart {
  cursor: default;
  padding: 0 0 5px;
  text-align: right;
  user-select: none; }
  .header-cart.open .cart.full:before {
    color: #edac0a; }
  .header-cart .cart {
    display: inline-block;
    position: relative;
    text-align: left;
    max-width: 150px;
    white-space: nowrap;
    width: 100%; }
    .header-cart .cart:before {
      content: "";
      color: #2a82c4;
      font-family: "FontAwesome";
      font-size: 18px;
      position: absolute;
      margin: -11px 0 0 -10px;
      top: 50%;
      left: 50%;
      color: #edac0a;
      font-size: 34px;
      margin: -19px 0 0;
      left: 0;
      z-index: 2; }
    .header-cart .cart > .header-cart__title-mob {
      display: none; }
    .header-cart .cart > .header-cart__title {
      color: #edac0a;
      font-size: 15px;
      line-height: 1;
      text-transform: uppercase; }
    .header-cart .cart .summ-block {
      padding: 0 0 0 3em; }
    .header-cart .cart > .count {
      padding: 0 0 0 3em; }
      .header-cart .cart > .count > .count-text {
        display: inline-block; }
      .header-cart .cart > .count > .count-quantity {
        display: inline-block; }

.desktop .header-cart .cart.full {
  cursor: pointer; }

.desktop .header-cart .cart:not(.empty):hover:before {
  color: #2a82c4; }

@media only screen and (max-width: 992px) {
  .desktop .header-bottom {
    padding: 0 0 1em; } }

.weather {
  display: block;
  width: 20%;
  float: right;
  margin-right: 1em;
  text-align: center;
  padding: 5px 0; }
  @media screen and (max-width: 480px) {
    .weather {
      width: 50%;
      text-align: center;
      margin: 0 auto;
      float: none; } }
  .weather .SinoptikInformer {
    display: inline-block !important; }
  .weather .SinoptikInformer .siHeader {
    display: none !important; }
  .weather .siTitle {
    display: none !important; }
  .weather .siLinks {
    display: none !important; }
  .weather .SinoptikInformer .siBody {
    background: url(/assets/images/informer-bg.png) no-repeat !important;
    background-size: cover !important;
    border: none !important; }
  .weather .type4c1 .siCityV2 {
    background: url(/assets/images/informer-bg.png) no-repeat !important;
    background-size: cover !important; }
  .weather .type4c1 .siCityV {
    background: none !important; }
  .weather .SinoptikInformer .siCityV {
    border: none !important; }
  .weather .SinoptikInformer .siCityV2 {
    border: none !important; }
  .weather .siLf {
    display: none; }
  .weather .SinoptikInformer .siFooter {
    display: none; }
  .weather .SinoptikInformer .siCityName a {
    font-size: 15px !important; }
    .weather .SinoptikInformer .siCityName a span {
      font-size: 15px !important; }

@media screen and (max-width: 480px) {
  .weather {
    width: 43% !important; } }

/* Вид на мобильных девайсах */
.mobile .menu-button,
.tablet .menu-button {
  background: none;
  border: none;
  cursor: pointer;
  float: left;
  outline: none;
  padding: 0;
  position: relative;
  text-align: center;
  height: 44px;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.15);
  user-select: none;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out; }
  .mobile .menu-button:after,
  .tablet .menu-button:after {
    background: #329ceb;
    border-right: 1px solid #22689d;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 1px; }
  .mobile .menu-button > span,
  .tablet .menu-button > span {
    display: block;
    position: absolute;
    height: 5px;
    width: 28px;
    background: #fff;
    border-radius: 1px;
    opacity: 1;
    left: 8px;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out; }
    .mobile .menu-button > span:nth-child(1),
    .tablet .menu-button > span:nth-child(1) {
      top: 9px;
      transform-origin: left center; }
    .mobile .menu-button > span:nth-child(2),
    .tablet .menu-button > span:nth-child(2) {
      top: 19px;
      transform-origin: left center; }
    .mobile .menu-button > span:nth-child(3),
    .tablet .menu-button > span:nth-child(3) {
      top: 29px;
      transform-origin: left center; }
  .mobile .menu-button.open > span:nth-child(1),
  .tablet .menu-button.open > span:nth-child(1) {
    transform: rotate(45deg);
    top: 8px;
    left: 12px; }
  .mobile .menu-button.open > span:nth-child(2),
  .tablet .menu-button.open > span:nth-child(2) {
    width: 0;
    opacity: 0; }
  .mobile .menu-button.open > span:nth-child(3),
  .tablet .menu-button.open > span:nth-child(3) {
    transform: rotate(-45deg);
    top: 28px;
    left: 12px; }

.mobile .header-top,
.tablet .header-top {
  background: #2a82c4;
  background: url(/assets/images/menu-bg.png);
  backface-visibility: hidden;
  height: 44px;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  right: 0;
  will-change: transform;
  z-index: 20; }
  .mobile .header-top .search,
  .tablet .header-top .search {
    float: right;
    padding: 0;
    position: relative;
    height: 44px;
    width: 44px; }
    .mobile .header-top .search .search-form.open,
    .tablet .header-top .search .search-form.open {
      transform: translate3d(0, 0, 0);
      visibility: visible; }
  .mobile .header-top .search-icon,
  .tablet .header-top .search-icon {
    height: 44px;
    width: 44px; }
    .mobile .header-top .search-icon:before,
    .tablet .header-top .search-icon:before {
      content: "";
      color: #2a82c4;
      font-family: "FontAwesome";
      font-size: 18px;
      position: absolute;
      margin: -11px 0 0 -10px;
      top: 50%;
      left: 50%;
      color: brown;
      margin: -13px 0 0 -7px;
      z-index: 2; }
  .mobile .header-top .search-form,
  .tablet .header-top .search-form {
    background: #fff;
    padding: 10px 44px 10px;
    position: fixed;
    margin: 0;
    top: 0;
    left: 0;
    right: 0;
    height: 44px;
    width: auto;
    max-width: none;
    transform: translate3d(0, -100%, 0);
    transition: all 0.3s ease-out;
    z-index: 10;
    visibility: hidden; }
  .mobile .header-top .search-form__button,
  .tablet .header-top .search-form__button {
    width: 44px; }
    .mobile .header-top .search-form__button:before,
    .tablet .header-top .search-form__button:before {
      content: "";
      color: #2a82c4;
      font-family: "FontAwesome";
      font-size: 18px;
      position: absolute;
      margin: -11px 0 0 -10px;
      top: 50%;
      left: 50%;
      color: #edac0a;
      margin: -12px 0 0 -7px;
      z-index: 2; }
    .mobile .header-top .search-form__button.disabled:before,
    .tablet .header-top .search-form__button.disabled:before {
      color: #999; }
  .mobile .header-top .times-block,
  .tablet .header-top .times-block {
    display: inline-block;
    float: none;
    margin: 0.6em 0 0;
    width: auto; }
    @media only screen and (max-width: 860px) {
      .mobile .header-top .times-block,
      .tablet .header-top .times-block {
        display: none; } }

.mobile .header-bottom,
.tablet .header-bottom {
  border-top: none;
  margin: 0; }
  .mobile .header-bottom .logo,
  .tablet .header-bottom .logo {
    margin: 0;
    padding-top: 0.5em;
    padding-bottom: 0.5em; }
  @media only screen and (max-width: 992px) {
    .mobile .header-bottom .logo,
    .tablet .header-bottom .logo {
      width: 40%; }
    .mobile .header-bottom .header-contacts,
    .tablet .header-bottom .header-contacts {
      padding: 20px 1em;
      width: 60%; } }
  @media only screen and (max-width: 600px) {
    .mobile .header-bottom .header-contacts,
    .tablet .header-bottom .header-contacts {
      padding: 0 0 1em;
      width: 100%; } }
  @media only screen and (max-width: 414px) {
    .mobile .header-bottom .logo,
    .tablet .header-bottom .logo {
      padding: 0 1em;
      width: 100%; } }
  .mobile .header-bottom .weather,
  .tablet .header-bottom .weather {
    width: 100%; }

.mobile .header-contacts,
.tablet .header-contacts {
  width: 70%; }
  .mobile .header-contacts .times,
  .tablet .header-contacts .times {
    padding: 0 0 0 2em; }
    .mobile .header-contacts .times:before,
    .tablet .header-contacts .times:before {
      margin: -22px 0 0 -10px;
      top: 0; }
    @media only screen and (max-width: 414px) {
      .mobile .header-contacts .times,
      .tablet .header-contacts .times {
        font-size: 11px;
        padding: 0 0 0 2%; }
        .mobile .header-contacts .times:before,
        .tablet .header-contacts .times:before {
          margin: -25px 0 0 -25px; } }
  .mobile .header-contacts .times-block,
  .tablet .header-contacts .times-block {
    margin: 10px 0 0;
    text-align: center;
    width: 50%; }
    @media only screen and (max-width: 992px) {
      .mobile .header-contacts .times-block,
      .tablet .header-contacts .times-block {
        margin: 1em 0 0; } }
    @media only screen and (max-width: 414px) {
      .mobile .header-contacts .times-block,
      .tablet .header-contacts .times-block {
        padding: 0 0 0 1em; } }
  .mobile .header-contacts .phones-list,
  .tablet .header-contacts .phones-list {
    display: inline-block;
    vertical-align: middle; }
  .mobile .header-contacts .phones,
  .tablet .header-contacts .phones {
    float: right;
    width: auto;
    padding: 0 1em 0 2em;
    text-align: right; }
    @media only screen and (max-width: 992px) {
      .mobile .header-contacts .phones,
      .tablet .header-contacts .phones {
        margin: 0.25em 0 0; } }
    @media only screen and (max-width: 414px) {
      .mobile .header-contacts .phones,
      .tablet .header-contacts .phones {
        margin: 0.5em 0 0;
        padding: 0 1em 0 0; } }
    @media only screen and (max-width: 330px) {
      .mobile .header-contacts .phones .icon,
      .tablet .header-contacts .phones .icon {
        position: absolute;
        top: 50%;
        margin: -12px 0 0; } }
    .mobile .header-contacts .phones .phones-list__item,
    .tablet .header-contacts .phones .phones-list__item {
      line-height: 1.7em;
      width: auto;
      float: none; }
      @media only screen and (max-width: 992px) {
        .mobile .header-contacts .phones .phones-list__item,
        .tablet .header-contacts .phones .phones-list__item {
          padding: 0; } }
      @media only screen and (max-width: 768px) {
        .mobile .header-contacts .phones .phones-list__item > a,
        .tablet .header-contacts .phones .phones-list__item > a {
          line-height: 30px; } }
      @media only screen and (max-width: 414px) {
        .mobile .header-contacts .phones .phones-list__item,
        .tablet .header-contacts .phones .phones-list__item {
          line-height: 1; }
          .mobile .header-contacts .phones .phones-list__item > a,
          .tablet .header-contacts .phones .phones-list__item > a {
            display: block;
            font-size: 14px;
            line-height: 24px; } }

.mobile .header-cart,
.tablet .header-cart {
  float: right;
  height: 44px;
  width: 44px;
  margin: 0;
  padding: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.15); }
  .mobile .header-cart.open .cart.full:before,
  .tablet .header-cart.open .cart.full:before {
    background: url("/assets/images/sprite.svg") 0 -585px no-repeat;
    content: '';
    display: block;
    pointer-events: none;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -15px 0 0 -15px;
    height: 28px;
    width: 28px;
    z-index: 2; }
  .mobile .header-cart .header-cart__title-mob,
  .tablet .header-cart .header-cart__title-mob {
    display: none; }
  .mobile .header-cart .cart,
  .tablet .header-cart .cart {
    padding: 0;
    width: 100%;
    height: 44px; }
    .mobile .header-cart .cart:after,
    .tablet .header-cart .cart:after {
      background: #329ceb;
      border-right: 1px solid #22689d;
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 1px; }
    .mobile .header-cart .cart:before,
    .tablet .header-cart .cart:before {
      content: "";
      color: #2a82c4;
      font-family: "FontAwesome";
      font-size: 18px;
      position: absolute;
      margin: -11px 0 0 -10px;
      top: 50%;
      left: 50%;
      color: #edac0a;
      font-size: 34px;
      margin: -26px 0 0 -18px;
      left: 50%;
      z-index: 2; }
  .mobile .header-cart .summ-block,
  .tablet .header-cart .summ-block {
    display: none;
    padding: 0; }
  .mobile .header-cart .header-cart__title,
  .tablet .header-cart .header-cart__title {
    display: none; }
  .mobile .header-cart .count,
  .tablet .header-cart .count {
    background: #fff;
    position: absolute;
    border-radius: 50%;
    height: 16px;
    padding: 0;
    text-align: center;
    width: 16px;
    top: 50%;
    margin: -11px 0 0 -7px;
    left: 50%;
    z-index: 2; }
    .mobile .header-cart .count .count-quantity,
    .tablet .header-cart .count .count-quantity {
      color: #222;
      display: block;
      font-size: 11px;
      line-height: 16px;
      letter-spacing: -1px; }
    .mobile .header-cart .count .count-text,
    .tablet .header-cart .count .count-text {
      display: none; }
    .mobile .header-cart .count .qt,
    .tablet .header-cart .count .qt {
      display: none; }

@media only screen and (max-width: 600px) {
  .header-top .search {
    float: right;
    position: relative;
    width: 4em;
    height: 3em;
    perspective-origin: right center; } }

.mobile .header-contacts {
  text-align: center; }

.mobile .auth-block {
  text-align: center;
  width: 50%;
  margin: 0 auto;
  overflow: hidden; }

.mobile .weather {
  width: 43%; }

.owl-carousel {
  display: none;
  height: 100%;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  position: relative;
  visibility: hidden;
  z-index: 1; }
  .owl-carousel .owl-stage {
    position: relative;
    height: 100%;
    -ms-touch-action: pan-Y; }
  .owl-carousel .owl-stage:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0; }
  .owl-carousel .owl-stage-outer {
    position: relative;
    overflow: hidden;
    height: 100%;
    -webkit-transform: translate3d(0, 0, 0); }
  .owl-carousel .owl-item {
    position: relative;
    min-height: 1px;
    float: left;
    height: 100%;
    width: 100%;
    -webkit-backface-visibility: hidden;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    will-change: transform; }
  .owl-carousel .owl-nav .owl-prev,
  .owl-carousel .owl-nav .owl-next,
  .owl-carousel .owl-dot {
    cursor: pointer;
    cursor: hand;
    user-select: none; }
  .owl-carousel .owl-nav {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    height: 0; }
    .owl-carousel .owl-nav.disabled {
      display: none; }
    .owl-carousel .owl-nav .owl-prev,
    .owl-carousel .owl-nav .owl-next {
      color: #fff;
      cursor: pointer;
      font: normal 4em/1 "FontAwesome";
      font-weight: bold;
      opacity: 0.5;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      transition: all 0.3s ease;
      user-select: none;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0.15); }
    .owl-carousel .owl-nav .owl-prev {
      left: 0.3em;
      right: auto; }
      .owl-carousel .owl-nav .owl-prev:hover {
        opacity: 0.9; }
    .owl-carousel .owl-nav .owl-next {
      left: auto;
      right: 0.3em; }
      .owl-carousel .owl-nav .owl-next:hover {
        opacity: 0.9; }
  .owl-carousel .owl-dots {
    /*background: $headerTopBgColor;*/
    display: inline-block;
    padding: 0.21426em 1em 0;
    position: absolute;
    left: 50%;
    bottom: 1em;
    transform: translateX(-50%);
    z-index: 100; }
    .owl-carousel .owl-dots.disabled {
      display: none; }
    @media only screen and (max-width: 768px) {
      .owl-carousel .owl-dots {
        font-size: 12px; } }
    .owl-carousel .owl-dots > .owl-dot {
      display: inline-block;
      zoom: 1; }
      .owl-carousel .owl-dots > .owl-dot.active > span {
        background: #193050;
        background: rgba(25, 48, 80, 0.2);
        border: 0.35643em solid #fff;
        padding: 0.28568em;
        margin: 0.35711em 0.28568em;
        width: 1.18em;
        height: 1.18em; }
      .owl-carousel .owl-dots > .owl-dot > span {
        border: 0.2851em solid transparent;
        margin: 0.71427em 0.64283em;
        background: #fff;
        display: block;
        transition: opacity 200ms ease;
        border-radius: 2.142854em; }
        @media only screen and (max-width: 768px) {
          .owl-carousel .owl-dots > .owl-dot > span {
            border: 0.25em solid transparent; } }
  .owl-carousel .animated {
    animation-duration: 1000ms;
    animation-fill-mode: both; }
  .owl-carousel .owl-animated-in {
    z-index: 0; }
  .owl-carousel .owl-animated-out {
    z-index: 1; }
  .owl-carousel .fadeOut {
    animation-name: fadeOut; }
  .owl-carousel .owl-height {
    transition: height 500ms ease-in-out; }
  .owl-carousel .owl-item .owl-lazy {
    opacity: 0;
    transition: opacity 400ms ease; }
  .owl-carousel .owl-item img.owl-lazy {
    transform-style: preserve-3d; }
  .owl-carousel.owl-loaded {
    display: block;
    visibility: visible; }
  .owl-carousel.owl-loading {
    opacity: 0;
    display: block; }
  .owl-carousel.owl-hidden {
    opacity: 0; }
  .owl-carousel.owl-refresh .owl-item {
    display: none; }
  .owl-carousel.owl-drag .owl-item {
    user-select: none; }
  .owl-carousel.owl-grab {
    cursor: move;
    cursor: grab; }
  .owl-carousel.owl-rtl {
    direction: rtl; }
  .owl-carousel.owl-rtl .owl-item {
    float: right; }

.slider.owl-carousel .owl-item {
  height: 40em;
  /* От 1200px и ниже уменьшаем высоту в зависимости от ширины экрана */ }
  @media screen and (max-width: 1024px) {
    .slider.owl-carousel .owl-item {
      height: 30em; } }

.slider.owl-carousel .slider-item {
  position: relative;
  height: 100%; }
  .slider.owl-carousel .slider-item > a {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }

.slider.owl-carousel .item-label {
  background: #fff;
  background: rgba(255, 255, 255, 0.85);
  padding: 0.5em 1em;
  position: absolute;
  bottom: 20%;
  left: 10%;
  transition: background 0.3s ease; }
  .slider.owl-carousel .item-label > .item-label__link {
    display: block;
    text-decoration: none; }
    .slider.owl-carousel .item-label > .item-label__link > p {
      color: #494949; }
  .slider.owl-carousel .item-label:hover {
    background: #eee;
    background: rgba(255, 255, 255, 0.5); }
    .slider.owl-carousel .item-label:hover > .item-label__link {
      color: #494949; }

.slider-block {
  margin: 1em auto 0;
  max-width: 1200px;
  width: 100%; }

.no-js .owl-carousel {
  display: block; }

.mobile .slider-block,
.tablet .slider-block {
  margin: 1em 0 0; }
  @media only screen and (max-width: 860px) {
    .mobile .slider-block,
    .tablet .slider-block {
      margin: 0; } }

.mobile .slider.owl-carousel,
.tablet .slider.owl-carousel {
  background: none;
  padding: 0; }

.module-inner .owl-carousel .owl-nav .owl-prev,
.module-inner .owl-carousel .owl-nav .owl-next {
  background: #2a82c4;
  border-radius: 50%;
  color: #fff;
  line-height: 0.96em;
  opacity: 1;
  height: 1em;
  width: 1em;
  text-align: center; }
  .module-inner .owl-carousel .owl-nav .owl-prev:hover,
  .module-inner .owl-carousel .owl-nav .owl-next:hover {
    color: #aaa; }

.module-inner .owl-carousel .owl-nav .owl-prev {
  left: -0.5em; }

.module-inner .owl-carousel .owl-nav .owl-next {
  right: -0.5em; }

@media only screen and (max-width: 768px) {
  .module-inner .owl-carousel .owl-nav .owl-prev {
    left: 0; }
  .module-inner .owl-carousel .owl-nav .owl-next {
    right: 0; } }

.tablet .module-inner .owl-carousel .owl-nav .owl-prev,
.tablet .module-inner .owl-carousel .owl-nav .owl-next,
.mobile .module-inner .owl-carousel .owl-nav .owl-prev,
.mobile .module-inner .owl-carousel .owl-nav .owl-next {
  display: none; }

.animated {
  animation-duration: 1s;
  animation-fill-mode: both; }

.animated.infinite {
  animation-iteration-count: infinite; }

.animated.hinge {
  animation-duration: 2s; }

.animated.flipOutX,
.animated.flipOutY,
.animated.bounceIn,
.animated.bounceOut {
  animation-duration: .75s; }

@-webkit-keyframes bounce {
  from, 20%, 53%, 80%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 0, 0); }
  40%, 43% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -30px, 0); }
  70% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -15px, 0); }
  90% {
    transform: translate3d(0, -4px, 0); } }

@keyframes bounce {
  from, 20%, 53%, 80%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 0, 0); }
  40%, 43% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -30px, 0);
    transform: translate3d(0, -30px, 0); }
  70% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0); }
  90% {
    -webkit-transform: translate3d(0, -4px, 0);
    transform: translate3d(0, -4px, 0); } }

.bounce {
  -webkit-animation-name: bounce;
  animation-name: bounce;
  -webkit-transform-origin: center bottom;
  transform-origin: center bottom; }

@-webkit-keyframes flash {
  from, 50%, to {
    opacity: 1; }
  25%, 75% {
    opacity: 0; } }

@keyframes flash {
  from, 50%, to {
    opacity: 1; }
  25%, 75% {
    opacity: 0; } }

.flash {
  -webkit-animation-name: flash;
  animation-name: flash; }

@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

.pulse {
  -webkit-animation-name: pulse;
  animation-name: pulse; }

@-webkit-keyframes rubberBand {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1); }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1); }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1); }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1); }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@keyframes rubberBand {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1); }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1); }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1); }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1); }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

.rubberBand {
  -webkit-animation-name: rubberBand;
  animation-name: rubberBand; }

@-webkit-keyframes shake {
  from, to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  10%, 30%, 50%, 70%, 90% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0); }
  20%, 40%, 60%, 80% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0); } }

@keyframes shake {
  from, to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  10%, 30%, 50%, 70%, 90% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0); }
  20%, 40%, 60%, 80% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0); } }

.shake {
  -webkit-animation-name: shake;
  animation-name: shake; }

@-webkit-keyframes headShake {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  6.5% {
    -webkit-transform: translateX(-6px) rotateY(-9deg);
    transform: translateX(-6px) rotateY(-9deg); }
  18.5% {
    -webkit-transform: translateX(5px) rotateY(7deg);
    transform: translateX(5px) rotateY(7deg); }
  31.5% {
    -webkit-transform: translateX(-3px) rotateY(-5deg);
    transform: translateX(-3px) rotateY(-5deg); }
  43.5% {
    -webkit-transform: translateX(2px) rotateY(3deg);
    transform: translateX(2px) rotateY(3deg); }
  50% {
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

@keyframes headShake {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  6.5% {
    -webkit-transform: translateX(-6px) rotateY(-9deg);
    transform: translateX(-6px) rotateY(-9deg); }
  18.5% {
    -webkit-transform: translateX(5px) rotateY(7deg);
    transform: translateX(5px) rotateY(7deg); }
  31.5% {
    -webkit-transform: translateX(-3px) rotateY(-5deg);
    transform: translateX(-3px) rotateY(-5deg); }
  43.5% {
    -webkit-transform: translateX(2px) rotateY(3deg);
    transform: translateX(2px) rotateY(3deg); }
  50% {
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

.headShake {
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-name: headShake;
  animation-name: headShake; }

@-webkit-keyframes swing {
  20% {
    -webkit-transform: rotate3d(0, 0, 1, 15deg);
    transform: rotate3d(0, 0, 1, 15deg); }
  40% {
    -webkit-transform: rotate3d(0, 0, 1, -10deg);
    transform: rotate3d(0, 0, 1, -10deg); }
  60% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
    transform: rotate3d(0, 0, 1, 5deg); }
  80% {
    -webkit-transform: rotate3d(0, 0, 1, -5deg);
    transform: rotate3d(0, 0, 1, -5deg); }
  to {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg); } }

@keyframes swing {
  20% {
    -webkit-transform: rotate3d(0, 0, 1, 15deg);
    transform: rotate3d(0, 0, 1, 15deg); }
  40% {
    -webkit-transform: rotate3d(0, 0, 1, -10deg);
    transform: rotate3d(0, 0, 1, -10deg); }
  60% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
    transform: rotate3d(0, 0, 1, 5deg); }
  80% {
    -webkit-transform: rotate3d(0, 0, 1, -5deg);
    transform: rotate3d(0, 0, 1, -5deg); }
  to {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg); } }

.swing {
  -webkit-transform-origin: top center;
  transform-origin: top center;
  -webkit-animation-name: swing;
  animation-name: swing; }

@-webkit-keyframes tada {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  10%, 20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg); }
  30%, 50%, 70%, 90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg); }
  40%, 60%, 80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@keyframes tada {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  10%, 20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg); }
  30%, 50%, 70%, 90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg); }
  40%, 60%, 80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

.tada {
  -webkit-animation-name: tada;
  animation-name: tada; }

@-webkit-keyframes wobble {
  from {
    -webkit-transform: none;
    transform: none; }
  15% {
    -webkit-transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
    transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg); }
  30% {
    -webkit-transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
    transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg); }
  45% {
    -webkit-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
    transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg); }
  60% {
    -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
    transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg); }
  75% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg); }
  to {
    -webkit-transform: none;
    transform: none; } }

@keyframes wobble {
  from {
    -webkit-transform: none;
    transform: none; }
  15% {
    -webkit-transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
    transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg); }
  30% {
    -webkit-transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
    transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg); }
  45% {
    -webkit-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
    transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg); }
  60% {
    -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
    transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg); }
  75% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg); }
  to {
    -webkit-transform: none;
    transform: none; } }

.wobble {
  -webkit-animation-name: wobble;
  animation-name: wobble; }

@-webkit-keyframes jello {
  from, 11.1%, to {
    -webkit-transform: none;
    transform: none; }
  22.2% {
    -webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
    transform: skewX(-12.5deg) skewY(-12.5deg); }
  33.3% {
    -webkit-transform: skewX(6.25deg) skewY(6.25deg);
    transform: skewX(6.25deg) skewY(6.25deg); }
  44.4% {
    -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
    transform: skewX(-3.125deg) skewY(-3.125deg); }
  55.5% {
    -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
    transform: skewX(1.5625deg) skewY(1.5625deg); }
  66.6% {
    -webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    transform: skewX(-0.78125deg) skewY(-0.78125deg); }
  77.7% {
    -webkit-transform: skewX(0.39063deg) skewY(0.39063deg);
    transform: skewX(0.39063deg) skewY(0.39063deg); }
  88.8% {
    -webkit-transform: skewX(-0.19531deg) skewY(-0.19531deg);
    transform: skewX(-0.19531deg) skewY(-0.19531deg); } }

@keyframes jello {
  from, 11.1%, to {
    -webkit-transform: none;
    transform: none; }
  22.2% {
    -webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
    transform: skewX(-12.5deg) skewY(-12.5deg); }
  33.3% {
    -webkit-transform: skewX(6.25deg) skewY(6.25deg);
    transform: skewX(6.25deg) skewY(6.25deg); }
  44.4% {
    -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
    transform: skewX(-3.125deg) skewY(-3.125deg); }
  55.5% {
    -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
    transform: skewX(1.5625deg) skewY(1.5625deg); }
  66.6% {
    -webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    transform: skewX(-0.78125deg) skewY(-0.78125deg); }
  77.7% {
    -webkit-transform: skewX(0.39063deg) skewY(0.39063deg);
    transform: skewX(0.39063deg) skewY(0.39063deg); }
  88.8% {
    -webkit-transform: skewX(-0.19531deg) skewY(-0.19531deg);
    transform: skewX(-0.19531deg) skewY(-0.19531deg); } }

.jello {
  -webkit-animation-name: jello;
  animation-name: jello;
  -webkit-transform-origin: center;
  transform-origin: center; }

@-webkit-keyframes bounceIn {
  from, 20%, 40%, 60%, 80%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1); }
  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03); }
  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97); }
  to {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@keyframes bounceIn {
  from, 20%, 40%, 60%, 80%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1); }
  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03); }
  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97); }
  to {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

.bounceIn {
  -webkit-animation-name: bounceIn;
  animation-name: bounceIn; }

@-webkit-keyframes bounceInDown {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0);
    transform: translate3d(0, -3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
    transform: translate3d(0, 25px, 0); }
  75% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0); }
  90% {
    -webkit-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0); }
  to {
    -webkit-transform: none;
    transform: none; } }

@keyframes bounceInDown {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0);
    transform: translate3d(0, -3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
    transform: translate3d(0, 25px, 0); }
  75% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0); }
  90% {
    -webkit-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0); }
  to {
    -webkit-transform: none;
    transform: none; } }

.bounceInDown {
  -webkit-animation-name: bounceInDown;
  animation-name: bounceInDown; }

@-webkit-keyframes bounceInLeft {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
    transform: translate3d(-3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
    transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(5px, 0, 0);
    transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
    transform: none; } }

@keyframes bounceInLeft {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
    transform: translate3d(-3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
    transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(5px, 0, 0);
    transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
    transform: none; } }

.bounceInLeft {
  -webkit-animation-name: bounceInLeft;
  animation-name: bounceInLeft; }

@-webkit-keyframes bounceInRight {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
    transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
    transform: translate3d(-25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
    transform: translate3d(-5px, 0, 0); }
  to {
    -webkit-transform: none;
    transform: none; } }

@keyframes bounceInRight {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
    transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
    transform: translate3d(-25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
    transform: translate3d(-5px, 0, 0); }
  to {
    -webkit-transform: none;
    transform: none; } }

.bounceInRight {
  -webkit-animation-name: bounceInRight;
  animation-name: bounceInRight; }

@-webkit-keyframes bounceInUp {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
    transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes bounceInUp {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
    transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.bounceInUp {
  -webkit-animation-name: bounceInUp;
  animation-name: bounceInUp; }

@-webkit-keyframes bounceOut {
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9); }
  50%, 55% {
    opacity: 1;
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1); }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); } }

@keyframes bounceOut {
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9); }
  50%, 55% {
    opacity: 1;
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1); }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); } }

.bounceOut {
  -webkit-animation-name: bounceOut;
  animation-name: bounceOut; }

@-webkit-keyframes bounceOutDown {
  20% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0); }
  40%, 45% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0); } }

@keyframes bounceOutDown {
  20% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0); }
  40%, 45% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0); } }

.bounceOutDown {
  -webkit-animation-name: bounceOutDown;
  animation-name: bounceOutDown; }

@-webkit-keyframes bounceOutLeft {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(20px, 0, 0);
    transform: translate3d(20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0); } }

@keyframes bounceOutLeft {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(20px, 0, 0);
    transform: translate3d(20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0); } }

.bounceOutLeft {
  -webkit-animation-name: bounceOutLeft;
  animation-name: bounceOutLeft; }

@-webkit-keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
    transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0); } }

@keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
    transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0); } }

.bounceOutRight {
  -webkit-animation-name: bounceOutRight;
  animation-name: bounceOutRight; }

@-webkit-keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0); }
  40%, 45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0); } }

@keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0); }
  40%, 45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0); } }

.bounceOutUp {
  -webkit-animation-name: bounceOutUp;
  animation-name: bounceOutUp; }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn; }

@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown; }

@-webkit-keyframes fadeInDownBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@keyframes fadeInDownBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

.fadeInDownBig {
  -webkit-animation-name: fadeInDownBig;
  animation-name: fadeInDownBig; }

@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft; }

@-webkit-keyframes fadeInLeftBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@keyframes fadeInLeftBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

.fadeInLeftBig {
  -webkit-animation-name: fadeInLeftBig;
  animation-name: fadeInLeftBig; }

@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight; }

@-webkit-keyframes fadeInRightBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@keyframes fadeInRightBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

.fadeInRightBig {
  -webkit-animation-name: fadeInRightBig;
  animation-name: fadeInRightBig; }

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp; }

@-webkit-keyframes fadeInUpBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@keyframes fadeInUpBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

.fadeInUpBig {
  -webkit-animation-name: fadeInUpBig;
  animation-name: fadeInUpBig; }

@-webkit-keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut; }

@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

@keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown; }

@-webkit-keyframes fadeOutDownBig {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0); } }

@keyframes fadeOutDownBig {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0); } }

.fadeOutDownBig {
  -webkit-animation-name: fadeOutDownBig;
  animation-name: fadeOutDownBig; }

@-webkit-keyframes fadeOutLeft {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

@keyframes fadeOutLeft {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

.fadeOutLeft {
  -webkit-animation-name: fadeOutLeft;
  animation-name: fadeOutLeft; }

@-webkit-keyframes fadeOutLeftBig {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0); } }

@keyframes fadeOutLeftBig {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0); } }

.fadeOutLeftBig {
  -webkit-animation-name: fadeOutLeftBig;
  animation-name: fadeOutLeftBig; }

@-webkit-keyframes fadeOutRight {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); } }

@keyframes fadeOutRight {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); } }

.fadeOutRight {
  -webkit-animation-name: fadeOutRight;
  animation-name: fadeOutRight; }

@-webkit-keyframes fadeOutRightBig {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0); } }

@keyframes fadeOutRightBig {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0); } }

.fadeOutRightBig {
  -webkit-animation-name: fadeOutRightBig;
  animation-name: fadeOutRightBig; }

@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

@keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp; }

@-webkit-keyframes fadeOutUpBig {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0); } }

@keyframes fadeOutUpBig {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0); } }

.fadeOutUpBig {
  -webkit-animation-name: fadeOutUpBig;
  animation-name: fadeOutUpBig; }

@-webkit-keyframes flip {
  from {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out; }
  40% {
    -webkit-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out; }
  50% {
    -webkit-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  80% {
    -webkit-transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
    transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; } }

@keyframes flip {
  from {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out; }
  40% {
    -webkit-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out; }
  50% {
    -webkit-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  80% {
    -webkit-transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
    transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; } }

.animated.flip {
  -webkit-backface-visibility: visible;
  backface-visibility: visible;
  -webkit-animation-name: flip;
  animation-name: flip; }

@-webkit-keyframes flipInX {
  from {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0; }
  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1; }
  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg); }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px); } }

@keyframes flipInX {
  from {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0; }
  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1; }
  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg); }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px); } }

.flipInX {
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: flipInX;
  animation-name: flipInX; }

@-webkit-keyframes flipInY {
  from {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0; }
  40% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  60% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    opacity: 1; }
  80% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -5deg); }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px); } }

@keyframes flipInY {
  from {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0; }
  40% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  60% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    opacity: 1; }
  80% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -5deg); }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px); } }

.flipInY {
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: flipInY;
  animation-name: flipInY; }

@-webkit-keyframes flipOutX {
  from {
    -webkit-transform: perspective(400px);
    transform: perspective(400px); }
  30% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1; }
  to {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0; } }

@keyframes flipOutX {
  from {
    -webkit-transform: perspective(400px);
    transform: perspective(400px); }
  30% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1; }
  to {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0; } }

.flipOutX {
  -webkit-animation-name: flipOutX;
  animation-name: flipOutX;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important; }

@-webkit-keyframes flipOutY {
  from {
    -webkit-transform: perspective(400px);
    transform: perspective(400px); }
  30% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    opacity: 1; }
  to {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    opacity: 0; } }

@keyframes flipOutY {
  from {
    -webkit-transform: perspective(400px);
    transform: perspective(400px); }
  30% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    opacity: 1; }
  to {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    opacity: 0; } }

.flipOutY {
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: flipOutY;
  animation-name: flipOutY; }

@-webkit-keyframes lightSpeedIn {
  from {
    -webkit-transform: translate3d(100%, 0, 0) skewX(-30deg);
    transform: translate3d(100%, 0, 0) skewX(-30deg);
    opacity: 0; }
  60% {
    -webkit-transform: skewX(20deg);
    transform: skewX(20deg);
    opacity: 1; }
  80% {
    -webkit-transform: skewX(-5deg);
    transform: skewX(-5deg);
    opacity: 1; }
  to {
    -webkit-transform: none;
    transform: none;
    opacity: 1; } }

@keyframes lightSpeedIn {
  from {
    -webkit-transform: translate3d(100%, 0, 0) skewX(-30deg);
    transform: translate3d(100%, 0, 0) skewX(-30deg);
    opacity: 0; }
  60% {
    -webkit-transform: skewX(20deg);
    transform: skewX(20deg);
    opacity: 1; }
  80% {
    -webkit-transform: skewX(-5deg);
    transform: skewX(-5deg);
    opacity: 1; }
  to {
    -webkit-transform: none;
    transform: none;
    opacity: 1; } }

.lightSpeedIn {
  -webkit-animation-name: lightSpeedIn;
  animation-name: lightSpeedIn;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out; }

@-webkit-keyframes lightSpeedOut {
  from {
    opacity: 1; }
  to {
    -webkit-transform: translate3d(100%, 0, 0) skewX(30deg);
    transform: translate3d(100%, 0, 0) skewX(30deg);
    opacity: 0; } }

@keyframes lightSpeedOut {
  from {
    opacity: 1; }
  to {
    -webkit-transform: translate3d(100%, 0, 0) skewX(30deg);
    transform: translate3d(100%, 0, 0) skewX(30deg);
    opacity: 0; } }

.lightSpeedOut {
  -webkit-animation-name: lightSpeedOut;
  animation-name: lightSpeedOut;
  -webkit-animation-timing-function: ease-in;
  animation-timing-function: ease-in; }

@-webkit-keyframes rotateIn {
  from {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, -200deg);
    transform: rotate3d(0, 0, 1, -200deg);
    opacity: 0; }
  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: none;
    transform: none;
    opacity: 1; } }

@keyframes rotateIn {
  from {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, -200deg);
    transform: rotate3d(0, 0, 1, -200deg);
    opacity: 0; }
  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: none;
    transform: none;
    opacity: 1; } }

.rotateIn {
  -webkit-animation-name: rotateIn;
  animation-name: rotateIn; }

@-webkit-keyframes rotateInDownLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0; }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1; } }

@keyframes rotateInDownLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0; }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1; } }

.rotateInDownLeft {
  -webkit-animation-name: rotateInDownLeft;
  animation-name: rotateInDownLeft; }

@-webkit-keyframes rotateInDownRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0; }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1; } }

@keyframes rotateInDownRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0; }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1; } }

.rotateInDownRight {
  -webkit-animation-name: rotateInDownRight;
  animation-name: rotateInDownRight; }

@-webkit-keyframes rotateInUpLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0; }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1; } }

@keyframes rotateInUpLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0; }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1; } }

.rotateInUpLeft {
  -webkit-animation-name: rotateInUpLeft;
  animation-name: rotateInUpLeft; }

@-webkit-keyframes rotateInUpRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, -90deg);
    transform: rotate3d(0, 0, 1, -90deg);
    opacity: 0; }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1; } }

@keyframes rotateInUpRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, -90deg);
    transform: rotate3d(0, 0, 1, -90deg);
    opacity: 0; }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1; } }

.rotateInUpRight {
  -webkit-animation-name: rotateInUpRight;
  animation-name: rotateInUpRight; }

@-webkit-keyframes rotateOut {
  from {
    -webkit-transform-origin: center;
    transform-origin: center;
    opacity: 1; }
  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, 200deg);
    transform: rotate3d(0, 0, 1, 200deg);
    opacity: 0; } }

@keyframes rotateOut {
  from {
    -webkit-transform-origin: center;
    transform-origin: center;
    opacity: 1; }
  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, 200deg);
    transform: rotate3d(0, 0, 1, 200deg);
    opacity: 0; } }

.rotateOut {
  -webkit-animation-name: rotateOut;
  animation-name: rotateOut; }

@-webkit-keyframes rotateOutDownLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    opacity: 1; }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0; } }

@keyframes rotateOutDownLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    opacity: 1; }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0; } }

.rotateOutDownLeft {
  -webkit-animation-name: rotateOutDownLeft;
  animation-name: rotateOutDownLeft; }

@-webkit-keyframes rotateOutDownRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    opacity: 1; }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0; } }

@keyframes rotateOutDownRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    opacity: 1; }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0; } }

.rotateOutDownRight {
  -webkit-animation-name: rotateOutDownRight;
  animation-name: rotateOutDownRight; }

@-webkit-keyframes rotateOutUpLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    opacity: 1; }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0; } }

@keyframes rotateOutUpLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    opacity: 1; }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0; } }

.rotateOutUpLeft {
  -webkit-animation-name: rotateOutUpLeft;
  animation-name: rotateOutUpLeft; }

@-webkit-keyframes rotateOutUpRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    opacity: 1; }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, 90deg);
    transform: rotate3d(0, 0, 1, 90deg);
    opacity: 0; } }

@keyframes rotateOutUpRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    opacity: 1; }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, 90deg);
    transform: rotate3d(0, 0, 1, 90deg);
    opacity: 0; } }

.rotateOutUpRight {
  -webkit-animation-name: rotateOutUpRight;
  animation-name: rotateOutUpRight; }

@-webkit-keyframes hinge {
  0% {
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out; }
  20%, 60% {
    -webkit-transform: rotate3d(0, 0, 1, 80deg);
    transform: rotate3d(0, 0, 1, 80deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out; }
  40%, 80% {
    -webkit-transform: rotate3d(0, 0, 1, 60deg);
    transform: rotate3d(0, 0, 1, 60deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    opacity: 1; }
  to {
    -webkit-transform: translate3d(0, 700px, 0);
    transform: translate3d(0, 700px, 0);
    opacity: 0; } }

@keyframes hinge {
  0% {
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out; }
  20%, 60% {
    -webkit-transform: rotate3d(0, 0, 1, 80deg);
    transform: rotate3d(0, 0, 1, 80deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out; }
  40%, 80% {
    -webkit-transform: rotate3d(0, 0, 1, 60deg);
    transform: rotate3d(0, 0, 1, 60deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    opacity: 1; }
  to {
    -webkit-transform: translate3d(0, 700px, 0);
    transform: translate3d(0, 700px, 0);
    opacity: 0; } }

.hinge {
  -webkit-animation-name: hinge;
  animation-name: hinge; }

@-webkit-keyframes rollIn {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
    transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@keyframes rollIn {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
    transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

.rollIn {
  -webkit-animation-name: rollIn;
  animation-name: rollIn; }

@-webkit-keyframes rollOut {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
    transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg); } }

@keyframes rollOut {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
    transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg); } }

.rollOut {
  -webkit-animation-name: rollOut;
  animation-name: rollOut; }

@-webkit-keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  50% {
    opacity: 1; } }

@keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  50% {
    opacity: 1; } }

.zoomIn {
  -webkit-animation-name: zoomIn;
  animation-name: zoomIn; }

@-webkit-keyframes zoomInDown {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

@keyframes zoomInDown {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

.zoomInDown {
  -webkit-animation-name: zoomInDown;
  animation-name: zoomInDown; }

@-webkit-keyframes zoomInLeft {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

@keyframes zoomInLeft {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

.zoomInLeft {
  -webkit-animation-name: zoomInLeft;
  animation-name: zoomInLeft; }

@-webkit-keyframes zoomInRight {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

@keyframes zoomInRight {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

.zoomInRight {
  -webkit-animation-name: zoomInRight;
  animation-name: zoomInRight; }

@-webkit-keyframes zoomInUp {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

@keyframes zoomInUp {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

.zoomInUp {
  -webkit-animation-name: zoomInUp;
  animation-name: zoomInUp; }

@-webkit-keyframes zoomOut {
  from {
    opacity: 1; }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  to {
    opacity: 0; } }

@keyframes zoomOut {
  from {
    opacity: 1; }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  to {
    opacity: 0; } }

.zoomOut {
  -webkit-animation-name: zoomOut;
  animation-name: zoomOut; }

@-webkit-keyframes zoomOutDown {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

@keyframes zoomOutDown {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

.zoomOutDown {
  -webkit-animation-name: zoomOutDown;
  animation-name: zoomOutDown; }

@-webkit-keyframes zoomOutLeft {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(-2000px, 0, 0);
    transform: scale(0.1) translate3d(-2000px, 0, 0);
    -webkit-transform-origin: left center;
    transform-origin: left center; } }

@keyframes zoomOutLeft {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(-2000px, 0, 0);
    transform: scale(0.1) translate3d(-2000px, 0, 0);
    -webkit-transform-origin: left center;
    transform-origin: left center; } }

.zoomOutLeft {
  -webkit-animation-name: zoomOutLeft;
  animation-name: zoomOutLeft; }

@-webkit-keyframes zoomOutRight {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(2000px, 0, 0);
    transform: scale(0.1) translate3d(2000px, 0, 0);
    -webkit-transform-origin: right center;
    transform-origin: right center; } }

@keyframes zoomOutRight {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(2000px, 0, 0);
    transform: scale(0.1) translate3d(2000px, 0, 0);
    -webkit-transform-origin: right center;
    transform-origin: right center; } }

.zoomOutRight {
  -webkit-animation-name: zoomOutRight;
  animation-name: zoomOutRight; }

@-webkit-keyframes zoomOutUp {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

@keyframes zoomOutUp {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

.zoomOutUp {
  -webkit-animation-name: zoomOutUp;
  animation-name: zoomOutUp; }

@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown; }

@-webkit-keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft; }

@-webkit-keyframes slideInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes slideInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInRight {
  -webkit-animation-name: slideInRight;
  animation-name: slideInRight; }

@-webkit-keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInUp {
  -webkit-animation-name: slideInUp;
  animation-name: slideInUp; }

@-webkit-keyframes slideOutDown {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

@keyframes slideOutDown {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

.slideOutDown {
  -webkit-animation-name: slideOutDown;
  animation-name: slideOutDown; }

@-webkit-keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

@keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

.slideOutLeft {
  -webkit-animation-name: slideOutLeft;
  animation-name: slideOutLeft; }

@-webkit-keyframes slideOutRight {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); } }

@keyframes slideOutRight {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); } }

.slideOutRight {
  -webkit-animation-name: slideOutRight;
  animation-name: slideOutRight; }

@-webkit-keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

@keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

.slideOutUp {
  -webkit-animation-name: slideOutUp;
  animation-name: slideOutUp; }

nav {
  background: #2a82c4;
  width: 100%;
  background: url(/assets/images/menu-bg.png) no-repeat;
  background-size: cover;
  background-position: top center;
  /* Всплывающее меню */ }
  nav .menu_level_1 > .menu-item.selected {
    background: #dd8200; }
  nav input {
    opacity: 0.6; }
  nav .menu_level_1 {
    display: table;
    padding: 0;
    list-style: none;
    width: 100%; }
    nav .menu_level_1 > li {
      display: table-cell;
      position: relative;
      vertical-align: top; }
      nav .menu_level_1 > li > a {
        font-family: 'Bookman Old Style';
        color: #473923;
        font-weight: normal;
        display: block;
        padding: 1em 0.5em;
        text-transform: uppercase;
        text-decoration: none;
        text-align: center;
        text-shadow: 3px 3px 5px white, -3px -3px 5px white, -5px -5px 10px white, 5px 5px 10px white; }
      nav .menu_level_1 > li.selected {
        cursor: default; }
        nav .menu_level_1 > li.selected > a {
          color: #999; }
    nav .menu_level_1 a {
      display: block;
      text-decoration: none; }
    nav .menu_level_1.deep-3 > li.selected > a, nav .menu_level_1.deep-2 > li.selected > a {
      color: #edac0a; }
    nav .menu_level_1.deep-3 .menu_level_2 > li.selected > a {
      color: #edac0a; }
      nav .menu_level_1.deep-3 .menu_level_2 > li.selected > a:hover {
        color: #bb7a00;
        text-decoration: underline; }
  nav .menu_level_2 {
    background: #FAEDCA;
    box-shadow: none;
    display: table;
    list-style: none;
    margin: 0;
    min-width: 260px;
    padding: 0;
    position: absolute;
    opacity: 0;
    top: 100%;
    left: 0;
    z-index: 999;
    text-align: left;
    text-transform: none;
    visibility: hidden; }
    nav .menu_level_2 > li {
      display: table-cell;
      padding: 1em; }
      nav .menu_level_2 > li .menu-item__photo {
        float: left;
        width: 50%; }
        nav .menu_level_2 > li .menu-item__photo > img {
          display: block;
          height: auto;
          max-width: 100px;
          width: 100%; }
      nav .menu_level_2 > li .menu-item__description {
        float: right;
        width: 50%; }
        nav .menu_level_2 > li .menu-item__description > span {
          font-size: 12px; }
      nav .menu_level_2 > li .menu-item__header > span {
        color: #7b7b7b;
        font-size: 12px;
        vertical-align: top; }
      nav .menu_level_2 > li > a {
        color: #171717;
        display: block;
        font-size: 16px; }
  nav .menu_level_3 {
    display: block;
    list-style: none;
    margin-left: 10px; }
    nav .menu_level_3 > li > a {
      color: #171717;
      display: block; }
    nav .menu_level_3 > li.selected > a {
      color: #edac0a; }

.desktop nav .menu_level_1 {
  padding: 0 1em;
  position: relative;
  width: 100%; }
  .desktop nav .menu_level_1 > li {
    /* Показываем всплывашку на ховер */ }
    .desktop nav .menu_level_1 > li.subcat:hover .menu_level_2 {
      opacity: 1;
      transition: opacity 0.3s ease, transform 0.3s ease-out;
      visibility: visible;
      transform: translate3d(0, 0, 0); }
    .desktop nav .menu_level_1 > li:not(.selected):hover > a {
      text-decoration: underline; }

.desktop nav .menu_level_2 {
  display: table-row;
  left: 1em;
  transform-origin: center;
  transform: translate3d(0, 1em, 0); }
  .desktop nav .menu_level_2 > li {
    float: left;
    width: 100%; }
    .desktop nav .menu_level_2 > li.selected > a {
      color: #edac0a; }
    .desktop nav .menu_level_2 > li:not(.selected) > a:hover {
      color: #edac0a; }

.desktop nav .menu_level_3 > li:not(.selected):hover > a {
  text-decoration: underline; }

.desktop nav .search {
  float: right;
  margin: 3px 0 0;
  padding: 0 1em;
  width: 250px; }

.desktop nav .menu-wrapper {
  float: left;
  padding: 0 1em;
  width: calc(100% - 250px - 2em); }

.nav-left {
  /*padding: 0 1em;*/ }

.nav-left {
  position: relative; }
  .nav-left .menu_level_1 {
    display: block; }
    .nav-left .menu_level_1 > li {
      list-style: none;
      transition: all 0.3s ease;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0.15); }
      .nav-left .menu_level_1 > li:first-child {
        border-top: none; }
      .nav-left .menu_level_1 > li:last-child {
        border-bottom: none; }
      .nav-left .menu_level_1 > li > a {
        display: block;
        color: #494949;
        font-size: 17px;
        padding: 10px 1em 8px;
        position: relative;
        text-decoration: none;
        transition: color 0.3s ease; }
        .nav-left .menu_level_1 > li > a:after:before {
          content: "";
          color: #2a82c4;
          font-family: "FontAwesome";
          font-size: 18px;
          position: absolute;
          margin: -11px 0 0 -10px;
          top: 50%;
          left: 50%;
          color: #333;
          font-size: 12px;
          margin-top: -7px;
          top: 50%;
          left: auto;
          right: 1em;
          transition: 0.1s ease 0.07s;
          z-index: 2; }
      .nav-left .menu_level_1 > li.subcats_exists:not(.selected):hover > a:after {
        right: 1em;
        transform-origin: left bottom;
        transform: rotate(90deg); }
      .nav-left .menu_level_1 > li.selected {
        cursor: default; }
        .nav-left .menu_level_1 > li.selected > a {
          background: #fcbb19;
          color: #fff;
          text-decoration: none; }
        .nav-left .menu_level_1 > li.selected.subcats_exists > a:after {
          color: #fff;
          transform: rotate(90deg); }
  .nav-left .menu_level_2 {
    background: #fff;
    display: block;
    padding: 0; }
    .nav-left .menu_level_2 > li {
      list-style: none; }
      .nav-left .menu_level_2 > li > a {
        color: #494949;
        display: block;
        font-size: 15px;
        padding: 8px 1em 8px 42px;
        text-decoration: none; }
      .nav-left .menu_level_2 > li:hover > a {
        color: #edac0a;
        text-decoration: underline; }
      .nav-left .menu_level_2 > li.selected > a {
        color: #edac0a;
        text-decoration: none; }
  .nav-left .menu_level_3 {
    background: #f1f1f1;
    border-top: 1px solid #d7d7d7;
    padding: 0.5em 1em 0.75em 2em; }
    .nav-left .menu_level_3 > li {
      list-style: disc;
      padding: 0.2em 0; }
      .nav-left .menu_level_3 > li > span > a {
        color: #666;
        display: block;
        font-size: 0.86em;
        text-decoration: none; }
      .nav-left .menu_level_3 > li.selected {
        color: #1c8db1; }
      .nav-left .menu_level_3 > li:hover > span > a {
        color: #1c8db1; }

/* ховеры только на десктопах */
.desktop .nav-left .menu_level_1 > li:not(.selected):hover {
  background: #2a82c4; }
  .desktop .nav-left .menu_level_1 > li:not(.selected):hover > a {
    color: #fff; }
    .desktop .nav-left .menu_level_1 > li:not(.selected):hover > a:after {
      right: 0.8em; }

@media screen and (max-width: 768px) {
  .main-left-menu {
    position: relative; }
    .main-left-menu .open-module {
      position: absolute;
      width: 30px;
      height: 30px;
      right: 25px;
      top: 4px; }
      .main-left-menu .open-module:before {
        content: "";
        color: #2a82c4;
        font-family: "FontAwesome";
        font-size: 18px;
        position: absolute;
        margin: -11px 0 0 -10px;
        top: 50%;
        left: 50%;
        color: #fff;
        font-size: 22px;
        transition: 0.1s ease 0.07s;
        margin: -18px 0 0 -10px;
        z-index: 2; }
    .main-left-menu .catalog .module-wrapper {
      display: none; } }

.mobile nav,
.tablet nav {
  background: url(/assets/images/nav-bg.jpg) no-repeat;
  background-size: cover;
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
  width: 260px;
  z-index: 1;
  text-align: left;
  transition: transform 0s ease-out, visibility 0s;
  transform: translate3d(0, 0, 0);
  -webkit-overflow-scrolling: touch; }
  .mobile nav.show-menu,
  .tablet nav.show-menu {
    display: block !important;
    height: calc(100vh - 3em);
    transform: translate3d(0, 0, 0) !important;
    transition: transform 0.4s ease-out;
    visibility: visible !important; }
  .mobile nav .arrow,
  .tablet nav .arrow {
    height: 38px;
    outline: none;
    position: absolute;
    right: 0;
    top: 0;
    width: 38px;
    transition: transform 0.3s ease;
    transform: translate3d(0, 0, 0);
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0.15);
    will-change: transform;
    z-index: 10; }
    .mobile nav .arrow:before,
    .tablet nav .arrow:before {
      background: url("/assets/images/sprite.svg") 0px -495px no-repeat;
      content: '';
      position: absolute;
      margin-left: -7px;
      margin-top: -4px;
      height: 8px;
      width: 14px;
      left: 50%;
      top: 50%;
      transition: transform 0.3s ease;
      will-change: transform; }
  .mobile nav .menu_level_1,
  .tablet nav .menu_level_1 {
    display: block;
    margin-bottom: 3em; }
    .mobile nav .menu_level_1 > li,
    .tablet nav .menu_level_1 > li {
      display: block;
      width: auto; }
      .mobile nav .menu_level_1 > li.selected > .mob-icon,
      .tablet nav .menu_level_1 > li.selected > .mob-icon {
        color: #222 !important; }
      .mobile nav .menu_level_1 > li.selected.subcat .menu_level_2,
      .tablet nav .menu_level_1 > li.selected.subcat .menu_level_2 {
        display: block; }
      .mobile nav .menu_level_1 > li.subcat > a,
      .tablet nav .menu_level_1 > li.subcat > a {
        width: calc(100% - 38px); }
      .mobile nav .menu_level_1 > li.open .arrow,
      .tablet nav .menu_level_1 > li.open .arrow {
        transform: translate3d(0, 0, 0);
        transition: transform 0.3s ease; }
        .mobile nav .menu_level_1 > li.open .arrow.rotate:before,
        .tablet nav .menu_level_1 > li.open .arrow.rotate:before {
          transform: rotate(180deg);
          transition: transform 0.3s ease; }
      .mobile nav .menu_level_1 > li > a,
      .tablet nav .menu_level_1 > li > a {
        padding: 1em 1em 1em 1.5em;
        font-size: 11px;
        text-align: left; }
      .mobile nav .menu_level_1 > li.selected > a,
      .tablet nav .menu_level_1 > li.selected > a {
        color: #edac0a; }
      .mobile nav .menu_level_1 > li:not(.selected) > a:hover,
      .tablet nav .menu_level_1 > li:not(.selected) > a:hover {
        color: #edac0a; }
    .mobile nav .menu_level_1 .mob-icon,
    .tablet nav .menu_level_1 .mob-icon {
      top: 0.8em;
      left: 1em; }
  .mobile nav .menu_level_2,
  .tablet nav .menu_level_2 {
    background: rgba(55, 55, 55, 0.9);
    display: none;
    position: static;
    overflow: hidden;
    opacity: 1;
    transition: all 0s;
    visibility: visible;
    will-change: transform;
    z-index: 5; }
    .mobile nav .menu_level_2 > li,
    .tablet nav .menu_level_2 > li {
      display: block;
      padding: 0; }
      .mobile nav .menu_level_2 > li > a,
      .tablet nav .menu_level_2 > li > a {
        padding: 0.5em 1em 0.6em 2em;
        color: #fff;
        font-size: 13px; }
      .mobile nav .menu_level_2 > li.selected > a,
      .tablet nav .menu_level_2 > li.selected > a {
        color: #edac0a; }
      .mobile nav .menu_level_2 > li.selected.subcat .menu_level_3,
      .tablet nav .menu_level_2 > li.selected.subcat .menu_level_3 {
        display: block; }
      .mobile nav .menu_level_2 > li:not(.selected) > a:hover,
      .tablet nav .menu_level_2 > li:not(.selected) > a:hover {
        color: #edac0a; }
    .mobile nav .menu_level_2 .mob-icon,
    .tablet nav .menu_level_2 .mob-icon {
      display: none; }
  .mobile nav .menu_level_3,
  .tablet nav .menu_level_3 {
    background: rgba(55, 55, 55, 0.7);
    display: none; }
    .mobile nav .menu_level_3 > li,
    .tablet nav .menu_level_3 > li {
      display: block;
      padding: 0; }
      .mobile nav .menu_level_3 > li > a,
      .tablet nav .menu_level_3 > li > a {
        padding: 0.5em 1em 0.6em 3em;
        color: #fff;
        font-size: 13px; }
      .mobile nav .menu_level_3 > li.selected > a,
      .tablet nav .menu_level_3 > li.selected > a {
        color: #edac0a; }
      .mobile nav .menu_level_3 > li:not(.selected) > a:hover,
      .tablet nav .menu_level_3 > li:not(.selected) > a:hover {
        color: #edac0a; }

.mobile .nav-left,
.tablet .nav-left {
  padding: 0; }

.mobile nav .header-contacts,
.tablet nav .header-contacts {
  padding: 1em; }
  .mobile nav .header-contacts .phones-list,
  .tablet nav .header-contacts .phones-list {
    display: inline-block; }
  .mobile nav .header-contacts .phones-list__item,
  .tablet nav .header-contacts .phones-list__item {
    display: block;
    list-style: none; }
    .mobile nav .header-contacts .phones-list__item > a,
    .tablet nav .header-contacts .phones-list__item > a {
      color: #fff;
      text-decoration: none; }
  .mobile nav .header-contacts .call-order-wrapper,
  .tablet nav .header-contacts .call-order-wrapper {
    text-align: center; }

.mobile nav .call-order-wrapper,
.tablet nav .call-order-wrapper {
  text-align: center; }

/* Левое меню */
.mobile .nav-left .menu_level_1,
.tablet .nav-left .menu_level_1 {
  display: block; }
  .mobile .nav-left .menu_level_1 > li,
  .tablet .nav-left .menu_level_1 > li {
    position: relative; }
    .mobile .nav-left .menu_level_1 > li .mob-icon,
    .tablet .nav-left .menu_level_1 > li .mob-icon {
      margin-top: -7px;
      top: 50%;
      left: 0.5em; }
    .mobile .nav-left .menu_level_1 > li > a,
    .tablet .nav-left .menu_level_1 > li > a {
      padding: 10px 1em 8px 2em; }

.feedback {
  padding: 1em; }

.feedback-form .feedback-col-6 {
  float: left;
  padding: 0 0.5em 0 0;
  width: 60%; }
  .feedback-form .feedback-col-6.last {
    padding: 0 0 0 0.5em;
    width: 40%; }
    .feedback-form .feedback-col-6.last .feedback-block {
      margin: 0 0 1em; }

.feedback-form .feedback-button-block {
  float: left;
  margin: 0;
  width: 100%; }
  .feedback-form .feedback-button-block .form_title {
    position: static; }
  .feedback-form .feedback-button-block .form_content {
    margin: 0.5em 0 0; }

.feedback-form input {
  width: 100%; }
  .feedback-form input.button {
    width: auto; }

.feedback-form textarea {
  resize: none;
  margin: 0 0 0.5em;
  height: 152px;
  width: 100%; }

/* Раскладка футера */
.footer-top .contacts {
  float: left;
  padding: 0 1em;
  width: 33.333%; }
  @media only screen and (max-width: 768px) {
    .footer-top .contacts {
      width: 100%;
      text-align: center; }
      .footer-top .contacts > div {
        display: inline-block; }
      .footer-top .contacts .logo {
        width: 100%; }
      .footer-top .contacts .phone > ul > li {
        display: inline-block; }
        .footer-top .contacts .phone > ul > li:first-child {
          margin: 0 10px 0 0; } }
  @media only screen and (max-width: 768px) and (max-width: 414px) {
    .footer-top .contacts .phone > ul > li {
      display: block; }
    .footer-top .contacts .icon {
      left: 0; } }

.footer-top .footer-menu {
  float: right;
  padding: 0 1em;
  width: 66.666%; }
  @media only screen and (max-width: 768px) {
    .footer-top .footer-menu {
      width: 100%; } }

.footer-bottom .payments {
  float: left;
  padding: 0 1em;
  width: 25%; }

.footer-bottom .social-block {
  float: left;
  padding: 0 1em;
  width: 45%; }

/* Оформление */
.footer-top {
  background: linear-gradient(to top, #0b3455, #143f61);
  padding: 1em 0 3em; }
  .footer-top .footer-menu {
    margin: 1.5em 0 0; }
    .footer-top .footer-menu .title {
      font-size: 16px;
      color: #fff;
      margin: 0 0 1em;
      text-transform: uppercase; }
    .footer-top .footer-menu .ishop,
    .footer-top .footer-menu .text {
      float: left;
      width: 50%; }
      @media only screen and (max-width: 414px) {
        .footer-top .footer-menu .ishop,
        .footer-top .footer-menu .text {
          width: 100%;
          text-align: center;
          margin: 0 0 1em; } }
      .footer-top .footer-menu .ishop .item > a,
      .footer-top .footer-menu .text .item > a {
        line-height: 1.666; }
      .footer-top .footer-menu .ishop .item.selected > a,
      .footer-top .footer-menu .text .item.selected > a {
        color: #edac0a;
        cursor: default;
        text-decoration: none; }
  .footer-top .adres {
    margin: 0 0 0.5em;
    position: relative;
    padding: 0 1em 0 2em; }
    .footer-top .adres .icon:before {
      content: "";
      color: #2a82c4;
      font-family: "FontAwesome";
      font-size: 18px;
      position: absolute;
      margin: -11px 0 0 -10px;
      top: 50%;
      left: 50%;
      color: #edac0a;
      z-index: 2; }
  .footer-top .phone {
    margin: 0 0 0.5em;
    padding: 0 1em 0 2em;
    position: relative; }
    .footer-top .phone .icon {
      vertical-align: top; }
      .footer-top .phone .icon:before {
        content: "";
        color: #2a82c4;
        font-family: "FontAwesome";
        font-size: 18px;
        position: absolute;
        margin: -11px 0 0 -10px;
        top: 50%;
        left: 50%;
        color: #edac0a;
        z-index: 2; }
    .footer-top .phone > ul {
      display: inline-block;
      list-style: none; }
      .footer-top .phone > ul > li {
        color: #fff;
        font-weight: bold; }
        .footer-top .phone > ul > li .pref {
          font-weight: normal; }
  .footer-top .mail {
    margin: 0 0 0.5em;
    position: relative;
    padding: 0 1em 0 2em; }
    .footer-top .mail .icon:before {
      content: "";
      color: #2a82c4;
      font-family: "FontAwesome";
      font-size: 18px;
      position: absolute;
      margin: -11px 0 0 -10px;
      top: 50%;
      left: 50%;
      color: #edac0a;
      z-index: 2; }

.footer-bottom {
  background: url(/assets/images/menu-bg.png) no-repeat;
  background-size: cover;
  background-position: top center;
  padding: 0.25em 0; }
  @media only screen and (max-width: 600px) {
    .footer-bottom .container {
      text-align: center; }
      .footer-bottom .container > div {
        width: 100%; } }
  .footer-bottom .payments {
    margin: 7px 0 0; }
    .footer-bottom .payments > img {
      max-width: 290px;
      width: 100%; }
  .footer-bottom .social-block {
    margin: 0.5em 0;
    text-align: center; }
  .footer-bottom .social-block__item {
    border: 1px solid #fff;
    border-radius: 50%;
    cursor: pointer;
    display: inline-block;
    height: 1.7em;
    margin: 0 0.5em 0 0;
    width: 1.7em; }
    .footer-bottom .social-block__item:hover > a:before {
      color: #edac0a; }
    .footer-bottom .social-block__item > a {
      display: inline-block;
      position: relative;
      line-height: 1;
      text-decoration: none;
      vertical-align: middle; }
      .footer-bottom .social-block__item > a:before {
        content: '';
        color: #2a82c4;
        font-family: "FontAwesome";
        font-size: 18px;
        position: absolute;
        margin: -11px 0 0 -10px;
        top: 50%;
        left: 50%;
        color: #fff;
        font-size: 1em;
        margin: -8px 0 0 -7px;
        z-index: 2; }
      .footer-bottom .social-block__item > a.si1:before {
        content: '\f189';
        margin: -8px 0 0 -8px; }
      .footer-bottom .social-block__item > a.si2:before {
        content: '\f263';
        margin: -8px 0 0 -5px; }
      .footer-bottom .social-block__item > a.si3:before {
        content: '\f09a';
        margin: -8px 0 0 -5px; }
      .footer-bottom .social-block__item > a.si4:before {
        content: '\f099';
        margin: -8px 0 0 -6px; }
      .footer-bottom .social-block__item > a.si5:before {
        content: '\f1a0';
        margin: -8px 0 0 -6px; }
      .footer-bottom .social-block__item > a.si6:before {
        content: '\f1fa';
        margin: -8px 0 0 -6px; }
      .footer-bottom .social-block__item > a.si7:before {
        content: '\f167';
        margin: -8px 0 0 -6px; }
      .footer-bottom .social-block__item > a.si8:before {
        content: '\f16d';
        margin: -8px 0 0 -7px; }
  .footer-bottom .counters {
    margin: 0.25em 0 0;
    padding: 0 1em; }
    .footer-bottom .counters > a {
      display: inline-block;
      vertical-align: middle;
      float: left; }
    .footer-bottom .counters > .citron-logo__link {
      display: inline-block;
      max-width: 92px;
      width: 100%;
      position: relative;
      vertical-align: middle;
      float: right; }
      .footer-bottom .counters > .citron-logo__link:hover .citron-logo--hover {
        opacity: 1;
        visibility: visible; }
    .footer-bottom .counters .citron-logo--hover {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      transition: opacity 0.3s ease;
      visibility: hidden; }

/* Айшоп  */
/* Выбор валюты */
.currency-list {
  display: block;
  list-style: none;
  margin: 0;
  vertical-align: middle; }
  .currency-list > li {
    color: #fff;
    cursor: pointer;
    display: inline-block;
    position: relative;
    margin: 0 6px 0 0;
    width: 36px;
    text-align: center;
    padding: 8px 0 5px;
    height: 38px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0.15);
    /* Всплывающая подсказка */ }
    .currency-list > li:after {
      background: #edac0a;
      content: '';
      position: absolute;
      top: 100%;
      left: 0;
      opacity: 0;
      font-size: 11px;
      color: #fff;
      display: block;
      padding: 0.3em 0.6em 0.4em;
      visibility: hidden;
      white-space: nowrap;
      z-index: 10; }
    .currency-list > li:not(.selected):after {
      content: 'Выбрать'; }
    .currency-list > li.selected {
      color: #edac0a;
      cursor: default; }
      .currency-list > li.selected:after {
        content: 'Текущая валюта'; }
    .currency-list > li:last-child {
      margin: 0; }
    .currency-list > li.rub:before {
      content: "";
      color: #2a82c4;
      font-family: "FontAwesome";
      font-size: 18px;
      position: absolute;
      margin: -11px 0 0 -10px;
      top: 50%;
      left: 50%;
      color: #fff;
      font-size: 24px;
      margin: -17px 0 0 -6px;
      z-index: 2; }
    .currency-list > li.rub:not(.selected):hover:before {
      color: #edac0a; }
    .currency-list > li.rub.selected:before {
      color: #edac0a; }
    .currency-list > li.dol:before {
      content: "";
      color: #2a82c4;
      font-family: "FontAwesome";
      font-size: 18px;
      position: absolute;
      margin: -11px 0 0 -10px;
      top: 50%;
      left: 50%;
      color: #fff;
      font-size: 21px;
      margin: -16px 0 0 -6px;
      z-index: 2; }
    .currency-list > li.dol:not(.selected):hover:before {
      color: #edac0a; }
    .currency-list > li.dol.selected:before {
      color: #edac0a; }

.desktop .currency-list > li:hover:after {
  opacity: 1;
  transition: opacity 0.3s ease 0.2s;
  visibility: visible; }

.module.ajaxflowcart {
  background: #2c2a7d;
  overflow: visible;
  margin: 0; }

.ajaxflowcart-block {
  background: #fff;
  border-radius: 0 0 0px 0px;
  box-shadow: 6px 8px 32px 0 rgba(0, 0, 0, 0.2);
  margin: 0;
  opacity: 0;
  padding: 0;
  position: absolute;
  right: 1em;
  text-align: center;
  top: 100%;
  transform: translate3d(0, -100%, 0);
  visibility: hidden;
  width: 380px;
  z-index: 10; }
  .ajaxflowcart-block:after {
    border: 16px solid #edac0a;
    border-width: 0 16px 10px 16px;
    border-color: transparent transparent #edac0a transparent;
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    bottom: 100%;
    left: 50%;
    margin-left: -16px; }
  .ajaxflowcart-block .price {
    color: #edac0a;
    font-size: 17px; }
  .ajaxflowcart-block .currency {
    color: #333;
    font-size: 11px;
    display: inline-block;
    vertical-align: middle; }
  .ajaxflowcart-block .ajaxflowcart_kill {
    cursor: pointer;
    padding: 0;
    width: 33px; }
    .ajaxflowcart-block .ajaxflowcart_kill > span {
      display: block;
      color: #2a82c4;
      cursor: pointer;
      font-family: Arial;
      font-size: 14px;
      line-height: 2.3;
      width: 34px;
      height: 34px;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0.15); }
  .ajaxflowcart-block .ajaxflowcart_photo-img {
    display: block; }
  .ajaxflowcart-block .ajaxflowcart_photo {
    width: 55px; }
    .ajaxflowcart-block .ajaxflowcart_photo > a {
      display: block; }
  .ajaxflowcart-block .ajaxflowcart_title {
    text-align: left;
    width: 138px; }
    .ajaxflowcart-block .ajaxflowcart_title > a {
      font-size: 13px; }
    .ajaxflowcart-block .ajaxflowcart_title .prod-params {
      display: block;
      font-size: 13px;
      line-height: 1.333; }
  .ajaxflowcart-block .ajaxflowcart_count {
    color: #333;
    width: 43px; }
    .ajaxflowcart-block .ajaxflowcart_count > span {
      font-size: 13px; }
  .ajaxflowcart-block .ajaxflowcart_price {
    width: 101px; }
  .ajaxflowcart-block .ajaxflowcart_summ {
    color: #827c72;
    font-size: 14px; }
  @media only screen and (max-width: 380px) {
    .ajaxflowcart-block {
      width: 100%; }
      .ajaxflowcart-block .currency {
        color: #333;
        font-size: 11px;
        width: 6px;
        overflow: hidden; }
      .ajaxflowcart-block .ajaxflowcart_price {
        padding: 5px 0.5em 5px 5px;
        width: 67px; }
      .ajaxflowcart-block .price {
        font-size: 14px; }
      .ajaxflowcart-block .ajaxflowcart_title {
        width: auto; }
      .ajaxflowcart-block .ajaxflowcart_count {
        width: 39px; }
        .ajaxflowcart-block .ajaxflowcart_count > span {
          font-size: 10px; } }

.tablet .ajaxflowcart-block,
.mobile .ajaxflowcart-block {
  box-shadow: -6px 8px 32px 0 rgba(0, 0, 0, 0.2);
  position: fixed;
  overflow-x: hidden;
  overflow-y: scroll;
  right: 0;
  top: 44px;
  height: calc(100vh - 44px);
  transform: translate3d(100%, 0, 0);
  transition: all 0.3s ease;
  z-index: 1002;
  -webkit-overflow-scrolling: touch; }

.ajaxflowcart-table {
  table-layout: fixed;
  width: 100%; }
  .ajaxflowcart-table td {
    padding: 5px;
    border-top: 1px solid #eee; }
  .ajaxflowcart-table tr:first-child > td {
    border-top: none; }

.mobile .ajaxflowcart-table,
.tablet .ajaxflowcart-table {
  margin: 0 0 4em; }

/* Раскладка */
.product .product_left_block {
  float: left;
  position: relative;
  margin: 0 2em 0 0;
  width: calc(50% - 1em); }

.product .product_mid_block {
  float: right;
  width: calc(50% - 1em); }

@media only screen and (max-width: 768px) {
  .product .product_left_block {
    margin: 0 1em 0 0;
    width: calc(50% - 0.5em); }
  .product .product_mid_block {
    width: calc(50% - 0.5em); } }

@media only screen and (max-width: 480px) {
  .product .product_left_block {
    margin: 0 0 1em;
    width: 100%; }
  .product .product_mid_block {
    width: 100%; } }

/* Оформление */
.product {
  margin: 2em 0; }
  .product .product_top {
    margin: 0 0 3em; }
  .product .product-header {
    position: relative; }
  .product .prod_title {
    display: inline-block;
    color: #2a82c4;
    font-size: 26px;
    font-weight: normal;
    margin: 0;
    padding: 0;
    text-transform: uppercase; }
  .product .product_photo > a {
    display: block;
    border: 1px solid #aaa;
    outline: none; }
    .product .product_photo > a > img {
      display: block;
      height: auto;
      max-width: 100%;
      width: 100%; }
  .product.--hidden {
    display: none; }
  .product .compare {
    text-align: left; }
  .product .compare_box {
    margin: 0 4px 0 0;
    padding: 0; }
  .product .short_description {
    margin: 0.5em 0; }
    .product .short_description * {
      font-size: 13px;
      padding: 0; }
  .product .characters-table__row {
    color: #494949;
    font-size: 14px; }
    .product .characters-table__row > b {
      font-weight: normal; }
  .product .characters-table .characters-table__row {
    color: #494949;
    line-height: 1.75;
    position: relative;
    z-index: 1; }
    .product .characters-table .characters-table__row a {
      color: #2a82c4; }
      .product .characters-table .characters-table__row a:hover {
        color: #edac0a; }
    .product .characters-table .characters-table__row > b {
      float: left;
      padding: 0 1em 0 0; }
      .product .characters-table .characters-table__row > b:before {
        border-bottom: 1px dashed #aaa;
        content: '';
        display: block;
        position: absolute;
        height: 1px;
        top: 62%;
        left: 0;
        right: 0;
        z-index: -1; }
    .product .characters-table .characters-table__row > span {
      float: right;
      padding: 0 0 0 1em; }
    .product .characters-table .characters-table__row > span,
    .product .characters-table .characters-table__row > b {
      background: #fff;
      line-height: 1.75;
      z-index: 10; }
    .product .characters-table .characters-table__row > b {
      font-weight: normal; }
  .product .prod_dop_option > b {
    font-weight: normal; }
  .product .prod_dop_option.rating_block {
    color: #666; }
    .product .prod_dop_option.rating_block .rate_results {
      color: #494949;
      font-size: 13px;
      float: right;
      padding: 5px 0 5px 10px; }
    .product .prod_dop_option.rating_block > b {
      color: #494949;
      font-weight: normal;
      line-height: 1.75;
      position: relative;
      z-index: 10; }
  .product .product-prod_prices {
    margin: 0.5em 0 0; }
  .product .prod_big_price {
    display: inline-block;
    margin: 0 1em 0 0; }
    .product .prod_big_price > span {
      font-size: 24px;
      font-weight: bold; }
  .product .prod_price_old {
    display: inline-block; }
    .product .prod_price_old > span {
      color: #666;
      font-size: 14px;
      text-decoration: line-through; }
  @media only screen and (max-width: 768px) {
    .product {
      padding: 1em 0 0; } }

.kolvo_buy_button_add {
  margin: 1em 0 0; }
  .kolvo_buy_button_add:before {
    content: "";
    color: #2a82c4;
    font-family: "FontAwesome";
    font-size: 18px;
    position: absolute;
    margin: -11px 0 0 -10px;
    top: 50%;
    left: 50%;
    font-size: 21px;
    z-index: 2; }
  .kolvo_buy_button_add .kolvo {
    float: left;
    margin: 0 1em 0 0;
    padding: 3px 0;
    width: 92px;
    user-select: none;
    border: 1px solid #edac0a;
    background: #fff; }
    .kolvo_buy_button_add .kolvo .input-text {
      display: inline-block;
      padding: 3px 0.25em 3px;
      text-align: center;
      vertical-align: middle;
      width: 34px; }
  .kolvo_buy_button_add .btn_add {
    float: left; }
  .kolvo_buy_button_add .kolvo_arrow {
    cursor: pointer;
    display: inline-block;
    text-align: center;
    height: 24px;
    width: 24px; }
    .kolvo_buy_button_add .kolvo_arrow:hover {
      background: #fff;
      transition: background 0.3s ease; }

.fav-block .catalog-item {
  float: left;
  margin: 0 2em 2em 0;
  width: calc(33.333% - 1.34em); }
  .fav-block .catalog-item:nth-child(3n + 3) {
    margin: 0 0 2em; }

.fav-link {
  display: inline-block;
  overflow: hidden;
  position: relative;
  height: 32px;
  width: 32px;
  text-indent: -9999px;
  vertical-align: text-bottom; }
  .fav-link:before {
    content: "";
    color: #2a82c4;
    font-family: "FontAwesome";
    font-size: 18px;
    position: absolute;
    margin: -11px 0 0 -10px;
    top: 50%;
    left: 50%;
    color: #494949;
    text-indent: 0;
    z-index: 2; }
  .fav-link.added:before {
    color: #d91c6b; }
  .fav-link:hover:before {
    color: #d91c6b;
    transition: color 0.2s ease; }

/* Ярлыки новинки/хит/скидка */
.product-labels {
  position: absolute;
  right: 0; }
  .product-labels > span {
    color: #fff;
    cursor: default;
    display: block;
    font-size: 15px;
    margin: 0 0 4px;
    padding: 2px 6px;
    position: relative;
    height: 26px;
    text-align: center;
    user-select: none; }
    .product-labels > span:hover {
      cursor: pointer; }
    .product-labels > span:before {
      content: '';
      width: 0;
      height: 0;
      border-top: 13px solid transparent;
      border-right: 11px solid transparent;
      border-bottom: 13px solid transparent;
      position: absolute;
      top: 0;
      left: -11px;
      bottom: 0; }
  .product-labels .label-new {
    background: #e54239; }
    .product-labels .label-new:before {
      border-right-color: #e54239; }
  .product-labels .label-spec {
    background: #f19000; }
    .product-labels .label-spec:before {
      border-right-color: #f19000; }
  .product-labels .label-hit {
    background: #ffcd1f;
    color: #947711; }
    .product-labels .label-hit:before {
      border-right-color: #ffcd1f; }

.product_dop_photo_slider .dop-photo__thumb {
  border-radius: 0px;
  border: 1px solid #aaa;
  cursor: pointer;
  overflow: hidden; }
  .product_dop_photo_slider .dop-photo__thumb > a {
    display: block; }
  .product_dop_photo_slider .dop-photo__thumb > img {
    display: block;
    width: 100%; }

.product_dop_photo_slider > .owl-carousel {
  padding: 0 1.5em;
  margin: 1em 0 0; }
  .product_dop_photo_slider > .owl-carousel > .owl-stage-outer {
    padding: 1px 0; }
  .product_dop_photo_slider > .owl-carousel > .owl-nav {
    perspective-origin: center; }
    .product_dop_photo_slider > .owl-carousel > .owl-nav.disabled {
      display: block; }
    .product_dop_photo_slider > .owl-carousel > .owl-nav .owl-prev,
    .product_dop_photo_slider > .owl-carousel > .owl-nav .owl-next {
      color: #fff;
      font-size: 3em;
      height: 46px;
      opacity: 1;
      padding: 0;
      position: absolute;
      width: 15px;
      top: 0; }
      .product_dop_photo_slider > .owl-carousel > .owl-nav .owl-prev:hover,
      .product_dop_photo_slider > .owl-carousel > .owl-nav .owl-next:hover {
        opacity: 0.5;
        transition: opacity 0.2s ease; }
    .product_dop_photo_slider > .owl-carousel > .owl-nav .owl-prev {
      left: 0; }
    .product_dop_photo_slider > .owl-carousel > .owl-nav .owl-next {
      right: 0; }

.product_dop_modes {
  position: relative;
  font-size: 14px;
  margin: 1em 0 2em; }
  .product_dop_modes .product_dop_modes_navigation {
    padding: 0;
    position: relative;
    height: 34px;
    z-index: 12; }
    .product_dop_modes .product_dop_modes_navigation > li {
      position: relative;
      display: block;
      float: left;
      font-size: 17px;
      color: #494949;
      font-style: normal;
      height: 35px;
      line-height: 34px;
      padding: 0 20px;
      margin-right: 5px;
      cursor: pointer; }
      .product_dop_modes .product_dop_modes_navigation > li:hover {
        background: #edac0a;
        transition: background 0.3s ease; }
      .product_dop_modes .product_dop_modes_navigation > li.mod_selected {
        cursor: default;
        color: #fff;
        background: #2a82c4; }
  .product_dop_modes .product_dop_modes_content {
    background: #fff;
    border: 1px solid #aaa;
    position: relative;
    z-index: 10;
    padding: 1em;
    text-align: justify;
    margin: 0 0 1em; }
    .product_dop_modes .product_dop_modes_content li,
    .product_dop_modes .product_dop_modes_content p {
      color: #382507;
      font-size: 14px; }
    .product_dop_modes .product_dop_modes_content a {
      font-size: 14px; }
    .product_dop_modes .product_dop_modes_content .product_dop_title {
      font-size: 16px;
      font-weight: bold;
      padding-bottom: 1em; }

/* Отзывы продукта */
.product-comments-form {
  float: left;
  max-width: 300px;
  width: 100%; }
  .product-comments-form .form_title {
    position: static; }
  .product-comments-form .form_block {
    margin: 0 0 1em; }
  .product-comments-form .input-textarea,
  .product-comments-form .input-text {
    display: block;
    padding: 3px 0.5em 3px;
    width: 100%; }
  .product-comments-form .input-textarea {
    resize: none;
    height: 7em; }

.fast_order_form--bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9990;
  display: none; }

.prod-bnts {
  float: right;
  width: 40%; }

.prod_selector {
  float: left;
  margin: 0 0 1.5em;
  width: 60%; }
  .prod_selector .prod_selector_wrapper {
    display: inline-block;
    position: relative; }
    .prod_selector .prod_selector_wrapper:after {
      background: #a8a8a8;
      content: '';
      height: 6px;
      width: 1px;
      top: 50%;
      margin-top: -3px;
      position: absolute;
      right: calc(1em + 3px);
      transform: rotate(-40deg); }
    .prod_selector .prod_selector_wrapper:before {
      background: #a8a8a8;
      content: '';
      height: 6px;
      width: 1px;
      top: 50%;
      margin-top: -3px;
      position: absolute;
      right: 1em;
      transform: rotate(40deg); }
  .prod_selector .select_size {
    border: 1px solid #a8a8a8;
    border-radius: 0;
    color: #a8a8a8;
    cursor: pointer;
    min-width: 228px;
    outline: none;
    padding: 3px 1em;
    height: 33px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none !important; }
  .prod_selector .prod_selector_title {
    display: inline-block;
    color: #696969;
    font: normal 15px/1 "Arial", "Helvetica", sans-serif;
    margin: 0 5% 0 0; }

select::-ms-expand {
  display: none; }

.prod_selector_title {
  color: #666;
  font-style: normal;
  font-size: 17px; }

.prod-color-wrapper {
  display: inline-block;
  margin: 0.5em 0 0.5em; }
  .prod-color-wrapper .prod-color-title {
    display: block;
    font-size: 12px;
    font-style: normal;
    text-align: center;
    text-transform: lowercase; }

.responses_content .message_box {
  float: right;
  width: calc(100% - 300px);
  padding: 0 0 1em 1em;
  margin: 0 0 2em; }

.responses_content .message_top {
  border-bottom: 1px solid #aaa;
  display: table;
  width: 100%; }

.responses_content .message_text {
  font-size: 13px;
  margin: 0.5em 0 0; }

.responses_content .message_date,
.responses_content .message_name {
  display: table-cell; }

.responses_content .message_name {
  vertical-align: middle; }
  .responses_content .message_name > span {
    font-size: 16px;
    font-weight: bold;
    vertical-align: middle; }

.responses_content .message_date {
  float: right;
  text-align: center; }
  .responses_content .message_date > span {
    font-size: 12px;
    color: #a8a8a8;
    padding: 1px 3px 2px; }
    .responses_content .message_date > span.dt {
      background: #2a82c4;
      color: #fff;
      display: block; }

@media only screen and (max-width: 768px) {
  .responses_content .message_box {
    float: none;
    width: 100%; } }

/* Раскладки каталога */
.catalog-main__item.set-2 {
  float: left;
  margin: 0 14px 14px 0;
  width: calc(50% - 7px); }
  .catalog-main__item.set-2:nth-child(2) {
    margin: 0 0 14px; }

/* 3 */
.catalog-main__wrappa.set-3 {
  float: left;
  margin: 0 14px 14px 0;
  width: calc(50% - 7px); }
  .catalog-main__wrappa.set-3:nth-child(2) {
    margin: 0 0 14px; }
  .catalog-main__wrappa.set-3.col-2 {
    padding: 0; }
    .catalog-main__wrappa.set-3.col-2 > .catalog-main__item:first-child {
      margin: 0 0 14px; }

/* 4 */
.catalog-main__wrappa.set-4 {
  float: left;
  margin: 0 14px 14px 0;
  width: calc(30% - 9px); }
  .catalog-main__wrappa.set-4.col-1 {
    padding: 0; }
    .catalog-main__wrappa.set-4.col-1 .catalog-main__title {
      top: 44%; }
  .catalog-main__wrappa.set-4.col-2 {
    padding: 0;
    width: calc(40% - 9px); }
    .catalog-main__wrappa.set-4.col-2 .catalog-main__title {
      top: 44%; }
      .catalog-main__wrappa.set-4.col-2 .catalog-main__title > h2 {
        font-size: 26px; }
    .catalog-main__wrappa.set-4.col-2 > .catalog-main__item:first-child {
      margin: 0 0 14px; }

.catalog-main__item.set-4 {
  float: left;
  margin: 0 0 1em;
  width: calc(30% - 10px); }

/* 5 */
.catalog-main__wrappa.set-5 {
  float: left;
  margin: 0 14px 14px 0;
  width: calc(50% - 7px); }
  .catalog-main__wrappa.set-5.col-1 .catalog-main__item:first-child {
    margin: 0 0 14px; }
  .catalog-main__wrappa.set-5.col-3 {
    padding: 0;
    margin: 0 0 14px; }
    .catalog-main__wrappa.set-5.col-3 > .catalog-main__item {
      margin: 0 0 14px; }
  .catalog-main__wrappa.set-5 > .row .catalog-main__item {
    margin: 0 14px 14px 0;
    width: calc(50% - 7px); }
    .catalog-main__wrappa.set-5 > .row .catalog-main__item.set-5 {
      margin: 0 0 14px; }

/* 6 */
/* 7 */
/* 8 */
/* Конец раскладки каталога */
/* Временно */
.catalog-main .catalog-main__item.set-6-more {
  float: left;
  margin: 0 2em 2em 0;
  width: calc(33.333% - 1.34em); }
  .catalog-main .catalog-main__item.set-6-more:nth-child(3n + 3) {
    margin: 0 0 2em; }

/* */
.catalog-main__item {
  cursor: pointer;
  overflow: hidden;
  position: relative;
  z-index: 1; }
  .catalog-main__item:hover > .back {
    background: rgba(0, 0, 0, 0.05);
    transition: background 0.3s ease; }
  .catalog-main__item:hover .catalog-main__image {
    transform: scale(1.1);
    transition: transform 0.3s ease-out; }
  .catalog-main__item:hover .catalog-main__title > h2 {
    color: #222;
    transition: color 0.3s ease; }
  .catalog-main__item:hover .catalog-main__title > p {
    color: #222;
    transition: color 0.3s ease; }
  .catalog-main__item > a {
    display: block; }
  .catalog-main__item > .back {
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    pointer-events: none;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2; }

.catalog-main__image {
  display: block;
  height: auto;
  max-width: 100%;
  transform-origin: center;
  transform: scale(1);
  width: 100%;
  will-change: transform; }

.catalog-main__title {
  position: absolute;
  top: 50%;
  left: 2em;
  margin: -50px 0 0 0;
  pointer-events: none;
  z-index: 5; }
  .catalog-main__title > h2 {
    color: #fff;
    font-size: 36px;
    font-weight: bold;
    line-height: 1;
    padding: 0 0 10px;
    text-transform: uppercase; }
  .catalog-main__title > p {
    color: #ebebeb;
    font-size: 12px;
    line-height: 1.2; }

/* Стили главного каталога */
.catalog-main {
  margin: 2em 0 0; }

/* Стили карточки в каталоге */
.catalog-item {
  background: #fff;
  border: 1px solid #aaa;
  padding: 0.25em 1em 5em 1em;
  position: relative; }
  .catalog-item .fav-link {
    position: absolute;
    top: 0;
    right: 0; }
    .catalog-item .fav-link:before {
      color: #666;
      font-size: 15px;
      margin: -11px 0 0 -7px; }
    .catalog-item .fav-link:hover:before {
      color: #d9017f; }
  .catalog-item .product_photo > .show_product {
    display: block; }
    .catalog-item .product_photo > .show_product > img {
      display: block;
      height: auto;
      max-width: 400px;
      width: 100%; }
  .catalog-item .catalog-item__title {
    padding: 0.5em 0;
    text-align: center; }
    .catalog-item .catalog-item__title > .show_product {
      display: block;
      font: bold 21px/1.333 "Roboto";
      overflow: hidden;
      text-overflow: ellipsis;
      text-transform: uppercase;
      text-decoration: none;
      white-space: nowrap; }
  .catalog-item .short_description {
    text-align: center; }
    .catalog-item .short_description * {
      color: #8f8f8f;
      font-size: 16px;
      line-height: 1.2; }
  .catalog-item .catalog-item__bottom {
    position: absolute;
    left: 1em;
    right: 1em;
    bottom: 1em; }
  .catalog-item .catalog-item__price-block {
    float: left; }
  .catalog-item .prod_price_old {
    line-height: 1; }
    .catalog-item .prod_price_old > span {
      color: #8f8f8f;
      font-size: 17px;
      text-decoration: line-through; }
  .catalog-item .prod_big_price {
    line-height: 1.333; }
    .catalog-item .prod_big_price > span {
      color: #000;
      font-size: 24px; }
      .catalog-item .prod_big_price > span.currency {
        font-size: 19px; }
  .catalog-item .kolvo {
    background: #fff;
    float: none;
    margin: 0;
    padding: 3px 0;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    text-align: center;
    visibility: hidden;
    width: auto;
    z-index: 1; }
  .catalog-item .kolvo_buy_button_add {
    float: right;
    position: relative; }
    .catalog-item .kolvo_buy_button_add > .btn_add {
      display: inline-block;
      position: relative;
      z-index: 2; }
  @media only screen and (max-width: 1200px) {
    .catalog-item .catalog-item__title > .show_product {
      font-size: 18px; }
    .catalog-item .short_description * {
      font-size: 14px; } }

.desktop .catalog-item .kolvo {
  transition: all 0.3s ease;
  transform: translate3d(0, 0, 0); }

.desktop .catalog-item .kolvo_buy_button_add.hover:hover:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 10%;
  height: 100%;
  margin: 0 0 0 -10px; }

.desktop .catalog-item .kolvo_buy_button_add.hover:hover .kolvo {
  transform: translate3d(-110%, 0, 0);
  transition: transform 0.3s ease;
  visibility: visible;
  border: 1px solid #edac0a; }

.flexbox .catalog-item:before, .flexbox .catalog-item:after {
  display: none; }

.flexbox .catalog-item__bottom {
  display: flex;
  justify-content: space-between; }
  .flexbox .catalog-item__bottom .kolvo_buy_button_add {
    float: none;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end; }
  .flexbox .catalog-item__bottom .catalog-item__price-block {
    float: none; }

.catalog-description__top {
  margin: 0 0 2em; }

.catalog-description__bottom {
  margin: 0 0 2em; }

/* Раскладка карточек в каталоге */
.catalog-list {
  margin: 1em 0 0; }
  .catalog-list > .catalog-item {
    cursor: pointer;
    float: left;
    margin: 0 2em 2em 0;
    width: calc(33.333% - 1.34em); }
    .catalog-list > .catalog-item:nth-child(3n + 3) {
      margin: 0 0 2em; }
    @media only screen and (max-width: 992px) {
      .catalog-list > .catalog-item {
        width: calc(50% - 1em); }
        .catalog-list > .catalog-item:nth-child(3n + 3) {
          margin: 0 2em 2em 0; }
        .catalog-list > .catalog-item:nth-child(2n + 2) {
          margin: 0 0 2em; } }
    @media only screen and (max-width: 480px) {
      .catalog-list > .catalog-item {
        margin: 0 0 2em;
        width: 100%; } }

.sort_panel {
  font-style: normal;
  font-size: 16px; }
  .sort_panel > span {
    margin: 0 0.5em 0 0;
    position: relative; }
    .sort_panel > span > a {
      margin: 0 3px 0 0; }
    .sort_panel > span.this_sort {
      font-weight: bold; }
    .sort_panel > span.desc_sort:after {
      content: "\2193"; }
    .sort_panel > span.asc_sort:after {
      content: "\2191"; }
    .sort_panel > span:first-child {
      margin: 0 0.5em; }
  @media only screen and (max-width: 768px) {
    .sort_panel {
      font-size: 14px; } }
  @media only screen and (max-width: 480px) {
    .sort_panel {
      font-size: 12px; } }

/* Список подразделов, плитки по 3 колонки */
.category-list .category-list__item {
  cursor: pointer;
  float: left;
  position: relative;
  margin: 0 2em 2em 0;
  width: calc(33.333% - 1.34em);
  transition: all 0.3s ease;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.15); }
  .category-list .category-list__item > a {
    display: block;
    width: 100%;
    background: #2a82c4;
    color: #fff;
    font-size: 17px;
    text-decoration: none;
    text-transform: uppercase;
    text-align: center; }
    @media only screen and (max-width: 1200px) {
      .category-list .category-list__item > a {
        padding: 1em;
        font-size: 15px; } }
    @media only screen and (max-width: 992px) {
      .category-list .category-list__item > a {
        font-size: 14px; } }
    .category-list .category-list__item > a:hover {
      transition: all 0.3s ease;
      background: #edac0a; }
    .category-list .category-list__item > a .category-list__item-title {
      display: block;
      padding: 10px; }
  .category-list .category-list__item:nth-child(3n + 3) {
    margin: 0 0 2em 0; }
  @media only screen and (max-width: 992px) {
    .category-list .category-list__item {
      width: calc(50% - 1em); }
      .category-list .category-list__item:nth-child(3n + 3) {
        margin: 0 2em 2em 0; }
      .category-list .category-list__item:nth-child(2n + 2) {
        margin: 0 0 2em 0; } }
  @media only screen and (max-width: 768px) {
    .category-list .category-list__item {
      margin: 0 1em 1em 0;
      width: calc(33.333% - 0.666667em); }
      .category-list .category-list__item:nth-child(2n + 2) {
        margin: 0 1em 1em 0; }
      .category-list .category-list__item:nth-child(3n + 3) {
        margin: 0 0 1em 0; } }
  @media only screen and (max-width: 600px) {
    .category-list .category-list__item {
      width: calc(50% - 0.5em); }
      .category-list .category-list__item:nth-child(3n + 3) {
        margin: 0 1em 1em 0; }
      .category-list .category-list__item:nth-child(2n + 2) {
        margin: 0 0 1em 0; } }
  @media only screen and (max-width: 414px) {
    .category-list .category-list__item {
      margin: 0 0 1em;
      float: none;
      width: 100%; }
      .category-list .category-list__item:nth-child(3n + 3) {
        margin: 0 0 1em 0; } }

.category-list.hidden {
  display: none; }

.header-cart.open .ajaxflowcart-block {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition: transform 0.3s ease, opacity 0.15s ease 0.15s;
  visibility: visible; }

.cart {
  display: inline-block;
  text-align: left;
  position: relative;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.15); }
  .cart.empty {
    cursor: default; }
  .cart .header_cart-goods-quantity {
    color: #2a82c4; }
  .cart.full {
    cursor: pointer; }

.desktop .cart.full:hover .cart-title {
  color: #2a82c4;
  transition: color 0.2s ease; }

.desktop .cart.full:hover:before {
  color: #2a82c4;
  transition: color 0.2s ease; }

.cart-table {
  margin: 0 0 1em;
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
  text-align: right;
  vertical-align: middle; }
  .cart-table .cart-table__delete-btn {
    cursor: pointer;
    display: inline-block;
    font-style: normal;
    width: 32px;
    height: 32px;
    border-radius: 0px;
    text-align: center;
    line-height: 28px;
    padding: 0;
    position: relative;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0.15); }
    .cart-table .cart-table__delete-btn:hover:before {
      color: #edac0a;
      transition: color 0.2s ease; }
    .cart-table .cart-table__delete-btn:before {
      content: "";
      color: #2a82c4;
      font-family: "FontAwesome";
      font-size: 18px;
      position: absolute;
      margin: -11px 0 0 -10px;
      top: 50%;
      left: 50%;
      color: #2a82c4;
      font-size: 21px;
      margin: -14px 0 0 -8px;
      z-index: 2; }
  .cart-table .cart_product_photo img {
    height: auto;
    max-width: 100px;
    width: 100%; }
  .cart-table .input-count {
    padding: 5px 9px 5px 1em;
    max-width: 76px;
    width: 100%; }
  .cart-table > thead > tr {
    border-bottom: 1px solid #aaa; }
    .cart-table > thead > tr > th {
      padding: 6px 0; }
      .cart-table > thead > tr > th:nth-child(1) {
        width: 13%; }
      .cart-table > thead > tr > th:nth-child(2) {
        width: 32%; }
      .cart-table > thead > tr > th:nth-child(3) {
        width: 14%; }
      .cart-table > thead > tr > th:nth-child(4) {
        width: 15%; }
      .cart-table > thead > tr > th:nth-child(5) {
        width: 16%; }
      .cart-table > thead > tr > th:nth-child(6) {
        width: 10%; }
  .cart-table > tbody > tr {
    border-bottom: 1px solid #aaa; }
    .cart-table > tbody > tr > td {
      padding: 6px 5px; }

.cart-table__skidki {
  width: 100%; }

.cart-table__deliver {
  margin: 0 0 1em;
  width: 100%; }
  .cart-table__deliver .dostavka_radio > label {
    padding: 0;
    height: 24px;
    width: 24px;
    display: block; }
  .cart-table__deliver .radio:not(checked) + label:before {
    background: #fff;
    left: 3px; }
  .cart-table__deliver .radio:not(checked) + label:after {
    left: 6px; }
  .cart-table__deliver .dostavka_header {
    vertical-align: top; }
  .cart-table__deliver tr {
    border-bottom: 1px solid #aaa; }
  .cart-table__deliver td {
    padding: 3px 0; }
  .cart-table__deliver tfoot > tr {
    border-bottom: none; }

.order_ok_table {
  table-layout: fixed;
  text-align: left;
  width: 100%; }
  .order_ok_table th,
  .order_ok_table td {
    padding: 6px 0; }
  .order_ok_table tr {
    border-bottom: 1px solid #aaa; }
    .order_ok_table tr:last-child {
      border-bottom: none; }

.filter_block_wrapper .filter_block {
  float: left;
  min-height: 93px;
  padding: 1em 0;
  width: 50%; }

.filter_block_wrapper .blockReset {
  color: #2a82c4;
  cursor: pointer;
  float: right;
  font-size: 12px;
  text-decoration: underline; }
  .filter_block_wrapper .blockReset:hover {
    color: #edac0a;
    text-decoration: underline;
    transition: color 0.2s ease; }

.filter_block_wrapper .filter_top {
  margin: 0 0 0.5em; }
  .filter_block_wrapper .filter_top > span {
    color: #494949;
    display: block;
    font-size: 17px; }

.filter_block_wrapper .filter_mid .filter-input-wrapper {
  display: inline-block;
  margin: 0 0.5em 0 0; }
  .filter_block_wrapper .filter_mid .filter-input-wrapper label {
    background: #edac0a;
    color: #fff;
    cursor: pointer;
    font-size: 12px;
    display: block;
    padding: 0 7px 2px; }
  .filter_block_wrapper .filter_mid .filter-input-wrapper input[type="checkbox"] {
    display: none; }
    .filter_block_wrapper .filter_mid .filter-input-wrapper input[type="checkbox"]:checked + label {
      background: #2a82c4; }

.filter_block_wrapper .filter_mid .input-wrapper.check {
  display: inline-block;
  margin: 0 0.5em 0 0; }

.filters-left .filter_block_wrapper .filter_block {
  float: none;
  width: 100%; }

.filter_range {
  display: table;
  width: 100%; }
  .filter_range .input-wrapper {
    float: left;
    width: calc(50% - 0.5em); }
    .filter_range .input-wrapper:first-child {
      margin: 0 1em 0 0; }
    .filter_range .input-wrapper > span,
    .filter_range .input-wrapper .input-text {
      display: inline-block; }
    .filter_range .input-wrapper > span {
      font-size: 12px;
      width: 20px; }
    .filter_range .input-wrapper .input-text {
      width: calc(100% - 25px); }
  .filter_range > .col-6 {
    padding: 0 0.5em 0 0; }
    .filter_range > .col-6:nth-child(2) {
      padding: 0 0 0 0.5em; }
    .filter_range > .col-6 > span {
      display: inline-block;
      font-size: 13px; }
  .filter_range .filter-input-wrapper {
    float: right;
    width: calc(100% - 35px); }
  .filter_range input.pole {
    width: 100%; }

.filters-main {
  background: #fff; }

.module-wrapper.filters-main .filter_block {
  padding: 1em 1em;
  min-height: 124px; }
  .module-wrapper.filters-main .filter_block:nth-child(1n + 2) {
    padding: 1em 1em 1em 0; }

.poiskat {
  padding: 5px;
  border-top: 1px solid #aaa;
  text-align: center; }

.range_slider {
  display: block;
  padding: 10px 10px;
  height: 8px; }

.ui-slider {
  position: relative;
  height: 6px;
  border: 1px solid #777;
  background: #fff;
  margin: 0; }

.ui-slider-range {
  position: absolute;
  height: 6px;
  border: 1px solid #777;
  top: -1px;
  background: white;
  background: linear-gradient(to right, white 0%, #d8d8d8 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#d8d8d8',GradientType=1 );
  /* IE6-9 */ }

.ui-slider-handle {
  position: absolute;
  width: 8px;
  height: 8px;
  top: -2px;
  margin-left: -5px;
  border: 1px solid #000;
  border-radius: 2px;
  background: #d8d8d8;
  background: linear-gradient(to bottom, #d8d8d8 0%, white 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d8d8d8', endColorstr='#ffffff',GradientType=0 );
  /* IE6-9 */ }

.compareCategories {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  padding: 0 0 2em; }
  .compareCategories .trashAll {
    margin: 0 5px;
    padding: 4px 10px;
    cursor: pointer;
    background: #fff;
    outline: 1px solid #aaa; }

.comparison,
.comparisonz {
  /* display: flex;*/ }

.comparisonz {
  padding: 0 0 5px;
  cursor: default; }

.comparison .owl-stage-outer .owl-stage .owl-item {
  display: inline-block;
  vertical-align: top; }

.comparison.owl-carousel .owl-nav .owl-prev,
.comparison.owl-carousel .owl-nav .owl-next {
  background: #e2e2e2;
  border-radius: 50%;
  color: #fff;
  line-height: 0.96em;
  opacity: 1;
  height: 1em;
  width: 1em;
  text-align: center; }

.comparison.owl-carousel .owl-nav .owl-prev:hover,
.comparison.owl-carousel .owl-nav .owl-next:hover {
  color: #aaa; }

.comparison.owl-carousel .owl-nav .owl-next {
  left: auto;
  right: -0.5em; }

.comparison.owl-carousel .owl-nav .owl-prev {
  left: -0.5em;
  right: auto; }

.comparison.owl-carousel .owl-nav {
  top: 50%; }

.comparison .container {
  background: #fff;
  border: 1px solid #aaa;
  text-align: center; }

.comparison .comparisonFullItem .image-link > img {
  width: 100%; }

.comparison .comparisonFullItem:hover .removeItem {
  opacity: 1;
  transition: opacity 0.2s ease; }

.comparison .btn_add {
  position: absolute;
  bottom: 1em;
  left: 0;
  right: 0; }

.comparison .general {
  padding: 10px 0 5em;
  margin: 0 0 2px;
  position: relative; }
  .comparison .general > img {
    width: 100%; }
  .comparison .general .numbered {
    width: 30px;
    position: absolute;
    background: #2a82c4;
    color: #fff;
    border-radius: 0 0 26px;
    top: 0;
    left: 0;
    padding: 0 5px 5px 1px; }
  .comparison .general .image-link {
    display: block; }
  .comparison .general .removeItem {
    padding: 10px 0;
    cursor: pointer;
    position: absolute;
    top: 0;
    opacity: 0;
    right: 0;
    height: 28px;
    width: 28px;
    text-indent: -9999px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0.15); }
    .comparison .general .removeItem:before {
      content: "";
      color: #2a82c4;
      font-family: "FontAwesome";
      font-size: 18px;
      position: absolute;
      margin: -11px 0 0 -10px;
      top: 50%;
      left: 50%;
      color: #2a82c4;
      top: 50%;
      margin: -14px 0 0 -6px;
      text-indent: 0;
      z-index: 2; }
    .comparison .general .removeItem:hover:before {
      color: #edac0a;
      transition: color 0.2s ease; }

.comparison .container > div .linkTitle {
  text-transform: uppercase;
  padding: 0 0 5px; }

.comparison .container > div .head {
  padding: 10px 0;
  text-transform: uppercase;
  font-weight: bold;
  box-shadow: 0 0 4px -1px #464646;
  background: #f7f7f7;
  font-size: 12px;
  cursor: pointer; }

.comparison .container > div .priceOld {
  text-decoration: line-through;
  color: #aaa; }

.comparison .container > div .generalParam:nth-child(odd) {
  background: #ccc; }

.comparison .container > div .generalParam .inners {
  padding: 5px 0; }

.comparison .container > div .generalParam .inners b {
  font-size: 20px;
  line-height: 14px; }

.comparison .container > div .generalParam .param_tsvet {
  display: flex;
  flex-flow: row wrap;
  justify-content: center; }

.comparison .container > div .generalParam .param_tsvet .colorPunkt {
  width: 30px;
  height: 30px;
  margin: 0 4px;
  border-radius: 15px; }

.compare_box {
  display: inline-block;
  padding: 0 5px; }

.compare_box input[type=checkbox] {
  cursor: pointer;
  display: inline-block;
  vertical-align: middle; }

.compare_text {
  cursor: pointer;
  display: inline-block;
  font-size: 12px; }
  .compare_text.in_compare {
    color: #2a82c4;
    text-decoration: underline; }
    .compare_text.in_compare:hover {
      color: #edac0a;
      transition: color 0.2s ease;
      text-decoration: underline; }

.owl-placeholder {
  height: 100px;
  float: left; }

.order-form {
  max-width: 300px;
  width: 100%; }
  .order-form .form_block {
    margin: 1em 0 0;
    position: relative; }
    .order-form .form_block > .form_title {
      position: static; }
  .order-form .picker-input {
    cursor: pointer; }
  .order-form .form_title {
    position: static; }
  .order-form .input-textarea,
  .order-form .input-text {
    width: 100%; }
  .order-form .input-textarea {
    height: 7em; }
  .order-form .required-message {
    font-size: 11px;
    margin: -1em 0 1em; }

.user-orders {
  margin: 0 0 1em; }

.user-orders-deliver-table {
  border-bottom: 1px solid #aaa;
  margin: 0 0 1em;
  text-align: left;
  table-layout: fixed;
  width: 100%; }
  .user-orders-deliver-table > tbody > tr {
    border-bottom: 1px solid #aaa; }

.user-order > .total-table,
.user-order > .summ-table {
  table-layout: fixed;
  width: 275px; }
  .user-order > .total-table td:first-child,
  .user-order > .summ-table td:first-child {
    width: 200px; }
  .user-order > .total-table td:last-child,
  .user-order > .summ-table td:last-child {
    width: 75px; }
  .user-order > .total-table .cart_prices,
  .user-order > .summ-table .cart_prices {
    text-align: right; }

.user-order > .total-table {
  margin: 0 0 1em; }

.order-table {
  margin: 1em 0;
  text-align: center;
  table-layout: fixed;
  width: 100%; }
  .order-table td {
    padding: 6px 0; }
  .order-table > thead > tr {
    border-bottom: 1px solid #aaa; }
  .order-table > tbody > tr {
    border-bottom: 1px solid #aaa; }

.users-orders-table {
  margin: 1em 0;
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
  text-align: left;
  vertical-align: middle; }
  .users-orders-table .cart-table__delete-btn {
    cursor: pointer;
    display: inline-block;
    font-style: normal;
    width: 28px;
    height: 28px;
    color: #fff;
    border-radius: 50%;
    text-align: center;
    line-height: 28px;
    padding: 0;
    position: relative; }
  .users-orders-table .cart_product_photo img {
    height: auto;
    max-width: 100px;
    width: 100%; }
  .users-orders-table .input-count {
    padding: 5px 9px 5px 1em;
    max-width: 76px;
    width: 100%; }
  .users-orders-table > thead > tr {
    border-bottom: 1px solid #aaa; }
    .users-orders-table > thead > tr > th {
      padding: 6px 0; }
      .users-orders-table > thead > tr > th:nth-child(1) {
        width: 15%; }
      .users-orders-table > thead > tr > th:nth-child(2) {
        width: 33%; }
      .users-orders-table > thead > tr > th:nth-child(3) {
        width: 13%; }
      .users-orders-table > thead > tr > th:nth-child(4) {
        width: 16%; }
      .users-orders-table > thead > tr > th:nth-child(5) {
        width: 10%; }
      .users-orders-table > thead > tr > th:nth-child(6) {
        width: 13%; }
  .users-orders-table > tbody > tr {
    border-bottom: 1px solid #aaa; }
    .users-orders-table > tbody > tr > td {
      padding: 8px 0; }

.rating_block {
  position: relative; }

.rating_block > div {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 0 5px; }

.rating_star {
  display: inline-block;
  height: 29px;
  width: 29px;
  vertical-align: middle;
  text-indent: -9999px;
  overflow: hidden;
  position: relative;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.15); }
  .rating_star:before {
    content: '\f005';
    font: normal 25px/1 "FontAwesome";
    color: #494949;
    position: absolute;
    top: 3px;
    left: 3px;
    text-indent: 0;
    transition: color 0.2s ease; }

label.rating_star {
  cursor: pointer; }

.rating_star.half:before {
  content: '\f123';
  color: #edac0a;
  transition: color 0.2s ease;
  z-index: 2; }

.rating_star.half:after {
  content: '\f005';
  font: normal 25px/1 "FontAwesome";
  color: #494949;
  position: absolute;
  top: 3px;
  left: 3px;
  z-index: 1; }

.rating_star_full:before {
  content: '\f005';
  color: #edac0a;
  transition: color 0.2s ease;
  z-index: 2; }

.rating_link {
  border-bottom-width: 1px;
  border-bottom-style: dashed;
  cursor: pointer;
  text-decoration: none; }

.flow_rating {
  position: absolute;
  top: -40px;
  left: 50%;
  margin-left: -78px;
  width: 140px;
  padding: 5px;
  text-align: center; }

.flow_rating > div {
  display: inline-block;
  line-height: 25px;
  margin: 0 !important; }

.rate_results {
  color: #494949;
  padding: 0 1em;
  position: relative;
  min-width: 215px;
  overflow: hidden; }

.rate_results:before {
  background: #fff;
  content: 'Ваш голос учтён!';
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  line-height: 24px;
  text-align: center;
  opacity: 0;
  transition: 0.2s ease-in-out; }

.rate_results._get:before {
  top: 0;
  opacity: 1; }

.brands-description__top,
.brands-description__bottom {
  margin: 0 0 2em; }

.brand-list {
  margin: 0 0 2em; }

.brand-list__item {
  float: left;
  margin: 0 1em 2em 0;
  width: calc(20% - 0.8em); }
  .brand-list__item:hover .brand-list__item-title > a {
    color: #edac0a; }
  .brand-list__item > a {
    display: block; }
  .brand-list__item:nth-child(5n + 5) {
    margin: 0 0 2em; }

.brand-list__item-image {
  display: block;
  max-width: 200px;
  width: 100%; }

.brand-list__item-title {
  margin: 0.5em 0 0;
  text-align: center; }
  .brand-list__item-title > a {
    font-size: 13px; }
  @media only screen and (max-width: 992px) {
    .brand-list__item-title > a {
      font-size: 12px; } }

.popup-block__fon {
  background: rgba(0, 0, 0, 0.5);
  display: none;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1000;
  position: fixed; }

.popup-block {
  background: #fff;
  box-shadow: 7px 10px 60px 0 #383838;
  border-radius: 0px;
  display: none;
  font-size: 15px;
  width: 410px;
  margin: -100px 0 0 -205px;
  text-align: center;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 1010; }
  .popup-block .popup-block__content {
    margin: 1em 0 0;
    text-align: center; }
  .popup-block .input-text {
    width: 100%; }
  .popup-block .input-textarea {
    height: 8em; }
  .popup-block .popup-block__inner {
    padding: 0.5em 1em 1em;
    position: relative; }
  .popup-block .form_block.form_block__last {
    margin: 0; }
    .popup-block .form_block.form_block__last .fb_title {
      font-size: 11px; }
  .popup-block .popup-block__title {
    color: #444;
    font: bold 20px/1.2 "Roboto";
    text-transform: uppercase; }
  @media only screen and (max-width: 480px) {
    .popup-block .popup-block__content {
      margin: 0.5em 0 0; }
    .popup-block .popup-block__title {
      font-size: 14px; }
    .popup-block .form_block.check-agree > label {
      font-size: 9px;
      line-height: 1.4; } }

.popup-block__cross {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  height: 36px;
  width: 40px;
  will-change: transform;
  z-index: 2;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.15); }
  .popup-block__cross:before {
    content: '\f2d3';
    display: block;
    font: normal 29px/1 "FontAwesome";
    color: #edac0a;
    position: relative;
    top: 3px; }
  .popup-block__cross:hover:before {
    color: #2a82c4;
    transition: color 0.3s ease; }

.popup-block.reg-window {
  margin: -96px 0 0 -224px;
  width: 448px; }
  .popup-block.reg-window .reg_auth-section .addition-block {
    float: right;
    margin: 8px 0 0;
    text-align: right; }
    .popup-block.reg-window .reg_auth-section .addition-block > a {
      display: block;
      font-size: 13px;
      line-height: 1.4;
      margin: 0 0.5em;
      -webkit-tap-highlight-color: transparent; }
      .popup-block.reg-window .reg_auth-section .addition-block > a > span {
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0.15); }
  .popup-block.reg-window .reg_auth-section .form_block.form_block__last {
    float: left;
    padding: 0.5em 0 0;
    width: 100px; }
    .popup-block.reg-window .reg_auth-section .form_block.form_block__last > .form_title {
      font-size: 11px;
      top: -16px; }
  @media only screen and (max-width: 480px) {
    .popup-block.reg-window {
      width: calc(320px - 2em);
      margin: -90px 0 0 calc(160px - 320px + 1em); }
      .popup-block.reg-window .popup-block__title {
        padding: 0 1.5em;
        margin: 0 0 0.5em; }
      .popup-block.reg-window .addition-block {
        float: right;
        margin: -5px 0 0;
        text-align: right;
        width: 120px; }
        .popup-block.reg-window .addition-block > a {
          font-size: 13px;
          display: inline-block; } }

.restore_pass-section {
  background: #fff;
  display: none;
  height: auto;
  padding: 0.5em 1em 1em; }
  .restore_pass-section .form_block.key-block .input-text {
    float: left;
    width: calc(100% - 1em - 92px); }
  .restore_pass-section .form_block.key-block .key-img {
    position: absolute;
    top: -10px;
    right: 0;
    height: 34px;
    width: 92px; }
  .restore_pass-section .form_block.key-block .help-block {
    right: 11em; }
  .restore_pass-section .form_block.key-title {
    margin: 0.5em 0 0; }
    .restore_pass-section .form_block.key-title .form_title {
      font-size: 11px;
      position: static; }
  .restore_pass-section .form_block.form_block__last .button {
    margin: 1em 0 0; }

.popup-block.auth-ok {
  margin-top: -3em; }

.popup-block.auth-error {
  margin-top: -3em; }

.popup-block.order-call {
  margin-top: -172px; }
  @media only screen and (max-width: 480px) {
    .popup-block.order-call {
      max-width: calc(320px - 2em);
      margin: -12em 0 0 calc(160px - 320px + 1em);
      width: 100%; } }

.popup-block.sendformok {
  margin-top: -3em; }

.popup-block.callbackformok {
  margin-top: -3em; }

.popup-block.add-to-cart {
  margin: -46px 0 0 -202px; }
  .popup-block.add-to-cart .link-to-cart {
    position: relative; }
  @media only screen and (max-width: 480px) {
    .popup-block.add-to-cart {
      margin: -42px 0 0 -145px;
      max-width: 290px;
      width: 290px; } }

.popup-block.compare-popup {
  top: 50%;
  left: 50%;
  height: 800px;
  margin: -400px 0 0 calc(-600px + 1em);
  width: calc(1200px - 2em); }
  @media only screen and (max-width: 1200px) {
    .popup-block.compare-popup {
      margin: -400px 0 0;
      left: 1em;
      right: 1em;
      width: auto; } }

.popup-block.compare-popup-error {
  width: 404px;
  margin: -39px 0 0 -202px; }

.product-popup {
  height: 100vh;
  left: 0;
  outline: medium none;
  overflow-x: hidden;
  overflow-y: scroll;
  position: fixed;
  top: 0;
  vertical-align: middle;
  width: 100vw;
  z-index: 1100;
  -webkit-overflow-scrolling: touch; }

.product-popup__window {
  position: relative;
  left: 0;
  right: 0;
  top: 1em;
  bottom: 1em;
  margin: auto;
  background: #fff;
  border: 1px solid #ccc;
  max-width: 1200px;
  padding: 1em;
  width: 100%;
  z-index: 901; }
  @media only screen and (max-width: 768px) {
    .product-popup__window {
      top: 0;
      bottom: 0; } }

.product-popup__close {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 2px;
  padding: 6px 6px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.15); }
  .product-popup__close:before {
    content: '\f2d3';
    display: block;
    font: normal 29px/1 "FontAwesome";
    color: #edac0a;
    position: relative;
    top: 0; }
  .product-popup__close:hover:before {
    color: #2a82c4;
    transition: color 0.3s ease; }

#layerok {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 900;
  background: rgba(0, 0, 0, 0.3); }

.vote-block {
  margin: 0 auto;
  max-width: 145px; }
  .vote-block .flag {
    position: relative; }
    .vote-block .flag:before {
      content: '\f00c';
      color: #494949;
      font: normal 14px/1 "FontAwesome";
      margin: 0 0 0 5px; }
  .vote-block > .vote-block__box .vote-block__box-innr {
    list-style: none;
    margin: 0 0 1em; }
    .vote-block > .vote-block__box .vote-block__box-innr > li {
      margin: 0 0 3px; }
      .vote-block > .vote-block__box .vote-block__box-innr > li > label {
        color: #494949;
        display: block;
        font-size: 13px;
        height: 20px;
        position: relative;
        padding: 0 0 0 2em; }
  .vote-block > .vote-block__box .vote-block__otvet-var {
    color: #494949;
    font-size: 13px;
    position: relative;
    line-height: 13px;
    padding: 5px 70px 5px 0; }
  .vote-block > .vote-block__box .vote-block__otvet_-var-percent {
    color: #fff;
    font-size: 12px;
    position: absolute;
    right: 5px;
    bottom: -14px; }
  .vote-block > .vote-block__box .vote-block__line {
    height: 16px;
    background: #cdcdcd;
    margin: 0 0 5px; }
  .vote-block > .vote-block__box .vote-block__line-in {
    height: 15px;
    background: #2a82c4;
    width: 0;
    will-change: width;
    transition: all .5s ease-in; }
  .vote-block > .vote-block__box .vote-block__total {
    color: #666;
    font-size: 12px;
    font-weight: bold;
    padding-top: 10px; }
  .vote-block > .vote-block__box .vote-block__total-percent {
    bottom: 5px; }
  .vote-block .vote-block__title {
    color: #494949;
    font-size: 15px;
    margin: 0 0 1em;
    text-align: center; }

@media only screen and (max-width: 992px) {
  .vote-block > .vote-block__box {
    padding: 1em 0 1em 1em; } }

.search-form {
  position: relative;
  margin: 7px 0;
  max-width: 268px;
  width: 100%; }
  .search-form .input-text {
    width: 100%; }

.search-form__button {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 28px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.15); }
  .search-form__button:before {
    content: "";
    color: #2a82c4;
    font-family: "FontAwesome";
    font-size: 18px;
    position: absolute;
    margin: -11px 0 0 -10px;
    top: 50%;
    left: 50%;
    font-size: 16px;
    margin-left: -8px;
    margin-top: -13px;
    z-index: 2; }
  .search-form__button:not(.disabled):hover:before {
    color: #edac0a; }
  .search-form__button.disabled {
    cursor: default;
    pointer-events: none; }
    .search-form__button.disabled:before {
      color: #8e8e8e; }

.search-close__button {
  width: 30px;
  height: 30px;
  position: absolute;
  left: 0;
  top: 0; }
  .search-close__button:before {
    content: "";
    color: #2a82c4;
    font-family: "FontAwesome";
    font-size: 18px;
    position: absolute;
    margin: -11px 0 0 -10px;
    top: 50%;
    left: 50%;
    color: #000;
    font-size: 25px;
    left: 70%;
    z-index: 2; }
  @media screen and (min-width: 1025px) {
    .search-close__button {
      display: none; } }

.mobile .search-close__button {
  display: block;
  height: 44px;
  width: 44px;
  position: absolute;
  top: 0;
  left: 0; }
  .mobile .search-close__button:before {
    content: '\f00d';
    font: normal 21px/1 "FontAwesome";
    color: #222;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -11px 0 0 -8px; }

.search_box {
  border-bottom: 1px solid #aaa;
  padding: 1em 0;
  margin-bottom: 1em; }
  .search_box > h3 > a {
    color: #2a82c4;
    font-size: 21px;
    font-weight: normal;
    text-transform: uppercase;
    text-decoration: none; }
    .search_box > h3 > a:hover {
      color: #edac0a; }
  .search_box .search-photo {
    float: left;
    margin: 0 1em 0.5em 0; }
    .search_box .search-photo > a {
      display: block; }
  .search_box .price-wrapper {
    margin: 1em 0 0; }
  .search_box .kolvo_buy_button_add {
    float: left;
    margin: 0; }
  .search_box .search-price {
    float: left;
    margin-right: 10px; }
    .search_box .search-price .price-title {
      font-size: 15px;
      font-weight: bold; }
    .search_box .search-price .price {
      font-size: 24px;
      font-weight: bold; }
    .search_box .search-price .currency {
      font-size: 16px;
      font-weight: bold; }

.main_src_text {
  min-height: 95px; }

.search_box .buy_link {
  float: none;
  display: inline-block; }

.content_search img {
  margin: 0 10px 10px 0; }

.content_search h3 {
  margin: 0;
  font-size: 14px; }

.content_search div.search_box {
  margin: 0 0 15px; }

.content_search cite {
  margin: 2px 0;
  font-style: normal;
  color: #ca0d1f; }

.content_search p {
  margin: 0; }

.search_result {
  position: absolute;
  top: 30px;
  left: 0;
  margin: 4px 0 0;
  background: #fff;
  border-radius: 5px;
  width: 300px;
  font-size: 11px;
  line-height: 16px;
  z-index: 999;
  text-align: left; }

.search_result > div {
  box-shadow: 1px 1px 5px #666;
  border: 1px solid #C6C6C6; }

.search_result > div > .search_box {
  padding: 5px 5px;
  border-top: 1px solid #C6C6C6;
  cursor: pointer; }

.search_result > div > .search_box p {
  margin: 0; }

.search_result > div > .search_box:hover {
  background: #eee; }

.search_result > div > .search_box:first-child {
  padding: 2px 5px;
  border-top: none; }

.search_result > div > .search_box img {
  margin: 0 10px 0; }

span.search_sell {
  color: #000;
  background: #ffff00; }

.ui-autocomplete-category {
  font-weight: bold;
  padding: 0.2em 0.4em;
  margin: 0.8em 0 0.2em;
  line-height: 1.5;
  font-size: 16px; }

.ui-autocomplete-category:first-child {
  padding: 0 0.4em !important;
  margin: 0 0 0.2em !important; }

.ui-state-focus {
  background: #eee !important;
  border: none !important; }

.ui-autocomplete-category {
  border-bottom: 1px solid #ccc; }

.ui-menu-item {
  margin: 0 !important;
  padding: .4em 1em !important;
  width: auto !important; }

.ui-autocomplete {
  z-index: 999 !important; }

.top-menu_search {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 8px; }

.img_search .search_form > .search_input {
  padding-right: 45px; }

.img_search .search_form > .search_button {
  width: 45px;
  height: 55px;
  background: url("../images/img_search.png") 50% -6px no-repeat;
  color: #fff; }

.photos-slider .photo-item > a {
  display: block; }

.albums_list,
.photos_list {
  text-align: center;
  margin: 0px -5px 0; }

.album_block,
.photo_block {
  display: inline-block;
  text-align: center;
  margin: 0 5px 10px;
  vertical-align: top; }

.photo_block {
  max-width: 175px; }

.album_block {
  max-width: 221px; }

.album_photo img,
.photo_photo img {
  display: block; }

.album_title {
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
  padding: 5px 0; }

.photo_description {
  font-size: 13px;
  line-height: 15px;
  padding: 5px 0; }

.gallery-photo {
  margin: 1em 0 0;
  padding: 0 2em; }
  .gallery-photo .owl-nav.disabled {
    display: block; }
  .gallery-photo .owl-nav .owl-next {
    right: -0.75em; }
  .gallery-photo .owl-nav .owl-prev {
    left: -0.75em; }
  .gallery-photo .owl-nav .owl-prev,
  .gallery-photo .owl-nav .owl-next {
    color: #30a12a;
    font-size: 3em;
    font-weight: bold;
    opacity: 0.5; }
    .gallery-photo .owl-nav .owl-prev:hover,
    .gallery-photo .owl-nav .owl-next:hover {
      opacity: 1;
      transition: opacity 0.2s ease; }
  .gallery-photo .photo-item > a > img {
    display: block;
    height: auto;
    max-width: 240px;
    width: 100%; }
  @media only screen and (max-width: 429px) {
    .gallery-photo {
      padding: 0 1em; } }
  @media only screen and (max-width: 367px) {
    .gallery-photo {
      padding: 0 0.5em; } }

.photo_block,
.album-block {
  float: left;
  margin: 0 1em 1em 0;
  width: calc(25% - 0.75em); }
  .photo_block:nth-child(4n + 4),
  .album-block:nth-child(4n + 4) {
    margin: 0 0 1em; }
  .photo_block .photo_photo > a > img,
  .photo_block .album-photo > a > img,
  .album-block .photo_photo > a > img,
  .album-block .album-photo > a > img {
    display: block;
    height: auto;
    max-width: 360px;
    width: 100%; }
  .photo_block .album-title,
  .album-block .album-title {
    padding: 0 0.5em;
    margin: 0.5em 0 0;
    text-align: center; }
    .photo_block .album-title > a,
    .album-block .album-title > a {
      font-size: 14px;
      margin: 0.5em 0; }
  @media only screen and (max-width: 992px) {
    .photo_block,
    .album-block {
      width: calc(50% - 0.5em); }
      .photo_block:nth-child(4n + 4),
      .album-block:nth-child(4n + 4) {
        margin: 0 1em 1em 0; }
      .photo_block:nth-child(2n + 2),
      .album-block:nth-child(2n + 2) {
        margin: 0 0 1em; } }
  @media only screen and (max-width: 768px) {
    .photo_block,
    .album-block {
      width: calc(25% - 0.75em); }
      .photo_block:nth-child(2n + 2),
      .album-block:nth-child(2n + 2) {
        margin: 0 1em 1em 0; }
      .photo_block:nth-child(4n + 4),
      .album-block:nth-child(4n + 4) {
        margin: 0 0 1em 0; } }
  @media only screen and (max-width: 550px) {
    .photo_block,
    .album-block {
      width: calc(50% - 0.5em); }
      .photo_block:nth-child(4n + 4),
      .album-block:nth-child(4n + 4) {
        margin: 0 1em 1em 0; }
      .photo_block:nth-child(2n + 2),
      .album-block:nth-child(2n + 2) {
        margin: 0 0 1em; } }
  @media only screen and (max-width: 380px) {
    .photo_block,
    .album-block {
      margin: 0 0 1em;
      width: 100%; }
      .photo_block:nth-child(4n + 4),
      .album-block:nth-child(4n + 4) {
        margin: 0 0 1em; } }

.photos_text {
  font-size: 14px; }

.prev__photo2, .next__photo2, .prev__photo2:active, .next__photo2:active {
  background: linear-gradient(to bottom, #ada4ff, #ada4ff);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#ada4ff, endColorstr=#ada4ff, GradientType=0);
  background-color: #ada4ff; }

.module {
  background: #fff;
  margin: 1em 0 2em; }
  .module .module-header {
    background: url(/assets/images/modul-header_bg.png) no-repeat;
    background-position: center center;
    padding: 1em;
    margin: 0 0 1px;
    text-align: center; }
    .module .module-header > span {
      color: #473923;
      cursor: default;
      text-transform: uppercase;
      text-shadow: 3px 3px 5px white, -3px -3px 5px white, -5px -5px 10px white, 5px 5px 10px white;
      font-family: 'Bookman Old Style'; }
  .module > .module-wrapper {
    padding: 1em; }
  .module.catalog .module-wrapper {
    padding: 0; }

/* Оформление модулей в контент области */
.module-inner {
  clear: both;
  margin: 1em 0 2em; }
  .module-inner > .module-header {
    background: none;
    padding: 1em;
    text-align: center; }
    .module-inner > .module-header > span {
      font-size: 18px;
      line-height: 18px;
      color: #222;
      text-transform: uppercase;
      font-family: 'Bookman Old Style'; }

.module-wrapper {
  padding: 0;
  position: relative; }
  @media only screen and (max-width: 768px) {
    .module-wrapper {
      padding: 1em; } }

/* Бренды */
.module.brands .brands-list {
  display: block;
  list-style: none;
  padding: 1em; }

.module.brands .brands-list__item {
  font-size: 1.14286em;
  line-height: 1.9; }
  .module.brands .brands-list__item > span {
    color: #edac0a; }

.module.brands .brands-list__link {
  color: #2a82c4;
  transition: color 0.3s ease; }
  .module.brands .brands-list__link:hover {
    color: #edac0a; }

@media only screen and (max-width: 768px) {
  .module.brands {
    float: left;
    padding: 0 0.5em 0 1em;
    width: 50%; } }

@media only screen and (max-width: 480px) {
  .module.brands {
    float: none;
    padding: 0;
    width: 100%; } }

.mobile .weather,
.tablet .weather {
  margin-top: 2%; }

.mobile .module,
.mobile .module-inner,
.tablet .module,
.tablet .module-inner {
  background: none; }
  .mobile .module > .module-header,
  .mobile .module-inner > .module-header,
  .tablet .module > .module-header,
  .tablet .module-inner > .module-header {
    background: url(/assets/images/menu-bg.png) no-repeat;
    background-size: cover;
    padding: 1em;
    margin: 0 0 1em; }
    .mobile .module > .module-header > span,
    .mobile .module-inner > .module-header > span,
    .tablet .module > .module-header > span,
    .tablet .module-inner > .module-header > span {
      color: #2c2c2c; }
    @media only screen and (max-width: 768px) {
      .mobile .module > .module-header,
      .mobile .module-inner > .module-header,
      .tablet .module > .module-header,
      .tablet .module-inner > .module-header {
        padding: 0.5em 1em; }
        .mobile .module > .module-header > span,
        .mobile .module-inner > .module-header > span,
        .tablet .module > .module-header > span,
        .tablet .module-inner > .module-header > span {
          font-size: 16px; } }
  .mobile .module .module-wrapper,
  .mobile .module-inner .module-wrapper,
  .tablet .module .module-wrapper,
  .tablet .module-inner .module-wrapper {
    padding: 0; }

.news-block__title {
  padding: 3px 0; }
  .news-block__title > a {
    font-size: 16px;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: bold; }
    .news-block__title > a:hover {
      text-decoration: none; }

.news-block__text {
  font-size: 14px;
  margin: 0.5em 0;
  line-height: 1.333;
  text-align: justify;
  color: #a29c91; }

.news-block__date {
  text-align: right;
  vertical-align: middle;
  width: 85px;
  padding-bottom: 4px; }
  .news-block__date > span {
    color: #a29c91;
    font-size: 13px;
    padding: 0 8px 1px;
    white-space: nowrap; }
    .news-block__date > span.news-publish {
      background: none;
      color: #a29c91;
      font-size: 10px;
      padding: 0; }

.desktop aside .news-block__item {
  margin: 0 -1em 0.8em !important;
  border-bottom: 2px solid #f2e3b5;
  border-top: 2px solid #f2e3b5; }
  .desktop aside .news-block__item .news-block__text {
    margin: 0.5em 1em; }
  .desktop aside .news-block__item .news-block__title {
    margin: 0 1em; }
  .desktop aside .news-block__item:first-child {
    border-bottom: 2px solid #f2e3b5;
    border-top: none; }

.desktop aside .news-all {
  margin: 0 -1em !important;
  background: url(/assets/images/modul-header_bg.png) no-repeat;
  background-position: center center;
  padding: 10px 0; }

.desktop aside .news-block__item:last-child {
  margin: 0 -1em 0em !important; }

.mobile .news-block .module-wrapper, .tablet .news-block .module-wrapper {
  border: none; }

/* Новости на главной     */
/* ...в левой колонке     */
.news-block .news-block__item {
  margin: 0 0 1em; }
  @media only screen and (max-width: 768px) {
    .news-block .news-block__item {
      margin: 0 10px 1em; } }

.news-block .news-block__header {
  display: table;
  width: 100%; }

.news-block .news-block__date {
  width: 68px;
  margin-left: 1em; }

.news-block .readmore-link {
  display: block; }

.news-block .news-all {
  margin: 7px 0 0;
  text-align: center; }
  .news-block .news-all > a {
    font-size: 15px;
    text-transform: uppercase;
    font-family: 'Bookman Old Style'; }

@media only screen and (max-width: 600px) {
  .news-block .news {
    width: calc(50% - 1em); }
    .news-block .news:last-child {
      width: 100%; } }

@media only screen and (max-width: 480px) {
  .news-block .news {
    width: 100%; } }

/* Новости на главной     */
/* ... в контент области  */
.module-inner .news-block .news-block__item {
  float: left;
  margin: 0 2em 1em 0;
  width: calc(33.333% - 1.5em); }
  .module-inner .news-block .news-block__item:nth-child(3) {
    margin: 0 0 1em; }
  @media only screen and (max-width: 768px) {
    .module-inner .news-block .news-block__item {
      width: 100%; } }

/* Список новостей - news/page/1 */
.news-list .readmore-link {
  display: block;
  margin: 1em 0 0; }

.news-list .news-block__image {
  float: left;
  margin: 0 1em 0.5em 0;
  max-width: 200px; }

.news-list .news-block__item {
  border-bottom: 1px solid #aaa;
  margin: 0 0 2em;
  padding: 0 0 1em; }
  .news-list .news-block__item:last-child {
    border-bottom: none; }

.news-list .news-block__header {
  display: table;
  width: 100%; }
  .news-list .news-block__header .news-block__date,
  .news-list .news-block__header .news-block__title {
    display: table-cell; }

.news-block .module-wrapper {
  border: 2px solid #f2e3b5;
  border-top: none;
  border-bottom: none;
  padding-bottom: 0; }

.news-detail {
  margin: 1em 0 0; }
  .news-detail .news-detail__header {
    display: table;
    width: 100%; }
  .news-detail .news-detail__title {
    font-size: 18px;
    display: table-cell; }
  .news-detail .news-detail__date {
    display: table-cell;
    vertical-align: middle;
    text-align: right;
    width: 85px;
    padding-bottom: 4px; }
    .news-detail .news-detail__date > span {
      background: #2a82c4;
      color: #fff;
      font-size: 13px;
      padding: 0 8px 1px;
      white-space: nowrap; }
      .news-detail .news-detail__date > span.pbl {
        background: none;
        display: block;
        color: #494949;
        font-size: 10px;
        padding: 0; }
  .news-detail .news-detail__text > a {
    float: left;
    outline: none; }
  .news-detail .news-detail__image {
    display: block;
    margin: 0 1em 0 0; }

.news_navigation {
  display: table;
  margin: 1em 0 0;
  width: 100%; }
  .news_navigation > .prev_news {
    text-align: left; }
  .news_navigation > .next_news {
    text-align: right; }
  .news_navigation > .all_news {
    text-align: center; }
  .news_navigation > .all_news,
  .news_navigation > .next_news,
  .news_navigation > .prev_news {
    display: table-cell;
    font-size: 13px;
    width: 33.333%; }
    .news_navigation > .all_news > a,
    .news_navigation > .next_news > a,
    .news_navigation > .prev_news > a {
      text-decoration: underline; }
      .news_navigation > .all_news > a:hover,
      .news_navigation > .next_news > a:hover,
      .news_navigation > .prev_news > a:hover {
        text-decoration: underline; }

.reg_reg-section {
  margin: 2em 0 0; }
  .reg_reg-section table {
    table-layout: fixed;
    max-width: 350px;
    width: 100%; }
  .reg_reg-section .input-text {
    width: 100%; }
  .reg_reg-section .reg_col_1 {
    font-size: 13px;
    padding: 0 1em 0 0;
    width: 138px; }
  .reg_reg-section .reg_col_2 {
    padding: 0 0 0.5em;
    position: relative; }
  .reg_reg-section .button {
    margin: 1em 0 0; }
  .reg_reg-section .last {
    text-align: center; }
    .reg_reg-section .last .button {
      display: inline-block; }

.profile-wrapper {
  background: #fff;
  min-width: 260px;
  max-width: 292px;
  padding: 1em; }
  .profile-wrapper .reg_col_1,
  .profile-wrapper .reg_col_2 {
    display: block; }
  .profile-wrapper .reg_col_1 {
    color: #494949;
    font-size: 12px;
    padding: 0 0 0.25em; }
  .profile-wrapper .reg_col_2 {
    padding: 0 0 0.25em;
    position: relative; }
    .profile-wrapper .reg_col_2.last {
      padding: 0;
      margin: 1em 0 0; }
    .profile-wrapper .reg_col_2 .input-text {
      width: 100%; }

.changepass-block > form > table,
.profile-block > form > table {
  table-layout: fixed;
  width: 100%; }

.changepass-block .changepass-block__title {
  margin: 1em 0 0.5em;
  text-align: center; }

@media only screen and (max-width: 480px) {
  .profile-block {
    margin: 1em 0 0; }
    .profile-block .profile-table {
      width: 100%; }
      .profile-block .profile-table tr {
        display: block; }
        .profile-block .profile-table tr:last-child .reg_col_1 {
          line-height: 0; }
    .profile-block .input-text {
      width: 100%; }
    .profile-block .reg_col_1,
    .profile-block .reg_col_2 {
      display: block; }
  .pass-change-table {
    table-layout: fixed;
    width: 100%; }
    .pass-change-table tr {
      display: block; }
      .pass-change-table tr:last-child .reg_col_1 {
        line-height: 0; }
    .pass-change-table .input-text {
      width: 100%; }
    .pass-change-table .reg_col_1,
    .pass-change-table .reg_col_2 {
      display: block; }
  .reg_col_1 {
    font-size: 14px; } }

.orders_btnm {
  margin: 1em 0 2em; }

.fancybox-enabled {
  overflow: hidden; }
  .fancybox-enabled body {
    overflow: visible;
    height: 100%; }

.fancybox-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99993;
  backface-visibility: hidden; }

/* Make sure that the first one is on the top */
.fancybox-container ~ .fancybox-container {
  z-index: 99992; }

.fancybox-bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition-timing-function: cubic-bezier(0.55, 0.06, 0.68, 0.19);
  backface-visibility: hidden; }

.fancybox-container--ready .fancybox-bg {
  opacity: 1;
  transition-timing-function: cubic-bezier(0.2, 0.61, 0.36, 1); }

.fancybox-controls {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  opacity: 0;
  z-index: 99994;
  transition: opacity .2s;
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  direction: ltr; }

.fancybox-show-controls .fancybox-controls {
  opacity: 1; }

.fancybox-infobar {
  display: none; }

.fancybox-show-infobar .fancybox-infobar {
  display: inline-block;
  pointer-events: all;
  margin: 3em 0 0; }

.fancybox-infobar__body {
  display: inline-block;
  width: 70px;
  line-height: 44px;
  font-size: 13px;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  text-align: center;
  color: #ddd;
  background-color: rgba(30, 30, 30, 0.7);
  pointer-events: none;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: subpixel-antialiased; }

.fancybox-buttons {
  position: absolute;
  top: 0;
  right: 0;
  display: none;
  pointer-events: all; }
  .fancybox-buttons .popup-block__cross {
    position: static;
    height: 44px;
    width: 44px; }
    .fancybox-buttons .popup-block__cross:hover:before {
      color: #2a82c4;
      transition: color 0.2s ease; }
    .fancybox-buttons .popup-block__cross:before {
      font-size: 22px;
      top: 10px; }

.fancybox-show-buttons .fancybox-buttons {
  display: block; }

.fancybox-slider-wrap {
  overflow: hidden;
  direction: ltr; }

.fancybox-slider-wrap,
.fancybox-slider {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0;
  margin: 0;
  z-index: 99993;
  backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent; }

.fancybox-slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: auto;
  outline: none;
  white-space: normal;
  text-align: center;
  z-index: 99994;
  -webkit-overflow-scrolling: touch; }
  .fancybox-slide:before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    height: 100%;
    width: 0; }
  .fancybox-slide > * {
    display: inline-block;
    position: relative;
    padding: 24px;
    margin: 44px 0 44px;
    border-width: 0;
    vertical-align: middle;
    text-align: left;
    background-color: #fff;
    overflow: auto; }

.fancybox-slide--image {
  overflow: hidden; }
  .fancybox-slide--image:before {
    display: none; }

.fancybox-content {
  display: inline-block;
  position: relative;
  margin: 44px auto;
  padding: 0;
  border: 0;
  width: 80%;
  height: calc(100% - 88px);
  vertical-align: middle;
  line-height: normal;
  text-align: left;
  white-space: normal;
  outline: none;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  -webkit-tap-highlight-color: transparent;
  -webkit-overflow-scrolling: touch; }

.fancybox-iframe {
  display: block;
  margin: 0;
  padding: 0;
  border: 0;
  width: 100%;
  height: 100%;
  background: #fff; }

.fancybox-slide--video .fancybox-content,
.fancybox-slide--video .fancybox-iframe {
  background: transparent; }

.fancybox-placeholder {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  border: 0;
  z-index: 99995;
  background: transparent no-repeat;
  cursor: default;
  overflow: visible;
  transform-origin: top left;
  background-size: 100% 100%;
  backface-visibility: hidden; }

.fancybox-image,
.fancybox-spaceball {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  border: 0;
  max-width: none;
  max-height: none;
  background: transparent;
  background-size: 100% 100%; }

.fancybox-controls--canzoomOut .fancybox-placeholder {
  cursor: zoom-out; }

.fancybox-controls--canzoomIn .fancybox-placeholder {
  cursor: zoom-in; }

.fancybox-controls--canGrab .fancybox-placeholder {
  cursor: grab; }

.fancybox-controls--isGrabbing .fancybox-placeholder {
  cursor: grabbing; }

.fancybox-spaceball {
  z-index: 1; }

.fancybox-tmp {
  position: absolute;
  top: -9999px;
  left: -9999px;
  visibility: hidden; }

.fancybox-error {
  position: absolute;
  margin: 0;
  padding: 40px;
  top: 50%;
  left: 50%;
  width: 380px;
  max-width: 100%;
  transform: translate(-50%, -50%);
  background: #fff;
  cursor: default; }

.fancybox-error p {
  margin: 0;
  padding: 0;
  color: #444;
  font: 16px/20px "Roboto", Helvetica, Arial, sans-serif; }

.fancybox-close-small {
  position: absolute;
  top: 0;
  right: 0;
  width: 44px;
  height: 44px;
  padding: 0;
  margin: 0;
  border: 0;
  border-radius: 0;
  outline: none;
  background: transparent;
  z-index: 10;
  cursor: pointer; }
  .fancybox-close-small:after {
    content: '×';
    position: absolute;
    top: 5px;
    right: 5px;
    width: 30px;
    height: 30px;
    font: 20px/30px Arial, "Helvetica Neue", Helvetica, sans-serif;
    color: #888;
    font-weight: 300;
    text-align: center;
    border-radius: 50%;
    border-width: 0;
    background: #fff;
    transition: background .2s;
    z-index: 2; }
  .fancybox-close-small:focus:after {
    outline: 1px dotted #888; }
  .fancybox-close-small:hover:after {
    color: #494949;
    background: #fff; }

.fancybox-slide--video .fancybox-close-small {
  top: -36px;
  right: -36px;
  background: transparent; }

/* Caption */
.fancybox-caption-wrap {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 1em;
  z-index: 99998;
  backface-visibility: hidden;
  background: rgba(0, 0, 0, 0.15);
  opacity: 0;
  transition: opacity .2s;
  pointer-events: none; }

.fancybox-show-caption .fancybox-caption-wrap {
  opacity: 1; }

.fancybox-caption {
  padding: 1em 0;
  border-top: 1px solid rgba(255, 255, 255, 0.4);
  font-size: 1.5em;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  color: #fff;
  line-height: 1.333;
  text-align: center;
  -webkit-text-size-adjust: none; }
  .fancybox-caption a,
  .fancybox-caption button,
  .fancybox-caption .popup-block.add-to-cart .link-to-cart, .popup-block.add-to-cart
  .fancybox-caption .link-to-cart {
    pointer-events: all; }
  .fancybox-caption a {
    color: #fff;
    text-decoration: underline; }

/* Buttons */
.fancybox-button {
  display: inline-block;
  position: relative;
  width: 44px;
  height: 44px;
  line-height: 44px;
  margin: 0;
  padding: 0;
  border: 0;
  border-radius: 0;
  cursor: pointer;
  background: transparent;
  color: #fff;
  vertical-align: top;
  outline: none; }
  .fancybox-button:hover {
    background: transparent; }
    .fancybox-button:hover:before {
      color: #2a82c4;
      transition: color 0.2s ease; }
  .fancybox-button:before {
    content: '';
    pointer-events: none;
    position: absolute;
    display: block; }

.fancybox-button--disabled {
  cursor: default;
  pointer-events: none; }
  .fancybox-button--disabled:before, .fancybox-button--disabled:after {
    color: #494949; }

.fancybox-button--left {
  border-bottom-left-radius: 5px; }
  .fancybox-button--left:after {
    left: 20px;
    top: 18px;
    width: 6px;
    height: 6px;
    background: transparent;
    border-top: solid 2px currentColor;
    border-right: solid 2px currentColor;
    transform: rotate(-135deg); }

.fancybox-button--right {
  border-bottom-right-radius: 5px; }
  .fancybox-button--right:after {
    right: 20px;
    top: 18px;
    width: 6px;
    height: 6px;
    background: transparent;
    border-top: solid 2px currentColor;
    border-right: solid 2px currentColor;
    transform: rotate(45deg); }

.fancybox-button--close {
  float: right; }
  .fancybox-button--close:before {
    content: '×';
    top: -3px;
    left: 8px;
    font-size: 35px;
    color: #dda209; }

/* Loading spinner */
.fancybox-loading {
  border: 6px solid rgba(100, 100, 100, 0.4);
  border-top: 6px solid rgba(255, 255, 255, 0.6);
  border-radius: 100%;
  height: 50px;
  width: 50px;
  animation: fancybox-rotate .8s infinite linear;
  background: transparent;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -25px;
  margin-left: -25px;
  z-index: 99999; }

@-webkit-keyframes fancybox-rotate {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }

@keyframes fancybox-rotate {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }

/* Styling for Small-Screen Devices */
@media all and (max-width: 800px) {
  .fancybox-controls {
    text-align: left; }
  .fancybox-button--left,
  .fancybox-button--right,
  .fancybox-buttons button:not(.fancybox-button--close),
  .fancybox-buttons .popup-block.add-to-cart .link-to-cart:not(.fancybox-button--close), .popup-block.add-to-cart
  .fancybox-buttons .link-to-cart:not(.fancybox-button--close) {
    display: none !important; }
  .fancybox-caption {
    padding: 20px 0;
    margin: 0; } }

/* Fullscreen  */
.fancybox-button--fullscreen:before {
  width: 18px;
  height: 13px;
  left: 11px;
  top: 13px;
  border: 2px solid #edac0a;
  background: none; }

.fancybox-button--fullscreen:hover:before {
  color: transparent;
  border-color: #2a82c4; }

/* Slideshow button */
.fancybox-button--play:before {
  background: none;
  content: '\f04b';
  color: #dda209;
  font: normal 20px/1 "FontAwesome";
  position: absolute;
  top: 12px;
  left: 16px; }

.fancybox-button--pause:before {
  background: none;
  content: '\f04c';
  color: #dda209;
  font: normal 20px/1 "FontAwesome";
  position: absolute;
  top: 12px;
  left: 13px; }

/* Thumbs */
.fancybox-button--thumbs span {
  font-size: 23px; }

.fancybox-button--thumbs:before {
  background: none;
  content: '\f00a';
  color: #dda209;
  font: normal 22px/1 "FontAwesome";
  position: absolute;
  top: 11px;
  left: 11px; }

.fancybox-container--thumbs .fancybox-controls,
.fancybox-container--thumbs .fancybox-slider-wrap,
.fancybox-container--thumbs .fancybox-caption-wrap {
  right: 220px; }

.fancybox-thumbs {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: auto;
  width: 220px;
  margin: 0;
  padding: 5px 5px 0 0;
  background: #fff;
  z-index: 99993;
  word-break: normal;
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: transparent; }
  .fancybox-thumbs > ul {
    list-style: none;
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    overflow-y: auto;
    font-size: 0; }
    .fancybox-thumbs > ul > li {
      float: left;
      overflow: hidden;
      max-width: 50%;
      padding: 0;
      margin: 0;
      width: 105px;
      height: 75px;
      position: relative;
      cursor: pointer;
      outline: none;
      border: 5px solid #fff;
      border-top-width: 0;
      border-right-width: 0;
      -webkit-tap-highlight-color: transparent;
      backface-visibility: hidden; }
      .fancybox-thumbs > ul > li.fancybox-thumbs-loading {
        background: rgba(0, 0, 0, 0.1); }
      .fancybox-thumbs > ul > li > img {
        position: absolute;
        top: 0;
        left: 0;
        min-width: 100%;
        min-height: 100%;
        max-width: none;
        max-height: none;
        -webkit-touch-callout: none;
        user-select: none; }
      .fancybox-thumbs > ul > li:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-radius: 2px;
        border: 4px solid #2a82c4;
        z-index: 99991;
        opacity: 0;
        transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94); }
      .fancybox-thumbs > ul > li.fancybox-thumbs-active:before {
        opacity: 1; }

/* Styling for Small-Screen Devices */
@media all and (max-width: 800px) {
  .fancybox-thumbs {
    display: none !important; }
  .fancybox-container--thumbs .fancybox-controls,
  .fancybox-container--thumbs .fancybox-slider-wrap,
  .fancybox-container--thumbs .fancybox-caption-wrap {
    right: 0; } }
