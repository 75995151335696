.news-block__title {
	padding: 3px 0;

	& > a {
		font-size: 16px;
		text-transform: uppercase;
		text-decoration: none;
		font-weight: bold;
		&:hover {
			text-decoration: none;
		}
	}
}


.news-block__text {
	font-size: 14px;
	margin: 0.5em 0;
	line-height: 1.333;
	text-align: justify;
	color: #a29c91;
}


.news-block__date {
	text-align: right;
	vertical-align: middle;
	width: 85px;
	padding-bottom:4px;

	& > span {
		// background: $linkColor;
		color: #a29c91;
		font-size: 13px;
		padding: 0 8px 1px;
		white-space: nowrap;

		&.news-publish {
			background: none;
			// display: block;
			color: #a29c91;
			font-size: 10px;
			padding: 0;
		}
	}
}

.desktop{
	aside{
		.news-block__item{
			margin: 0 -1em 0.8em!important;
			border-bottom: 2px solid #f2e3b5;
			border-top: 2px solid #f2e3b5;
			.news-block__text{
				margin: 0.5em 1em;
			}
			.news-block__title {
				margin: 0 1em;
			}
			&:first-child{
				border-bottom: 2px solid #f2e3b5;
				border-top: none;
			}
		}
		.news-all{
			margin: 0 -1em!important;
			background: url(/assets/images/modul-header_bg.png) no-repeat;
			background-position: center center;
			padding: 10px 0;
		}	
		.news-block__item:last-child{
			margin: 0 -1em 0em!important;
		}
	}
}
.mobile, .tablet{
	.news-block .module-wrapper{
		border: none;
	}
}


/* Новости на главной     */
/* ...в левой колонке     */
.news-block {

	.news-block__item {
		margin: 0 0 1em;
		
		@include media(md){
			margin: 0 10px 1em;
		}

	}


	.news-block__header {
		display: table;
		width: 100%;
	}


	.news-block__date,
	.news-block__title {
		// display: table-cell;
	}


	.news-block__date {
		width: 68px;
		margin-left: 1em;
	}


	.news-block__image {
		@extend .img-responsive;
	}


	.readmore-link {
		display: block;
	}

	.news-all {
		margin: 7px 0 0;
		text-align: center;

		& > a {
			font-size: 15px;
			text-transform: uppercase;
			font-family: 'Bookman Old Style';
		}
	}

	@include media(xmd) {
		.news {
			width: calc(50% - 1em);

			&:last-child {
				width: 100%;
			}
		}
	}

	@include media(sm) {
		.news {
			width: 100%;
		}
	}
}



/* Новости на главной     */
/* ... в контент области  */
.module-inner {
	.news-block {
		.news-block__item {
			float: left;
			margin: 0 2em 1em 0;
			width: calc(33.333% - 1.5em);

			&:nth-child(3) {
				margin: 0 0 1em;
			}
			@include media(md){
				width: 100%;
			}
		}
	}
}


/* Список новостей - news/page/1 */
.news-list {

	.readmore-link {
		display: block;
		margin: 1em 0 0;
	}

	.news-block__image {
		float: left;
		margin: 0 1em 0.5em 0;
		max-width: 200px;
	}

	.news-block__item {
		@extend .clearfix;

		border-bottom: 1px solid $borderColor;
		margin: 0 0 2em;
		padding: 0 0 1em;

		&:last-child {
			border-bottom: none;
		}
	}

	.news-block__header {
		display: table;
		width: 100%;

		.news-block__date,
		.news-block__title {
			display: table-cell;
		}
	}
}




.news-block{
	.module-wrapper{
		border: 2px solid #f2e3b5;
		border-top: none;
		border-bottom: none;
		padding-bottom: 0;
	}
}