.news_navigation {
  display: table;
  margin: 1em 0 0;
  width: 100%;

  & > .prev_news {
    text-align: left;
  }

  & > .next_news {
    text-align: right;
  }

  & > .all_news {
    text-align: center;
  }

  & > .all_news,
  & > .next_news,
  & > .prev_news {
    display: table-cell;
    font-size: 13px;
    width: 33.333%;

    & > a {
      text-decoration: underline;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
