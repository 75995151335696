/* Список подразделов, плитки по 3 колонки */
.category-list {
  @extend .clearfix;

  .category-list__item {
    cursor: pointer;
    float: left;
    position: relative;
    margin: 0 2em 2em 0;
    width: calc(33.333% - 1.34em);
    transition: all 0.3s ease;
    -webkit-tap-highlight-color: $webkitHighlightTapColor;

    
      > a {
          display: block;
          width: 100%;
          background: $linkColor;
          color: $white;
          font-size: 17px;
          text-decoration: none;
          text-transform: uppercase;
          text-align: center;

          @include media(xlg) {
            padding: 1em;
            font-size: 15px;
          }

          @include media(lg) {
            font-size: 14px;
          }
           &:hover {
      transition: all 0.3s ease;
      background: $linkColor--hover;
        
    }
    .category-list__item-title {
      display: block;
      padding: 10px;
      }
    }
    

    &:nth-child(3n + 3) {
      margin: 0 0 2em 0;
    }

   


    @include media(lg) {

      &:nth-child(3n + 3) {
        margin: 0 2em 2em 0;
      }

      width: calc(50% - 1em);

      &:nth-child(2n + 2) {
        margin: 0 0 2em 0;
      }
    }

    @include media(md) {
      margin: 0 1em 1em 0;

      &:nth-child(2n + 2) {
        margin: 0 1em 1em 0;
      }

      width: calc(33.333% - 0.666667em);

      &:nth-child(3n + 3) {
        margin: 0 0 1em 0;
      }
    }


    @include media(xmd) {

      &:nth-child(3n + 3) {
        margin: 0 1em 1em 0;
      }

      width: calc(50% - 0.5em);

      &:nth-child(2n + 2) {
        margin: 0 0 1em 0;
      }
    }

    @include media(xs) {
      margin: 0 0 1em;
      float: none;
      width: 100%;

      &:nth-child(3n + 3) {
        margin: 0 0 1em 0;
      }
    }

  }


  .category-list__item-image {
    @extend .img-responsive;
  }


  &.hidden {
    display: none;
  }
}
